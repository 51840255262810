import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { url } from 'inspector';
import { firstValueFrom } from 'rxjs';
import { ETFUser } from 'src/app/models/user';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  currentUser: ETFUser;
  mobileMenuOpen: boolean;
  mobileMenuClose:boolean;

  constructor(public afAuth: AngularFireAuth, private router: Router, private fs:FirebaseService) { }

  ngOnInit(): void {

this.afAuth.authState.subscribe(result=>{
  if(result!==null){
  this.fs.getUserData().then((res) => {
    firstValueFrom(res).then((currentUser) => {
        this.currentUser = currentUser as ETFUser;
    })})
  }})
  }

  logout() {
    this.afAuth.signOut().then(() =>{
      this.router.navigate([''])
      window.location.reload()
    })
  }

  goto(url:string){
    window.location.href=url;
  }

toggleMobileMenu() {
  this.mobileMenuOpen = !this.mobileMenuOpen;
}
}
