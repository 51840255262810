import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/services';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.css'],
})
export class BusinessDetailComponent implements OnInit {
  business: any;
  customerId: any;
  itRequests = [];
  gstRequests = [];

  isParentBusinessPage: boolean;
  constructor(
    private fs: FirebaseService, private router: Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        var id = String(params.get('id'));
        this.customerId = String(params.get('c'));
        this.isParentBusinessPage = (params.get('isParentBusinessPage')) == 'true';
        return this.fs.getBusiness(id);
      })
    ).subscribe(b => {
      this.business = b;
      this.fs.getAllItRequestsForBusiness(b.id).subscribe((result) => {
        this.itRequests = result;
      })
      this.fs.getAllGstRequestsForBusiness(b.id).subscribe((result) => {
        this.gstRequests = result;
      })
    });

  }

  back() {
    if (this.isParentBusinessPage) {
      this.router.navigate(['businesses']);
    } else {
      this.router.navigate(['customer-detail', { id: this.customerId }]);
    }
  }

  viewIncomeTaxFilingRequest(incometaxfiling: any) {
    this.router.navigate(['itRequestDetail', { id: incometaxfiling.requestID }]);
  }
  viewGstRequest(request: any) {
    this.router.navigate(['gstRequestDetail', { id: request.requestId }]);
  }
}
