import { Component } from '@angular/core';

@Component({
  selector: 'app-subscribe-today',
  templateUrl: './subscribe-today.component.html',
  styleUrls: ['./subscribe-today.component.css']
})
export class SubscribeTodayComponent {

}
