import { Pipe, PipeTransform } from '@angular/core';

enum GstType { GSTR1, GSTR3B }

@Pipe({
  name: 'gstRequestType'
})
export class GstRequestTypePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    return GstType[value].toUpperCase();
  }

}
