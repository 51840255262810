import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Toast from 'awesome-toast-component';
import { firstValueFrom } from 'rxjs';
import { SubscriptionTypeItem } from 'src/app/models/subscriptionTypeItem';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-subscribtion-details',
  templateUrl: './subscribtion-details.component.html',
  styleUrls: ['./subscribtion-details.component.css']
})
export class SubscribtionDetailsComponent {

  subscriptionDetails:any;
  user:any;
  formvalue: FormGroup;
  editSelectedItem :any = {};
  editbtn: boolean;
  currentDate = new Date()
  subscriptionEndDate:any;

  constructor( private fs: FirebaseService,private activateRoute:ActivatedRoute){}


  ngOnInit(){

    const userId = this.activateRoute.snapshot.paramMap.get('id');

    this.formvalue = new FormGroup({
      endDate          : new FormControl(),
      planType         : new FormControl(),
    });

    this.getSubscriptionDetails(userId);
    this.getUser(userId);

  }

  getUser(userId){
    this.fs.getUserbyId(userId).subscribe(res=>{
      this.user=res;
      // console.log(res)
    })
  }

  getSubscriptionDetails(userId){
     this.fs.getSubscriptionDetails(userId)
    .subscribe(res=>{
    this.subscriptionDetails=res;
     })
   }


   edit(item:any){
    // console.log(item.endDate.toDate())
    this.editSelectedItem = item;
    this.editbtn=true;

    // var subendDate = [(item.endDate).toDateString()]
    // console.log(subendDate)
    this.formvalue.controls['planType'].setValue(item.plan);
    this.formvalue.controls['endDate'].setValue(item.endDate);
  }

  updateSubscriptionDetails(id){

    if(this.formvalue.value.endDate==null || undefined || '' ){
      alert('Please select End Date')
    }
      this.editSelectedItem.plan = this.formvalue.value.planType;
      this.editSelectedItem.endDate = new Date(this.formvalue.value.endDate);

    this.fs.updateSubscriptionPlan(this.editSelectedItem).then(res => {
      console.log(res)
      new Toast(`Project Details Updated Successfully.`, {
        position: 'top'});

      this.formvalue.reset();
      window.location.reload()
      this.editbtn=false;

    })
  }
updateUserSubscription(data:any){
  if(data.validSubscription!=true){
    if(confirm("Start Subscription?")){
     this.fs.updateUserValidSubscription(data).then(()=>{
      new Toast(`Subscription done..!!` , {
        position: 'top'});
        // window.location.reload();
})
}
  }else{
    if(confirm("Pause Subscription?")){
    this.fs.updateUserunsubscribe(data).then(()=>{
      new Toast(`Subscription Paused` , {
        position: 'top'});
        // window.location.reload();
      })}
  }
}
  updateVerification(data:any){
    if(data.verified!=true){
      if(confirm("Verify Customer?")){
       this.fs.updateUserVerification(data).then(()=>{
        new Toast(`Verification done..!!` , {
          position: 'top'});
  })
}
    }else{
      if(confirm("Unverify Customer?")){
      this.fs.updateUserunVerified(data).then(()=>{
        new Toast(`Customer Unverified..!!` , {
          position: 'top'});
        })}
    }
  }


  cancel(){
    this.formvalue.reset();
    this.editbtn=false;

  }
  }
