export enum LogEventType{
  createCustomer = 0,
  editCustomer   = 1,
  deleteCustomer = 2,
  addCustomer    = 3,
  verifyCustomer = 4,

  changeItStatusNew         = 5,
  changeItStatusSubmitted   = 6,
  changeItStatusReview      = 7,
  changeItStatusIncompleted = 8,
  changeItStatusInvalid     = 9,
  changeItStatusDeleted     = 10,

  changeGstStatusNew          = 11,
  changeGstStatusSubmitted    = 12,
  changeGstStatusReview       = 13,
  changeGstStatusIncompleted  = 14,
  changeGstStatusInvalid      = 15,
  changeGstStatusDeleted      = 16,

  addSubscription = 17,

  addRaiseTicket                  = 18,
  changeRaiseTicketStatusNew      = 19,
  changeRaiseTicketStatusOpen     = 20,
  changeRaiseTicketStatusClosed   = 21,
  changeRaiseTicketStatusDeferred = 22,

  addUser = 23,

  updateSubscription = 24,
  cancelTrialSubscription = 25,

  createSubscriptionItem = 26,
  editSubscriptionItem   = 27,
  deleteSubscriptionItem = 28,

  startUserSubscription = 29,
  userVerification = 30,
  userUnverified   = 31,
  pauseUserSubscription = 32,

}

export enum LogEventDataType{
  customer        = 1,
  itRequest       = 2,
  gstRequest      = 3,
  subscription = 4,
  raiseTicket     = 5,
  user            = 6,
  subscriptionItem= 7,
  Verification    = 8,
  pauseSubscription=9,
  startUserSubscription=10,

}
