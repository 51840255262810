<div class="sectionMobile md:m-10 md:p-10 mt-2">
  <div class="bg-white  shadow p-4 mt-5">
    <div class="mx-auto tp-Center mt-2 mb-2">

      <h2 class="text-xl font-bold uppercase">Customer Details</h2>
      <h2 *ngIf="customer">{{ customer.name }}</h2>
    </div>
  </div>
  <div class="row mt-2">
    <button class="btn btn-taxPrism-outline" (click)="back()">BACK</button>
  </div>
  <div class="md:mb-20 mt-5">
    <div class=" mt-2 mb-2 bg-white p-4 shadow" *ngIf="customer">
      <div class=" p-1">
        <img class="p-1" src="{{ customer.socialPhotoUrl }}" height="150px" width="150px" />
        <h6 class="">Name: {{ customer.name }}</h6>
        <h6 class="">Address:{{ customer.address }} </h6>
        <h6 class="">Date of Birth: <span *ngIf="customer.dateofBirth"> {{ customer.dateofBirth.toDate() | date:
            "dd/MM/yyyy" }}</span></h6>

        <h6 class="">Phone Number: {{ customer.phoneNumber }}</h6>
        <h6 class="">Email Address: {{ customer.email }} </h6>
        <h6 class="">AADHAR Card Number: {{ customer.aadharCardNumber }}</h6>
        <h6 class="">PAN Card Number: {{ customer.panCardNumber }}</h6>
        <h6 class="">IT Portal Login: {{ customer.loginId }}</h6>
        <h6 class="">IT Portal Password: {{ customer.loginPwd }}</h6>
      </div>
    </div>
    <div class="  text-center mt-2 mb-2 p-4 bg-white shadow">
      <div class="">
        <div class="p-2">
          <p class="text-2xl">Business Details</p>
          Number of Businesses registered with Tax Prism: {{ businessCount }}
        </div>
        <div class="bg-gray-100 shadow p-2 mt-2 grid grid-cols-1 gap-2 md:grid-cols-6">
          <div>Name </div>
          <div>PAN</div>
          <div>Email</div>
          <div>Business Type</div>
          <div></div>
          <div></div>
        </div>
        <div class="bg-gray-50 shadow p-2  mt-2" *ngFor="let business of businesses">
          <div class="grid gap-2 md:grid-cols-6 w-100">
            <div class="col">{{ business.name }}</div>
            <div class="col">{{ business.panCardNumber }}</div>
            <div class="col">{{ business.emailAddress }}</div>
            <div class="col">{{business.businessType | businessType}}</div>
            <div class="col">
              <p *ngIf="businesses.isActive">ACTIVE</p>
              <p *ngIf="!businesses.isActive">INACTIVE</p>
            </div>
            <div class="col">
              <button class="btn btn-block btn-taxPrism-outline" (click)="viewBusinessDetail(business)">
                VIEW
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="  text-center mt-2 mb-2 p-4 bg-white shadow">
      <div>
        <h5 class="text-2xl font-bold">IT Requests Filed</h5>
      </div>
      <div class="bg-gray-300 shadow mt-2 grid grid-cols-1 md:grid-cols-5 gap-2 p-4 uppercase text-lg">
        <div>Year of Assesment</div>
        <div>Filing Request Type</div>
        <div>Name of Entity</div>
        <div>STATUS</div>
        <div></div>
      </div>
      <div class="bg-white shadow mt-2 p-4" *ngFor="let request of itRequests">
        <div class="bg-white  mt-2 grid grid-cols-1 md:grid-cols-5 gap-2 ">
          <div class="">
            <p> {{ request.assesmentYear }}</p>
          </div>

          <div class="">
            Filing Request Type: {{ request.itRequestType | iTRequestType }}
          </div>
          <div class="">
            <p>{{ request.name }}</p>
          </div>
          <div class=" uppercase">
            <span class="text-blue-400" *ngIf="request.status === 0">NEW </span>
        <span class="text-yellow-400" *ngIf="request.status === 1">SUBMITTED </span>
        <span class="text-green-400" *ngIf="request.status === 2">REVIEW </span>
        <span class="text-red-400" *ngIf="request.status === 3">INCOMPLETE </span>
        <span class="text-red-400" *ngIf="request.status === 4">INVALID </span>
        <span class="text-red-400" *ngIf="request.status === 5">DELETED </span>
          </div>
          <div class="">
            <button class="btn btn-taxPrism-outline" (click)="viewIncomeTaxFilingRequest(request)">
              DETAILS
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
