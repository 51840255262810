import { Component, OnInit, Input } from '@angular/core';
import { Customer } from 'src/app/models/models';
import { FirebaseService } from 'src/app/services/services';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BusinessType } from "../../models/enums";
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css'],
})
export class CustomerDetailComponent implements OnInit {
  customer: Customer;
  public dob: any;
  public businesses;
  public businessCount;
  bType: BusinessType;
  itRequests = [];
  constructor(
    private fs: FirebaseService,
    private router: Router, private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        var id = String(params.get('id'));
        // return this.dataService.getTeamDetails(id);
        console.log(id);
        // console.log(cust as Customer);
        this.fs.getCustomer(id)
          .subscribe(cust => {
            this.customer = cust;
            // console.log(cust);
            this.fs.getAllItRequestsForIndividual(id).subscribe((result) => {
              this.itRequests = result;
              // console.table(result);
            })
          })
        return this.fs.getBusinesses(id);
      }
      )
    ).subscribe((businesses) => {


      this.businesses = businesses;
      if (businesses == null) {
        this.businessCount = 0;
      } else {
        this.businessCount = businesses.length;
      }
    });
  }

  viewBusinessDetail(businessInfo: any) {
    this.router.navigate(['business-detail', { id: businessInfo.id, c: this.customer.id, isParentBusinessPage: false }]);
  }

  back() {
    this.router.navigate(['customers']);
  }
  viewIncomeTaxFilingRequest(incometaxfiling: any) {
    console.log(incometaxfiling);
    this.router.navigate(['itRequestDetail', { id: incometaxfiling.requestID }]);
  }

  // todo : name of entity not showing
}
