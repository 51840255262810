import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import Toast from 'awesome-toast-component';
import { firstValueFrom, switchMap } from 'rxjs';
import { DataService, FirebaseService } from 'src/app/services/services';

@Component({
  selector: 'app-gst-request-detail',
  templateUrl: './gst-request-detail.component.html',
  styleUrls: ['./gst-request-detail.component.css']
})
export class GstRequestDetailComponent implements OnInit {
  gstRequest;
  businessDetail;
  saleStatements = [];
  gstReqId: any;
  constructor(private fs: FirebaseService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.gstReqId = String(params.get('id'));
        // console.log(id)
        // getting sales statement with Id[requestId]
        firstValueFrom(this.fs.getAllSalesStatements(this.gstReqId)).then(statements => {
          this.saleStatements = statements;
          // console.log(statements);
        });
        return this.fs.getGSTRequest(this.gstReqId);
      }
      )
    ).subscribe((result) => {
      this.gstRequest = result;
      // console.log(result);
      this.fs.getBusiness(this.gstRequest.businessId).subscribe(business =>
        {this.businessDetail = business;
          // console.log(this.businessDetail)
        });

    })

  }


  updateNew(gstRequest) {
    console.log(gstRequest)
    if(window.confirm('Are you sure want to Change Status to New?')){
      this.fs.updateGstRequestStatusNew(gstRequest);
      new Toast(`Status Changed to NEW.` , {
        position: 'top'});
    }
  }

  updateSubmitted(gstRequest){
    if(window.confirm('Are you sure want to submit GST Request?')){
      this.fs.updateGstRequestStatusSubmitted(gstRequest);
      new Toast(`GST Request Submitted` , {
        position: 'top'});
    }
  }

  updateReview(gstRequest){
    this.fs.updateGstRequestStatusReview(gstRequest);
    new Toast(`GST Request Under Review...` , {
      position: 'top'});
  }

  updateIncomplete(gstRequest){
    if(window.confirm('Set GST Request as Incomplete?')){
      this.fs.updateGstRequestStatusIncomplete(gstRequest);
    }
  }

  updateInvalid(gstRequest){
    if(window.confirm('Set GST Request as Invalid?')){
    this.fs.updateGstRequestStatusInvalid(gstRequest);
  }
  }

  updateDelete(gstRequest){
    if(window.confirm('Delete GST Request?')){
    this.fs.updateGstRequestStatusDeleted(gstRequest);
    }
  }
}
