import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, first, firstValueFrom, map, switchMap, take, tap } from 'rxjs';
import { FirebaseService } from '../services/firebase.service';
import { ETFUser } from '../models/user';
import { AuthService } from '../services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private fs: FirebaseService, private router:Router, private authService:AuthService , private afAuth: AngularFireAuth) { }

   canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){

return this.afAuth.authState.pipe(
  take(1),
  switchMap(
    user =>{
      return this.authService.getCurrentUser(user.uid)
    }
  ),
  map( userData => !!(userData.exists && userData.data()['isAdmin'] == true),
  tap(isAdmin =>{
    if(isAdmin){
    return true;
    }
    else{
      return false;
    }
  })
  )
)


  // var userInfo = this.fs.getUserInfo()

  //  this.authService.getUser().then(user=>{

  //   console.log(user)
  // })


  // if ((userInfo as ETFUser).isAdmin == false) {
  //   this.router.navigate(['billing']);
  //   return false;

  // }
  // this.router.navigate(['/adminHome']);
  // return true;
}

}
