import { Injectable } from '@angular/core';
import { Customer, ItRequest, GstRequest, ContactRequest } from '../models/models';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { BillingItem } from '../models/billingItem';
import { ETFUser } from '../models/user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { first, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { SubscriptionItem } from '../models/subscriptionItem';
import { Ticket } from '../models/ticket';
import { TicketStatus } from '../models/enums';
import { Status } from '../enum/status';
import { LogService } from './log.service';
import { LogEventDataType, LogEventType } from '../enum/log';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  currentUser: any;

  userCollection            : AngularFirestoreCollection;
  customersCollection       : AngularFirestoreCollection;
  subscriptionsCollection   : AngularFirestoreCollection;
  ticketsCollection         : AngularFirestoreCollection;
  gstRequestsCollection     : AngularFirestoreCollection;
  itRequestsCollection      : AngularFirestoreCollection;
  businessCollection        : AngularFirestoreCollection;
  subscriptionItemCollection : AngularFirestoreCollection;

  authState: any = null;

  validSubscription:boolean;

  users: any;

  customersData: Customer[];

  constructor(private db: AngularFirestore, public afAuth: AngularFireAuth,private logService:LogService) {

//---------------------------gets data from users collection----------------------------------------------------------------------
  this.userCollection=db.collection('users', ref =>
  ref.orderBy('name'));

  this.customersCollection=db.collection('customers' , ref=>ref.orderBy('name'));

  this.subscriptionsCollection=db.collection('subscriptions', ref=>ref.orderBy('endDate'));

  this.ticketsCollection=db.collection('tickets', ref=>ref.orderBy('status'));

  this.businessCollection=db.collection('business');

  this.gstRequestsCollection=db.collection('gstRequests')

  this.itRequestsCollection=db.collection('itRequests');

  this.subscriptionItemCollection = db.collection('subscriptionItem' , ref=>ref.orderBy('type'))

  this.getCustomers().subscribe(res=>{
    // console.log(res)
    this.customersData=res

  })

//-----------------------------Gives the current User profile----------------------------------------------------------------------
this.currentUser= this.afAuth.authState.pipe(  first()  );

  this.afAuth.authState.subscribe(data => this.authState = data);
// --------------------------------------------------------------------------------------------------------------------------------
}
//---------------------------gets current user id-------------------
  get currentUserId(): string {
    return this.isAuthenticated ? this.authState.uid : null
  }
  isAuthenticated():boolean{
    if (localStorage.getItem('token')!==null) {
        return true;
    }
    return false;
  }
 //--------------------------------------------------GetsUser Info-------------------------------------------------------
  // TODO: To Remove
 getUserInfo() {
    return this.afAuth.currentUser.then(res=>{
      return this.userCollection.doc(res.uid).valueChanges({ idField: 'uid' });
    })
  }

  getUserData() {
     return firstValueFrom (this.afAuth.authState).then(result=>{
       return this.userCollection.doc(result.uid).valueChanges({ idField: 'uid' });
     })
   }

   getUserbyId(id:any){
    return this.userCollection.doc(id).valueChanges({ idField: 'uid' });
   }
//-----------------------------------------------------------------------------------------------------------------------------


//------------------------------- Add new user to database for the first time--------------------------------------
//------------------------------- Check to see if user exists in database------------------------------------------

  addNewUser(user: ETFUser) {
    // Check if user exists in database
    return firstValueFrom(this.db.collection("users").doc(user.uid).get()).then((doc) => {
      if(!doc.exists){
        this.db.collection('users').doc(user.uid).set(user);
      }
    }).catch((err) => {
      // this.db.collection('users').doc(user.uid).set(user);
console.log(err)
    });

  }

  // --------------------------------------------Customers-------------------
  updateCustomer(customer: Customer) {
    var customerBatch = this.db.firestore.batch();
        var customerId = this.db.createId();
        var customerRef = this.db.doc('customers/' + customer.id).ref;

        var logEntry=this.logService.createLog(customer,LogEventType.verifyCustomer,LogEventDataType.customer)
        var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

        // Step 1
        customer.id = customerId;
        customerBatch.update(customerRef, customer);

      // log Entry for Time Sheet
      customerBatch.set(logEntryRef, logEntry);

    return customerBatch.commit();
    // return this.db.collection<Customer>('customers').doc(customer.id).update(customer);
  }

  getCustomer(userId: string) {
    return this.db
      .collection<Customer>('customers')
      .doc(userId)
      .valueChanges();
  }

  getCustomers() {
    return this.db
      .collection<Customer>('/customers')
      .valueChanges();
  }
  //-------------------------------------------- Get all businesses owned by user or all-------------------
  getBusiness(businessId: string) {
    return this.db
      .collection('businesses')
      .doc(businessId)
      .valueChanges({ idField: 'id' });
  }
  getBusinesses(uid?: any) {
    if (uid) {
      return this.db
        .collection('businesses', (ref) => ref.where('customerUid', '==', uid))
        .valueChanges({ idField: 'id' });
    } else {
      return this.db
        .collection('businesses')
        .valueChanges({ idField: 'id' });
    }
  }

  public getCustomerBusinesssDetails(customers:[]){
    return this.db.collection('businesses', ref=>ref.where('customerUid','in',customers)).valueChanges({idField:'id'})
  }
  //

  //---------------------------------------------------IT Requests--------------------------------------------------------------
  getAllItRequests(isBusiness: boolean, id?: any) {
    if (isBusiness && id) {
      return this.db.collection<ItRequest>('itRequests', (ref) => ref
        .where('businessId', '==', id)
        .where('isActive', '==' , true)
        .orderBy('timestamp', 'desc'))
        .valueChanges({ idField: 'requestID' });
    }

    if (id) {
      return this.db.collection<ItRequest>('itRequests', (ref) => ref
        .where('userId', '==', id)
        .where('isActive', '==' , true)
        .orderBy('timestamp', 'desc'))
        .valueChanges({ idField: 'requestID' });
    } else {
      return this.db.collection<ItRequest>('itRequests', (ref) => ref
      .where('isActive', '==' , true)
      .orderBy('timestamp' , 'desc'))
        .valueChanges({ idField: 'requestID' });
    }
  }

  getlimitedItRequests(isBusiness: boolean, id?: any) {
    if (isBusiness && id) {
      return this.db.collection<ItRequest>('itRequests', (ref) => ref
        .where('businessId', '==', id)
        .where('isActive','==', true)
        .where('status', '==', 0)
        .orderBy('timestamp','desc').limit(5))
        .valueChanges({ idField: 'requestID' });
    }
    if (id) {
      return this.db.collection<ItRequest>('itRequests', (ref) => ref
        .where('userId', '==', id)
        .where('isActive','==', true)
        .where('status', '==', 0)
        .orderBy('timestamp','desc').limit(5))
        .valueChanges({ idField: 'requestID' });
    } else {
      return this.db.collection<ItRequest>('itRequests', (ref) => ref
      .where('isActive','==', true)
      .where('status', '==', 0)
      .orderBy('timestamp', 'desc').limit(5))
        .valueChanges({ idField: 'requestID' });
    }
  }

  getAllItRequestsForBusiness(id?: any) {
    return this.db.collection<ItRequest>('itRequests', (ref) => ref
      .where('businessId', '==', id)
      .where('itRequestType', '==', 1)

      .orderBy('timestamp', 'desc'))

      .valueChanges({ idField: 'requestID' });

  }
  getAllItRequestsForIndividual(id?: any) {
    return this.db.collection<ItRequest>('itRequests', (ref) => ref
      .where('userId', '==', id)
      .orderBy('timestamp', 'desc'))
      .valueChanges({ idField: 'requestID' });

  }

  getITRequest(id: any) {
    return this.db.collection<ItRequest>('itRequests').doc(id).valueChanges({ idField: 'requestID' })
  }

  //--------------------------------------------- GST REQUESTS -----------------------------------

  /** Get all GST requests
   * @param  {any} id? (Optional)
   */
  getAllGstRequests(id?: any) {
    return this.db.collection<GstRequest>('gstRequests', (ref) => ref
      .where('isActive', '==', true)
      .orderBy('timestamp', 'desc'))
      .valueChanges({ idField: 'requestId' });
  }

  getLimitedGstRequests(id: any) {
   var count = 5;
    return this.db.collection<GstRequest>('gstRequests', (ref) => ref
      .where('isActive', '==', true)
      .where('status', '==', 0)
      .orderBy('timestamp', 'desc').limit(count))
      .valueChanges({ idField: 'requestId' });
  }


  /** Get all gst requests for a bunsiness.
   * @param  {any} id Business ID
   */
  getAllGstRequestsForBusiness(id: any) {
    return this.db.collection<GstRequest>('gstRequests', (ref) => ref
      .where('businessId', '==', id)
      .where('isActive', '==', true)
      .orderBy('timestamp', 'desc'))
      .valueChanges({ idField: 'requestId' });
  }

  /** Get single GST Request
   * @param  {any} id GST ID
   */
  getGSTRequest(id: any) {
    return this.db.collection<GstRequest>('gstRequests').doc(id).valueChanges({ idField: 'id' })
  }
  //

  // Bank Statements
  getAllBankStatements(requestID: string) {
    // console.log('RequestID :' + requestID);
    return this.db
      .collection('itRequests')
      .doc(requestID)
      .collection('bankStatements')
      .valueChanges({ idField: 'id' });
  }
  //

  // Loan Statements
  getAllLoanStatements(requestID: string) {
    return this.db
      .collection('itRequests')
      .doc(requestID)
      .collection('loanStatements')
      .valueChanges();
  }
  //

  // Insurance statements
  getAllInsuranceStatements(requestID: string) {
    return this.db
      .collection('itRequests')
      .doc(requestID)
      .collection('insuranceStatements')
      .valueChanges();
  }
  ///

  // Property Statements
  getAllPropertyStatements(requestID: string) {
    return this.db
      .collection('itRequests')
      .doc(requestID)
      .collection('propertyStatements')
      .valueChanges();
  }
  //

  // TDS Statements
  getAllTDSStatements(requestID: string) {
    return this.db
      .collection('itRequests')
      .doc(requestID)
      .collection('tdsStatements')
      .valueChanges();
  }
  //

  // Sales statements
  getAllSalesStatements(requestID: string) {
    //TODO: Change to sales
    return this.db
      .collection('gstRequests')
      .doc(requestID)
      .collection('sales')
      .valueChanges({ idField: 'id' });
  }
  //

  //------------------------------------------- Contact--------------------------
  getContactUsRequest(requestId: string) {
    return this.db.collection('contacts')
      .doc(requestId)
      .valueChanges();
  }

  getAllContactUsRequest() {
    return this.db.collection<ContactRequest>('contacts',
      (ref) => ref.orderBy('timestamp', 'desc')
    )
      .valueChanges({ idField: 'id' });
  }

  //-------------------------------------- Appointments----------------------
  getAllAppointments() {
    return this.db.collection('appointments', (ref) => ref
      // .where('completed', 'not-in', [true])
      .orderBy('appointment', 'desc')
    ).valueChanges({ idField: 'id' });
  }


  updateAppointment(appointment: any) {
    return this.db.collection('appointments').doc(appointment.id).update(appointment);
  }
  // END Appointments


  //---------------------------------- Billing Item-------------------------------------
  createNewBilling(userId, billing: BillingItem) {
    throw new Error("Not IMplemented");
    //TODO: Transaction to be created to update both locations
  }

  getAllBillingforUser(userId: any) {
    return this.db.collection('users').doc(userId).collection('billing').valueChanges({ idField: 'id' });
  }

  getAllBillingforUserTimePeriod(userId, startDate, endDate) {
    return this.db.collection('users').doc(userId).collection('billing', (ref) => ref
      .where('dateofBilling', '>=', startDate)
      .where('dateofBilling', '<=', endDate)
      .orderBy('dateofBilling')
    ).valueChanges({ idField: 'id' });
  }

  getAllBilling() {
    return this.db.collection('billing').valueChanges({ idField: 'id' });
  }

  getAllBillingforTimePeriod(startDate, endDate) {
    return this.db.collection('billing', (ref) => ref
      .where('dateofBilling', '>=', startDate)
      .where('dateofBilling', '<=', endDate)
      .orderBy('dateofBilling')
    ).valueChanges({ idField: 'id' });
  }
  //-----------------------------------------Add New Customer------------------------------------------------------------------------------------------

createNewCustomerDetails(customer:Customer){
  var customerBatch = this.db.firestore.batch();
  var customerId = this.db.createId();
  var customerRef = this.customersCollection.doc(customer.id).ref;

  var logEntry=this.logService.createLog(customer,LogEventType.createCustomer,LogEventDataType.customer)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  customer.id = customerId;
  customerBatch.set(customerRef, customer);

// log Entry for Time Sheet
customerBatch.set(logEntryRef, logEntry);

return customerBatch.commit();
  // return this.customersCollection.add(data)
}

// get new customer
getAllCustomersDetails(uid){
  return this.db.collection('customers', ref=>ref.where('userID','==',uid)).valueChanges({idField:'id'})
}

getCustomerDetails(email){
  return this.db.collection('customers', ref=>ref.where('email','==',email)).valueChanges({idField:'id'})
}

getAllCustomers(){
  return this.customersCollection.valueChanges({idField:'id'});
}

// update customer profile
updateCustomerDetails(customerData:Customer) {
  var customerBatch = this.db.firestore.batch();
  var customerId = this.db.createId();
  var customerRef = this.customersCollection.doc(customerData.id).ref;

  var logEntry=this.logService.createLog(customerData,LogEventType.editCustomer,LogEventDataType.customer)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  customerData.id = customerId;
  customerBatch.update(customerRef, customerData);

// log Entry for Time Sheet
customerBatch.set(logEntryRef, logEntry);

return customerBatch.commit();
  // return this.customersCollection.doc(customerData.id).update(customerData)
 }
 addCustomerwithEmail(customerData:Customer) {
  var customerBatch = this.db.firestore.batch();
  var customerId = this.db.createId();
  var customerRef = this.customersCollection.doc(customerData.id).ref;

  var logEntry=this.logService.createLog(customerData,LogEventType.addCustomer,LogEventDataType.customer)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  customerData.id = customerId;
  customerBatch.update(customerRef, customerData);

// log Entry for Time Sheet
customerBatch.set(logEntryRef, logEntry);

return customerBatch.commit();
  // return this.customersCollection.doc(customerData.id).update(customerData)
 }

// updateCustomer with email
  updateNewCustomerDetails(selectedCustomer:Customer){
    return this.db.collection ('customers').doc(selectedCustomer.id).update(selectedCustomer)
  }

//get users from usercollection
getUsers(){
  return this.userCollection.valueChanges({idField:'id'});
}

// ----------------------------------------subscription---------------------------------------

createSubscription(subscriptions:SubscriptionItem){
  var subscriptionItemBatch = this.db.firestore.batch();
  var subscriptionItemId = this.db.createId();
  var subscriptionItemRef = this.subscriptionsCollection.doc(subscriptions.id).ref;

  var logEntry=this.logService.createLog(subscriptions,LogEventType.addSubscription,LogEventDataType.subscription)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  subscriptions.id = subscriptionItemId;
  subscriptionItemBatch.set(subscriptionItemRef, subscriptions);

// log Entry for Time Sheet
subscriptionItemBatch.set(logEntryRef, logEntry);

return subscriptionItemBatch.commit();
//  return this.subscriptionsCollection.add(data)
}

getSubscriptionDetails(uid){
    return this.db.collection('subscriptions', ref=> ref.where('userId','==',uid)).valueChanges({idField:'id'});
}

getAllSubscriptionDetails(){
  return this.subscriptionsCollection.valueChanges({idField:'id'});
}

updateSubscriptionPlan(subscription:any){
  var subscriptionItemBatch = this.db.firestore.batch();
  var subscriptionItemId = subscription.id;
  var subscriptionItemRef = this.subscriptionsCollection.doc(subscription.id).ref;

  var logEntry=this.logService.createLog(subscription,LogEventType.updateSubscription,LogEventDataType.subscription)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  subscription.id = subscriptionItemId;
  subscriptionItemBatch.update(subscriptionItemRef, subscription);

// log Entry for Time Sheet
subscriptionItemBatch.set(logEntryRef, logEntry);
return subscriptionItemBatch.commit();
  // return this.subscriptionsCollection.doc(subscriptions.id).update(subscriptions);
}

trialSubscription(uid:string,status:boolean){
  return this.subscriptionsCollection.doc(uid).update({trialSubscription:status});
}

updateTrialSubscription(subscription:any){
  var subscriptionItemBatch = this.db.firestore.batch();
  var subscriptionItemId = subscription.id;
  var subscriptionItemRef = this.subscriptionsCollection.doc(subscription.id).ref;

  var logEntry=this.logService.createLog(subscription,LogEventType.cancelTrialSubscription,LogEventDataType.subscription)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  subscription.id = subscriptionItemId;
  subscriptionItemBatch.update(subscriptionItemRef, ({trialSubscription:false}));

// log Entry for Time Sheet
subscriptionItemBatch.set(logEntryRef, logEntry);

return subscriptionItemBatch.commit();
  //return this.subscriptionsCollection.doc(subscriptionsId).update({trialSubscription:false});
}

// -----------------------------------------support Ticket--------------------------------------

addSupportTicket(ticket:Ticket){
  var ticketBatch = this.db.firestore.batch();
  var ticketId = this.db.createId();
  var ticketRef = this.ticketsCollection.doc(ticket.id).ref;
  // log Entry
  var logEntry=this.logService.createLog(ticket,LogEventType.addRaiseTicket,LogEventDataType.raiseTicket)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  ticket.id = ticketId;
  ticketBatch.set(ticketRef,ticket);
// log Entry for Time Sheet
ticketBatch.set(logEntryRef, logEntry);
return ticketBatch.commit();
//  return this.ticketsCollection.add(ticket)
}

getSupportTickets(uid){
 return this.db.collection('tickets', ref=>ref.where('userId','==',uid)).valueChanges({idField:'id'});
}

getAllTickets(){
  return this.ticketsCollection.valueChanges({idField:'id'});
}


updateTicketOpen(ticket:any){
  var ticketBatch = this.db.firestore.batch();
  var ticketId = ticket.id;
  var ticketRef = this.ticketsCollection.doc(ticketId).ref;
  // log Entry
  var logEntry=this.logService.createLog(ticket,LogEventType.changeRaiseTicketStatusOpen,LogEventDataType.raiseTicket)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  ticket.id = ticketId;
  ticketBatch.update(ticketRef, {status:TicketStatus.open});
// log Entry for Time Sheet
ticketBatch.set(logEntryRef, logEntry);
return ticketBatch.commit();
  // return this.ticketsCollection.doc(ticketId).update({status:TicketStatus.open});
}
updateTicketClose(ticket:any){
  var ticketBatch = this.db.firestore.batch();
  var ticketId = ticket.id;
  var ticketRef = this.ticketsCollection.doc(ticketId).ref;
  // log Entry
  var logEntry=this.logService.createLog(ticket,LogEventType.changeRaiseTicketStatusClosed,LogEventDataType.raiseTicket)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  ticket.id = ticketId;
  ticketBatch.update(ticketRef, {status:TicketStatus.closed});
// log Entry for Time Sheet
ticketBatch.set(logEntryRef, logEntry);
return ticketBatch.commit();
  // return this.ticketsCollection.doc(ticketId).update({status:TicketStatus.closed});
}
updateTicketpending(ticket:any){
  var ticketBatch = this.db.firestore.batch();
  var ticketId = ticket.id;
  var ticketRef = this.ticketsCollection.doc(ticketId).ref;
  // log Entry
  var logEntry=this.logService.createLog(ticket,LogEventType.changeRaiseTicketStatusNew,LogEventDataType.raiseTicket)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  ticket.id = ticketId;
  ticketBatch.update(ticketRef, {status:TicketStatus.new});
// log Entry for Time Sheet
ticketBatch.set(logEntryRef, logEntry);
return ticketBatch.commit();
  // return this.ticketsCollection.doc(ticketId).update({status:TicketStatus.new});
}
updateTicketDeferred(ticket:any){
  var ticketBatch = this.db.firestore.batch();
  var ticketId = ticket.id;
  var ticketRef = this.ticketsCollection.doc(ticketId).ref;
  // log Entry
  var logEntry=this.logService.createLog(ticket,LogEventType.changeRaiseTicketStatusDeferred,LogEventDataType.raiseTicket)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  ticket.id = ticketId;
  ticketBatch.update(ticketRef, {status:TicketStatus.deferred});
// log Entry for Time Sheet
ticketBatch.set(logEntryRef, logEntry);
return ticketBatch.commit();
  // return this.ticketsCollection.doc(ticketId).update({status:TicketStatus.deferred});
}


// ----------------Gst Request Status-----------------------------------------------------
updateGstRequestStatusNew(gstRequests:any) {
  var gstRequestBatch = this.db.firestore.batch();
  var gstRequestId = gstRequests.id;
  var gstRequestRef = this.gstRequestsCollection.doc(gstRequestId).ref;
  // log Entry
  var logEntry=this.logService.createLog(gstRequests,LogEventType.changeGstStatusNew,LogEventDataType.gstRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  gstRequests.id = gstRequestId;
  gstRequestBatch.update(gstRequestRef, {status:Status.NEW});
// log Entry for Time Sheet
gstRequestBatch.set(logEntryRef, logEntry);
return gstRequestBatch.commit();
 }

 updateGstRequestStatusSubmitted(gstRequests:any) {
  var gstRequestBatch = this.db.firestore.batch();
  var gstRequestId = gstRequests.id;
  var gstRequestRef = this.gstRequestsCollection.doc(gstRequestId).ref;
  var logEntry=this.logService.createLog(gstRequests,LogEventType.changeGstStatusSubmitted,LogEventDataType.gstRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  gstRequests.id = gstRequestId;
  gstRequestBatch.update(gstRequestRef, {status:Status.SUBMITTED});
// log Entry for Time Sheet
gstRequestBatch.set(logEntryRef, logEntry);
return gstRequestBatch.commit();
 }

 updateGstRequestStatusReview(gstRequests:any) {
  var gstRequestBatch = this.db.firestore.batch();
  var gstRequestId = gstRequests.id;
  var gstRequestRef = this.gstRequestsCollection.doc(gstRequestId).ref;
  var logEntry=this.logService.createLog(gstRequests,LogEventType.changeGstStatusReview,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  gstRequests.id = gstRequestId;
  gstRequestBatch.update(gstRequestRef, {status:Status.REVIEW});
// log Entry for Time Sheet
gstRequestBatch.set(logEntryRef, logEntry);
return gstRequestBatch.commit();
 }

 updateGstRequestStatusIncomplete(gstRequests:any) {
  var gstRequestBatch = this.db.firestore.batch();
  var gstRequestId = gstRequests.id;
  var gstRequestRef = this.gstRequestsCollection.doc(gstRequestId).ref;
  var logEntry=this.logService.createLog(gstRequests,LogEventType.changeGstStatusIncompleted,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  gstRequests.id = gstRequestId;
  gstRequestBatch.update(gstRequestRef, {status:Status.INCOMPLETE});
// log Entry for Time Sheet
gstRequestBatch.set(logEntryRef, logEntry);
return gstRequestBatch.commit();
 }

 updateGstRequestStatusInvalid(gstRequests:any) {
  var gstRequestBatch = this.db.firestore.batch();
  var gstRequestId = gstRequests.id;
  var gstRequestRef = this.gstRequestsCollection.doc(gstRequestId).ref;
  var logEntry=this.logService.createLog(gstRequests,LogEventType.changeGstStatusInvalid,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  gstRequests.id = gstRequestId;
  gstRequestBatch.update(gstRequestRef, {status:Status.INVALID});
// log Entry for Time Sheet
gstRequestBatch.set(logEntryRef, logEntry);
return gstRequestBatch.commit();
 }

 updateGstRequestStatusDeleted(gstRequests:any) {
  var gstRequestBatch = this.db.firestore.batch();
  var gstRequestId = gstRequests.id;
  var gstRequestRef = this.gstRequestsCollection.doc(gstRequestId).ref;
  var logEntry=this.logService.createLog(gstRequests,LogEventType.changeGstStatusDeleted,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  gstRequests.id = gstRequestId;
  gstRequestBatch.update(gstRequestRef, {status:Status.DELETED});
// log Entry for Time Sheet
gstRequestBatch.set(logEntryRef, logEntry);
return gstRequestBatch.commit();
 }

// ------------------------It Request Status -------------------------------
updateITRequestStatusNew(itRequests:any) {
  var ItRequestBatch = this.db.firestore.batch();
  var ItRequestId = itRequests.requestID;
  var ItRequestRef = this.itRequestsCollection.doc(ItRequestId).ref;
  // log Entry
  var logEntry=this.logService.createLog(itRequests,LogEventType.changeItStatusNew,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  itRequests.id = ItRequestId;
  ItRequestBatch.update(ItRequestRef, {status:Status.NEW});
// log Entry for Time Sheet
ItRequestBatch.set(logEntryRef, logEntry);
return ItRequestBatch.commit();
 }

 updateITRequestStatusSubmitted(itRequests:any) {
  var ItRequestBatch = this.db.firestore.batch();
  var ItRequestId = itRequests.requestID;
  var ItRequestRef = this.itRequestsCollection.doc(ItRequestId).ref;
  var logEntry=this.logService.createLog(itRequests,LogEventType.changeItStatusSubmitted,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  itRequests.id = ItRequestId;
  ItRequestBatch.update(ItRequestRef, {status:Status.SUBMITTED});
// log Entry for Time Sheet
ItRequestBatch.set(logEntryRef, logEntry);
return ItRequestBatch.commit();
 }

 updateITRequestStatusReview(itRequests:any) {
  var ItRequestBatch = this.db.firestore.batch();
  var ItRequestId = itRequests.requestID;
  var ItRequestRef = this.itRequestsCollection.doc(ItRequestId).ref;
  var logEntry=this.logService.createLog(itRequests,LogEventType.changeItStatusReview,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  itRequests.id = ItRequestId;
  ItRequestBatch.update(ItRequestRef, {status:Status.REVIEW});
// log Entry for Time Sheet
ItRequestBatch.set(logEntryRef, logEntry);
return ItRequestBatch.commit();
 }

 updateITRequestStatusIncomplete(itRequests:any) {
  var ItRequestBatch = this.db.firestore.batch();
  var ItRequestId = itRequests.requestID;
  var ItRequestRef = this.itRequestsCollection.doc(ItRequestId).ref;
  var logEntry=this.logService.createLog(itRequests,LogEventType.changeItStatusIncompleted,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  itRequests.id = ItRequestId;
  ItRequestBatch.update(ItRequestRef, {status:Status.INCOMPLETE});
// log Entry for Time Sheet
ItRequestBatch.set(logEntryRef, logEntry);
return ItRequestBatch.commit();
 }

 updateITRequestStatusInvalid(itRequests:any) {
  var ItRequestBatch = this.db.firestore.batch();
  var ItRequestId = itRequests.requestID;
  var ItRequestRef = this.itRequestsCollection.doc(ItRequestId).ref;
  var logEntry=this.logService.createLog(itRequests,LogEventType.changeItStatusInvalid,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  itRequests.id = ItRequestId;
  ItRequestBatch.update(ItRequestRef, {status:Status.INVALID});
// log Entry for Time Sheet
ItRequestBatch.set(logEntryRef, logEntry);
return ItRequestBatch.commit();
 }

 updateITRequestStatusDeleted(itRequests:any) {
  var ItRequestBatch = this.db.firestore.batch();
  var ItRequestId = itRequests.requestID;
  var ItRequestRef = this.itRequestsCollection.doc(ItRequestId).ref;
  var logEntry=this.logService.createLog(itRequests,LogEventType.changeItStatusDeleted,LogEventDataType.itRequest)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;
  // Step 1
  itRequests.id = ItRequestId;
  ItRequestBatch.update(ItRequestRef, {status:Status.DELETED});
// log Entry for Time Sheet
ItRequestBatch.set(logEntryRef, logEntry);
return ItRequestBatch.commit();
 }

// updateItNew(requestID:string){
//   return this.itRequestsCollection.doc(requestID).update({status:Status.NEW});
// }


// -------------------------------------Login - Agree to T&c-----------------------------------------
updateIfAgree(userId:string){
  return this.userCollection.doc(userId).update({ifAgree:true});
}

// ----------------------------------- Subscription Item -------------------------------------------

createSubscriptionItem(subscriptionItem:any){
  var subscriptionItemBatch = this.db.firestore.batch();
  var subscriptionItemId = this.db.createId();;
  var subscriptionItemRef = this.subscriptionItemCollection.doc(subscriptionItem.id).ref;

  var logEntry=this.logService.createLog(subscriptionItem,LogEventType.createSubscriptionItem,LogEventDataType.subscriptionItem)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  subscriptionItem.id = subscriptionItemId;
  subscriptionItemBatch.set(subscriptionItemRef, subscriptionItem);

// log Entry for Time Sheet
subscriptionItemBatch.set(logEntryRef, logEntry);
return subscriptionItemBatch.commit();
  // return this.subscriptionItemCollection.add(subscriptionItem)
 }

getSUbscriptionItem(){
 return this.subscriptionItemCollection.valueChanges({idField:'id'});
}

updateSubscriptionItem(subscriptionItem:any){
  var subscriptionItemBatch = this.db.firestore.batch();
  var subscriptionItemId = subscriptionItem.id;
  var subscriptionItemRef = this.subscriptionItemCollection.doc(subscriptionItem.id).ref;

  var logEntry=this.logService.createLog(subscriptionItem,LogEventType.editSubscriptionItem,LogEventDataType.subscriptionItem)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  subscriptionItem.id = subscriptionItemId;
  subscriptionItemBatch.update(subscriptionItemRef, subscriptionItem);

// log Entry for Time Sheet
subscriptionItemBatch.set(logEntryRef, logEntry);
return subscriptionItemBatch.commit();
  // return this.subscriptionItemCollection.doc(SubscriptionItem.id).update(SubscriptionItem)
}
deleteSubscriptionItem(subscriptionItem:any){
  var subscriptionItemBatch = this.db.firestore.batch();
  var subscriptionItemId = subscriptionItem.id;
  var subscriptionItemRef = this.subscriptionItemCollection.doc(subscriptionItem.id).ref;

  var logEntry=this.logService.createLog(subscriptionItem,LogEventType.deleteSubscriptionItem,LogEventDataType.subscriptionItem)
  var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

  // Step 1
  subscriptionItem.id = subscriptionItemId;
  subscriptionItemBatch.delete(subscriptionItemRef);

// log Entry for Time Sheet
subscriptionItemBatch.set(logEntryRef, logEntry);
return subscriptionItemBatch.commit();
  // return this.subscriptionItemCollection.doc(SubscriptionItem.id).delete()
}

 // -----------------------------------------------Update  User Verification and Subscription----------------------------------------


  updateSubscripction(uid:string,status:boolean){
    return this.userCollection.doc(uid).update({validSubscription:status});
  }

  updateUserValidSubscription(data:any){
    // console.log(data.uid)
    var userSubscriptionBatch = this.db.firestore.batch();
    var userSubscriptionId = data.uid;
    var userSubscriptionRef = this.userCollection.doc(data.uid).ref;

    var logEntry=this.logService.createLog(data,LogEventType.startUserSubscription,LogEventDataType.startUserSubscription)
    var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

    // Step 1
    data.uid = userSubscriptionId;
    userSubscriptionBatch.update(userSubscriptionRef, ({validSubscription:true}));

  // log Entry for Time Sheet
  userSubscriptionBatch.set(logEntryRef, logEntry);
  return userSubscriptionBatch.commit();
  }

  updateUserunsubscribe(data:any){
    // console.log(data.uid)
    var userSubscriptionBatch = this.db.firestore.batch();
    var userSubscriptionId = data.uid;
    var userSubscriptionRef = this.userCollection.doc(data.uid).ref;

    var logEntry=this.logService.createLog(data,LogEventType.pauseUserSubscription,LogEventDataType.pauseSubscription)
    var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

    // Step 1
    data.uid = userSubscriptionId;
    userSubscriptionBatch.update(userSubscriptionRef, ({validSubscription:false}));

  // log Entry for Time Sheet
  userSubscriptionBatch.set(logEntryRef, logEntry);
  return userSubscriptionBatch.commit();
  }

  updateUserVerification(data:any){
    console.log(data.uid)
    var userVerificationBatch = this.db.firestore.batch();
    var userVerificationId = data.uid;
    var userVerificationRef = this.userCollection.doc(data.uid).ref;

    var logEntry=this.logService.createLog(data,LogEventType.userVerification,LogEventDataType.Verification)
    var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

    // Step 1
    data.uid = userVerificationId;
    userVerificationBatch.update(userVerificationRef, ({verified:true}));

  // log Entry for Time Sheet
  userVerificationBatch.set(logEntryRef, logEntry);
  return userVerificationBatch.commit();

    // return this.subscriptionItemCollection.doc(SubscriptionItem.id).update(SubscriptionItem)
  }

  updateUserunVerified(data:any){
    console.log(data.uid)
    var userVerificationBatch = this.db.firestore.batch();
    var userVerificationId = data.uid;
    var userVerificationRef = this.userCollection.doc(data.uid).ref;

    var logEntry=this.logService.createLog(data,LogEventType.userUnverified,LogEventDataType.Verification)
    var logEntryRef = this.db.doc('logs/' + logEntry.logId).ref;

    // Step 1
    data.uid = userVerificationId;
    userVerificationBatch.update(userVerificationRef, ({verified:false}));

  // log Entry for Time Sheet
  userVerificationBatch.set(logEntryRef, logEntry);
  return userVerificationBatch.commit();

    // return this.subscriptionItemCollection.doc(SubscriptionItem.id).update(SubscriptionItem)
  }

  updateVerification(uid:string,status:boolean){
    return this.userCollection.doc(uid).update({verified:status});
  }

}
