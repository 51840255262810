
<div class="sectionMobile md:p-10 md:m-10 ">
  <div class="grid md:grid-cols-2">
    <div class="mx-auto">
      <img src="/assets/images/landingPage.jpg" width="400px">
    </div>

    <div class="p-5 text-justify">
      "Welcome to Easy Tax Filing! Our platform is designed to simplify the process of managing income tax and GST filing requests for individuals and businesses alike.
With Easy Tax Filing, you can now manage all of your clients' tax filing requests in one, easy-to-use place. Our user-friendly dashboard provides a comprehensive view of all your filing requests, enabling you to track their status and progress, and ensuring that nothing slips through the cracks.
Moreover, our innovative technology allows customers to simply take a picture of their relevant records, eliminating the need for email threads and document attachments. This not only saves time, but also ensures that all necessary documents are kept in one place, making it easier for you to access and manage them.
At Easy Tax Filing, we understand the importance of accuracy and timeliness when it comes to tax filing. That's why we've made it our mission to provide you with a reliable, efficient, and hassle-free tax filing experience.
So why wait? Sign up for Easy Tax Filing today and discover a smarter way to manage your tax filing requests!"
    </div>



  </div>

  </div>

  <!-- <div class="text-justify ">

    <div>
      <div class="font-semibold md:text-4xl p-2">
        Easy Tax Filing: Your Auditor's Partner
      </div>

      <div class="m-5 md:text-sm">
        Are you tired of the stress and headache that comes with tax season?
        Easy Tax Filing is here to help! Our subscription plan, priced at just Rs.999 per month,
        makes it easy and convenient to file your taxes and stay on top of your financial records.
      </div>
    </div>


      <div class="font-semibold p-2 md:text-2xl">
        How it Works
      </div>

      <ol class="m-5 md:text-sm">
        <li class="p-2">
           1.Take pictures of your bank statements, loan statements, insurance statements, property documents, and Form16/16A with your phone.
        </li>
        <li class="p-2">
            2.Send them to your auditor with just a tap of a button on our mobile application.
        </li>
        <li class="p-2">
            3.Stay on top of your taxes and financial records with our easy-to-use web-based dashboard.
        </li>
      </ol>


<div class="font-semibold p-2 md:text-2xl "> Benefits</div>
<ul class="m-5 md:text-sm" style="list-style-type:disc;">
<li class="p-2">
  Easy and convenient tax filing
</li>
<li class="p-2">
  Affordable subscription plan
</li>
<li class="p-2">
  Mobile application for clients
  </li>
  <li class="p-2">
    Web-based dashboard for auditors
  </li>
</ul>
  <div class="m-5 md:text-sm">
    At Easy Tax Filing, we understand the importance of data security and take it very seriously.
    We store your data on secure servers and protect it with encryption to ensure that it remains safe at all times.
    If you decide to cancel your subscription, we will delete all of your data within 30 days of your request.
    Don't let tax season stress you out - choose Easy Tax Filing and file your taxes with ease.
    Sign up today and start taking control of your financial future!
  </div>
</div> -->


