<div class="sectionMobile md:m-10 md:p-10  mt-2">
  <div class=" bg-white  shadow p-4 mt-5 ">
    <div *ngIf="itFilingRequest">
      <p class="font-bold">Request Date: {{itFilingRequest.timestamp.toDate() | date}} </p>
      <p>Assesment Year: {{itFilingRequest.assesmentYear}} {{ itFilingRequest.name }}</p>
    </div>
  </div>
  <div class="">
    <div class="bg-white mt-2 shadow p-4" *ngIf="customerDetail">
      <div class="">
        <div class="text-2xl font-bold">Customer Details</div>
        <h5 class="text-blue-400">{{customerDetail.name }}</h5>
        <p class=" mb-2 ">Address: {{ customerDetail.address }}</p>
        <p class=" mb-2 ">Email Address: {{ customerDetail.email }}</p>
        <p class=" mb-2 ">PAN Card: {{ customerDetail.panCardNumber }}</p>
        <p class=" mb-2 ">Phone Number: {{ customerDetail.phoneNumber }}</p>
      </div>

      <div class="bg-white mt-2 shadow p-4" *ngIf="itFilingRequest && itFilingRequest.itRequestType == 1">
        <div class="text-2xl font-bold">Business Details</div>
        <h5 class="card-title" *ngIf="businessDetail">
          {{ businessDetail.name }}
        </h5>
        <div class="card-body" *ngIf="businessDetail">
          <p class="card-subtitle mb-2 text-muted">Address: {{ businessDetail.address }}</p>
          <p class="card-subtitle mb-2 text-muted">Email Address: {{ businessDetail.emailAddress }}</p>
          <p class="card-subtitle mb-2 text-muted">PAN Card: {{ businessDetail.panCardNumber }}</p>
          <p class="card-subtitle mb-2 text-muted">Phone Number: {{ businessDetail.phoneNumber }}</p>
        </div>
      </div>

      <div> Status</div>
      <div class="flex mx-auto">
        <h6 class="statusbtn" (click)="updateNew(itFilingRequest)">
          <i class="text-blue-500  list-disc "
          [ngClass]="itFilingRequest.status==0? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          New
        </h6>
        <h6 class="statusbtn" (click)="updateSubmitted(itFilingRequest)">
          <i class="text-green-500  list-disc"
            [ngClass]="itFilingRequest.status==1? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
            SUBMITTED
        </h6>
        <h6 class="statusbtn" (click)="updateReview(itFilingRequest)">
          <i class="text-orange-500  list-disc"
          [ngClass]="itFilingRequest.status==2? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          REVIEW
        </h6>
        <h6 class="statusbtn" (click)="updateIncomplete(itFilingRequest)">
          <i class="text-red-500  list-disc"
          [ngClass]="itFilingRequest.status==3? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          INCOMPLETE
        </h6>
        <h6 class="statusbtn" (click)="updateInvalid(itFilingRequest)">
          <i class="text-red-500  list-disc"
          [ngClass]="itFilingRequest.status==4? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          INVALID
        </h6>
        <h6 class="statusbtn" (click)="updateDelete(itFilingRequest)">
          <i class="text-red-500  list-disc"
          [ngClass]="itFilingRequest.status==5? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          DELETED
        </h6>
      </div>
    </div>
    <div class="bg-white mt-2 shadow p-4">
      <div>
        <h5 class="text-2xl font-bold">Bank Statements</h5>
      </div>
      <app-no-data *ngIf="bankStatements.length == 0"></app-no-data>
      <div class="grid grid-cols-3 gap-2 p-4 bg-blue-50 rounded overflow-y-visible"
        *ngFor="let statement of bankStatements">
        <div class="">{{statement.name }}</div>
        <div class="">
          <img class="rounded w-1/2" src="{{ statement.fileUrl }}" alt="" />
        </div>
        <div class="">
          <a target="_blank" rel="noopener noreferrer" download="{{ statement.fileUrl }}"
            href="{{ statement.fileUrl }}">
            <button class="btn btn-block btn-taxPrism-outline">
              Download File
            </button>
          </a>
        </div>
      </div>
      <!-- INSURANCE -->
      <div class="mt-5">
        <h5 class="text-2xl font-bold">Insurance Statements</h5>
      </div>
      <app-no-data *ngIf="insuranceStatements.length == 0"></app-no-data>
      <div class="grid grid-cols-3 gap-2 p-4 bg-blue-50 rounded overflow-y-visible"
        *ngFor="let statement of insuranceStatements">
        <div class="col">
          <div class="">{{ statement.name }}</div>
          <div class="">
            <img class="rounded w-1/2" src="{{ statement.fileUrl }}" alt="" />
          </div>
          <div class="">
            <a target="_blank" rel="noopener noreferrer" download="{{ statement.fileUrl }}"
              href="{{ statement.fileUrl }}">
              <button class="btn btn-block btn-taxPrism-outline">
                Download File
              </button>
            </a>
          </div>
        </div>
      </div>
      <!-- Insurance -->
      <div class="mt-5">
        <h5 class="text-2xl font-bold">FORM16/16A</h5>
      </div>
      <app-no-data *ngIf="tdsStatements.length == 0"></app-no-data>
      <div class="grid grid-cols-3 gap-2 p-4 bg-blue-50 rounded overflow-y-visible"
        *ngFor="let statement of tdsStatements">
        <div class="col">
          <div class="row">{{ statement.name }}</div>
          <div class="row">
            <img class="rounded w-1/2" src="{{ statement.fileUrl }}" />
          </div>
          <div class="row">
            <a target="_blank" rel="noopener noreferrer" download="{{ statement.fileUrl }}"
              href="{{ statement.fileUrl }}">
              <button class="btn btn-block btn-taxPrism-outline">
                Download File
              </button>
            </a>
          </div>
        </div>
      </div>
      <!-- LOAN -->
      <div class="mt-5">
        <h5 class="text-2xl font-bold">Loan Statements</h5>
      </div>
      <app-no-data *ngIf="loanStatements.length == 0"></app-no-data>
      <div class="grid grid-cols-3 gap-2 p-4 bg-blue-50 rounded overflow-y-visible"
        *ngFor="let statement of loanStatements">
        <div class="col">
          <div class="row">{{ statement.name }}</div>
          <div class="row">
            <img class="rounded w-1/2" src="{{ statement.fileUrl }}" alt="" />
          </div>
          <div class="row">
            <a target="_blank" rel="noopener noreferrer" download="{{ statement.fileUrl }}"
              href="{{ statement.fileUrl }}">
              <button class="btn btn-block btn-taxPrism-outline">
                Download
              </button>
            </a>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <h5 class="text-2xl font-bold">Property/Rental/Asset Details</h5>
      </div>
      <app-no-data *ngIf="rentalStatements.length == 0"></app-no-data>
      <div class="grid grid-cols-3 gap-2 p-4 bg-blue-50 rounded overflow-y-visible"
        *ngFor="let statement of rentalStatements">
        <div class="col">
          <div class="row">{{ statement.name }}</div>
          <div class="row">
            <img src="{{ statement.fileUrl }}" alt="" width="100%" height="50%" />
          </div>
          <div class="row">
            <a target="_blank" rel="noopener noreferrer" download="{{ statement.fileUrl }}"
              href="{{ statement.fileUrl }}">
              <button class="btn btn-block btn-taxPrism-outline">
                Download
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
