import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Toast from 'awesome-toast-component';
import { ETFUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { firstValueFrom} from 'rxjs'
import { TicketStatus } from 'src/app/models/enums';

@Component({
  selector: 'app-raise-ticket',
  templateUrl: './raise-ticket.component.html',
  styleUrls: ['./raise-ticket.component.css']
})
export class RaiseTicketComponent {

  formvalue: FormGroup;
  user: ETFUser;
  userId: string;
  supportTicket: any;


  constructor( private fs:FirebaseService, private authService:AuthService, private formBuilder:FormBuilder){  }

  ngOnInit(): void {
    this.formvalue=new FormGroup({
      title:new FormControl(),
      textTicket:new FormControl(),
    });

    this.formvalue = this.formBuilder.group({
      title : ['', Validators.required],
      textTicket:['', Validators.required],
    })

    this.fs.getUserData().then((res) => {

      firstValueFrom(res).then((user) => {

        this.user = user as ETFUser;
        this.userId=this.user.uid;

        this.fs.getSupportTickets(this.userId).subscribe(res=>{
          this.supportTicket=res;
        })
      })
    })
  }

  addTicket(){

    const ticket={
      userId:this.userId,
      name:this.user.name,
      email:this.user.email,
      date:new Date(),
      title:this.formvalue.value.title,
      textTicket:this.formvalue.value.textTicket,
      status:TicketStatus.new,
    }
    this.fs.addSupportTicket(ticket)
    new Toast(`Support Ticket Raised`, {
      position: 'top'});
      this.formvalue.reset();
  }

}
