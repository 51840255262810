import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';


// Firebase
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';

// Environment Variables
import { environment } from 'src/environments/environment';
// Directives
import { GoogleSigninDirective } from './directives/google-signin.directive';

// Components

import {
  CustomersComponent,
  ItRequestsComponent,
  GstRequestsComponent,
  BusinessesComponent,
  CustomerDetailComponent,
  BusinessDetailComponent,
  HomeComponent,
  ItRequestDetailComponent,
  GstRequestDetailComponent,
  ContactRequestsComponent,
  UserRegistrationComponent,
  AppointmentsComponent
} from './components/components';
import { BusinessTypePipe, ITRequestTypePipe, GstRequestTypePipe } from './pipes/pipes';
import { OtherServicesPipe } from './pipes/other-services.pipe';
import { SlotNumberPipe } from './pipes/slot-number.pipe';
import { NavigationComponent, LoginComponent, NoDataComponent, PrivacyPolicyComponent } from './components/common/common';
import { BillingComponent } from './components/Payment/billing/billing.component';
import { SubscribeTodayComponent } from './common/subscribe-today/subscribe-today.component';
import { UsersComponent } from './admin/users/users.component';
import { TermsandconditionsComponent } from './components/common/termsandconditions/termsandconditions.component';
import { SubscriptionComponent } from './components/Payment/subscription/subscription.component';
import { RaiseTicketComponent } from './components/common/raise-ticket/raise-ticket.component';
import { TicketsComponent } from './components/common/tickets/tickets.component';
import { DeleteComponent } from './data/delete/delete.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { LandingComponent } from './components/common/landing/landing.component';
import { UserCustomerComponent } from './admin/user-customer/user-customer.component';
import { ActivityLogsComponent } from './admin/activity-logs/activity-logs.component';
import { SubscriptionPlansComponent } from './admin/subscription-plans/subscription-plans.component';
import { SubscribtionDetailsComponent } from './admin/subscribtion-details/subscribtion-details.component';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    LoginComponent,
    GoogleSigninDirective,
    CustomersComponent,
    ItRequestsComponent,
    GstRequestsComponent,
    BusinessesComponent,
    CustomerDetailComponent,
    BusinessDetailComponent,
    BusinessTypePipe,
    ITRequestTypePipe,
    ItRequestDetailComponent,
    NoDataComponent,
    GstRequestTypePipe,
    GstRequestDetailComponent,
    ContactRequestsComponent,
    OtherServicesPipe,
    AppointmentsComponent,
    SlotNumberPipe,
    PrivacyPolicyComponent,
    UserRegistrationComponent,
    BillingComponent,
    SubscribeTodayComponent,
    UsersComponent,
    TermsandconditionsComponent,
    SubscriptionComponent,
    RaiseTicketComponent,
    TicketsComponent,
    DeleteComponent,
    FooterComponent,
    LandingComponent,
    UserCustomerComponent,
    ActivityLogsComponent,
    SubscriptionPlansComponent,
    SubscribtionDetailsComponent,
    AddCustomerComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    AutocompleteLibModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
