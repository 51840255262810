import { Directive, HostListener } from '@angular/core';
import * as firebase from 'firebase/app'
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from 'firebase/auth';

@Directive({
  selector: '[appGoogleSignin]'
})
export class GoogleSigninDirective {

  constructor(private afAuth: AngularFireAuth, private router: Router) { }

  @HostListener('click')
  onClick() {
    this.afAuth.signInWithPopup(new GoogleAuthProvider()).then(res =>
      this.router.navigate([''])
    );
  }

}
