import { OnInit, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slotNumber'
})
export class SlotNumberPipe implements PipeTransform {

  appointmentSlots = [];

  transform(value: unknown, ...args: unknown[]): string {
    console.log(value);
    switch (value) {
      case 1: return '11.00.00 AM	to 11.30.00 AM';

      case 2: return '11.30.00 AM	to 12.00.00 PM';

      case 3: return '12.00.00 PM	to	12.30.00 PM';

      case 4: return '12.30.00 PM	to	1.00.00 PM';

      case 5: return '1.00.00 PM	to	1.30.00 PM';

      case 6: return '1.30.00 PM	to	2.00.00 PM';

      case 7: return '2.00.00 PM	to	2.30.00 PM';

      case 8: return '2.30.00 PM	to	3.00.00 PM';

      case 9: return '3.00.00 PM	to	3.30.00 PM';

      case 10: return '3.30.00 PM	to	4.00.00 PM';

      case 11: return '4.00.00 PM	to	4.30.00 PM';

      case 12: return '4.30.00 PM	to	5.00.00 PM';

      case 13: return '5.00.00 PM	to	5.30.00 PM';

      default:
        return 'Please call support';
    }

  }

}
