<div class="sectionMobile md:m-10 md:p-10">
  <div class="text-lg">Subscription Plans</div>
  <div class="m-4 p-2">
  <form [formGroup]="formvalue" >
    <div class="grid md:grid-cols-2 gap-3 uppercase">
<div>
  <!-- <div class="flex m-2">
    Plan Name
    <input type="text" class="p-2 ml-auto" placeholder="Plan Name" formControlName="planName">
  </div> -->
  <div class="flex  m-2">
    <div class="inline-flex">
      <p class="uppercase">Plan Type</p>
      <!-- <div *ngIf="editbtn==true" class="text-blue-400 ml-4">
      <p *ngIf="editSelectedItem.type==0">Trial</p>
      <p *ngIf="editSelectedItem.type==1">Silver</p>
      <p *ngIf="editSelectedItem.type==2">Gold</p>
      <p *ngIf="editSelectedItem.type==3">Diamond</p>
      </div> -->
    </div>
    <select class="bg-slate-200 formControl p-2 rounded w-48 ml-auto" name="" id="" formControlName="planType" >
      <option [value]=-1 disabled>Select Activity</option>
      <option [value]=0>Trial</option>
      <option [value]=1>Silver</option>
      <option [value]=2>Gold</option>
      <option [value]=3>Diamond</option>
    </select>
  </div>
  <div class="flex m-2">
    number Of Customers
    <input type="number" class="p-2 ml-auto" placeholder="Number Of Customers" formControlName="numberOfCustomers">
  </div>

  <div class="flex m-2">
    baseValue
    <input type="number" class="p-2 ml-auto" placeholder="Base Value" formControlName="baseValue">
  </div>
  <div class="flex m-2">
    Discount 12 Months
    <input type="number" class="p-2 ml-auto" placeholder="Discount %" formControlName="discountyear">
  </div>
  <div class="flex m-2">
    description
    <input type="text" class="p-2 ml-auto" placeholder="description" formControlName="description">
  </div>
  </div>
  <div>



  <div class="flex m-2">
    Subscription Rate per Customer
    <input type="number" class="p-2 ml-auto" placeholder="Customer Rate" formControlName="customerSubscriptionRate">
  </div>

  <div class="flex m-2">
    gst Percent
    <input type="number" class="p-2 ml-auto"  placeholder="Gst Percent" formControlName="gstPercent">
  </div>

  <div class="flex m-2">
    promotional Value
    <input type="number" class="p-2 ml-auto"  placeholder="Promotional Value" formControlName="promotionalValue">
  </div>
  <div class="flex m-2">
    Discount 24 Months
    <input type="number" class="p-2 ml-auto" placeholder="Discount %" formControlName="discountyears">
  </div>

</div>
</div>
<div class="flex">
<button (click)="createPost()" *ngIf="!editbtn" class=" bg-blue-800 rounded shadow p-2 text-white font-bold ml-auto">Create</button>
<button class="btn ml-auto" *ngIf="editbtn" (click)="updateSubscriptionDetails()">Update</button>
   </div>
  </form>
  </div>

    <div class=" p-2 m-2 rounded shadow  justify-around" >
      <div class="m-1 p-2 grid md:grid-cols-12 gap-2 mx-auto rounded shadow text-center uppercase">
        <div class="">Plan</div>
        <div>Customers</div>
        <div>customer Cost </div>
        <div>Base </div>
        <div>GST %</div>
        <div>GST </div>
        <div>description</div>
        <div>subscription </div>
        <div>discount 12M</div>
        <div>discount 24M</div>
        <div>Total</div>
        <div>promotion </div>

      </div>
      <div class="grid md:grid-cols-12 gap-2 text-black text-center shadow rounded p-2 m-1" *ngFor="let item of subscriptionItem">
        <!-- Plan -->
      <div class="" *ngIf="item.type==0">Trial</div>
      <div class="" *ngIf="item.type==1">Silver</div>
      <div class="" *ngIf="item.type==2">Gold</div>
      <div class="" *ngIf="item.type==3">Diamond</div>
      <!-- Number of Customers -->
      <div>{{item.numberOfCustomers}}</div>
      <!-- subscription Cost / customer -->
      <div>{{item.customerSubscriptionRate}}</div>
      <!-- base Value - Dashboard Cost -->
      <div>{{item.baseValue}}</div>
      <!-- Gst % -->
      <div>{{item.gstPercent}}%</div>
      <!-- Gst Value - subscription cost * Gst% -->
      <div>{{(item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100}}</div>
      <!-- description -->
      <div>{{item.description}}</div>
      <!-- subscriptionCost -->
      <div>{{item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )}}</div>
      <!-- Discount - 12Months -->
      <div>{{item.discountyear}}%</div>
       <!-- Discount - 24Months -->
       <div>{{item.discountyears}}%</div>
      <!-- totalCost -->
      <div>{{((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+(item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate ))}}</div>
       <!-- Promotional Value -->
      <div>{{item.promotionalValue}}</div>

      <div class="flex col-span-12 gap-2">
        <button class="btn ml-auto" (click)="edit(item)">Edit</button>
        <button class="btnnobg bg-red-400" (click)="delete(item)">Delete</button>
      </div>
    </div>

    </div>
  </div>
