export enum BusinessType {
  proprietor,
  partnership,
  company,
  society,
  trust,
}

export enum TicketStatus{
  new = 0,
  open = 1,
  closed = 2,
  deferred = 3
}
