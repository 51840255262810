import { Component } from '@angular/core';
import Toast from 'awesome-toast-component';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent {
  public tickets;


  constructor(private fs:FirebaseService){}

  ngOnInit(){
    return this.fs.getAllTickets().subscribe(res=>{
      this.tickets=res
    })
  }

  updateOpen(ticket:any){
    this.fs.updateTicketOpen(ticket);
    new Toast(`Ticket Opened` , {
      position: 'top'});
  }
  updatePending(ticket:any){
     this.fs.updateTicketpending(ticket)
  }
  updateClosed(ticket:any){
     if(confirm('Close Customer Ticket?')){
      this.fs.updateTicketClose(ticket)
      new Toast(`Ticket Closed` , {
        position: 'top'});
     }
  }
  updateDeferred(ticket:any){
     this.fs.updateTicketDeferred(ticket)
     new Toast(`Ticket added to Deferred` , {
      position: 'top'});
  }
}
