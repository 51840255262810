import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {

  showSignUp = false;
  constructor(private auth: AuthService, private router: Router, public afAuth: AngularFireAuth) {
    this.auth.isLoggedIn()
      .then((val: any) => {
        if (val == undefined || val == null) {
        }
        else {
          this.router.navigate(['/home']);
        }
      }
      )
  }

}
