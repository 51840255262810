import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Toast from 'awesome-toast-component';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent {

  formvalue: FormGroup;

  constructor(
    private fs: FirebaseService,
    private router: Router, private formbuilder : FormBuilder,private authService:AuthService
  ) { }


  ngOnInit(): void {

    this.formvalue=new FormGroup({

      aadharCardNumber  : new FormControl(),
      address           : new FormControl(),
      dateofBirth       : new FormControl(),
      email             : new FormControl(),
      loginId           : new FormControl(),
      loginPwd          : new FormControl(),
      name              : new FormControl(),
      newUser           : new FormControl(),
      panCardNumber     : new FormControl(),
      phoneNumber       : new FormControl(),
      socialPhotoUrl    : new FormControl(),
       detail            : new FormControl(),

       userID            : new FormControl(),
       verified          : new FormControl()
    })
}

newUser(){
  const customerData={
    name             : this.formvalue.value.name,
    email            : this.formvalue.value.email,
    loginId          : this.formvalue.value.loginId,
    loginPwd         : this.formvalue.value.loginPwd,
    aadharCardNumber : this.formvalue.value.aadharCardNumber,
    panCardNumber    : this.formvalue.value.panCardNumber,
    phoneNumber      : this.formvalue.value.phoneNumber,
    address          : this.formvalue.value.address,
    dateofBirth      : this.formvalue.value.dateofBirth,
    socialPhotoUrl   : this.formvalue.value.socialPhotoUrl,

    newUser : true,
    verified : false,
    detail:true,
    userID:this.fs.currentUserId
  }

  this.fs.createNewCustomerDetails(customerData);
  new Toast(`New Customer Added Successfully..!!!`, {
    position: 'top'});
  this.formvalue.reset();
  this.router.navigate(['/customers']);
}

cancel(){
  this.formvalue.reset();
  this.router.navigate(['/customers']);

}
}
