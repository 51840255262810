<div class="section md:m-10 md:p-10 grid grid-cols-1 md:grid-cols-2 gap-4 m-6 p-6 rounded shadow ">

  <div class="bg-gray-100 rounded shadow mx-auto">
    <div class="text-2xl font-sans font-semibold m-3 p-3">Raise Support Ticket</div>
    <form  [formGroup]="formvalue" class="m-10 w-fit">
      <input type="text" formControlName="title" placeholder="Title" class=" p-2 flex mx-auto rounded shadow">
      <textarea class=" h-24 mt-2 p-2 rounded shadow flex mx-auto" formControlName="textTicket" placeholder="Description"></textarea>
      <button class="btn flex mx-auto m-2" (click)="addTicket()">
        Raise Ticket
      </button>
    </form>

  </div>
  <div class="flex mx-auto">
    <img class="" src="https://ugmsupport.powerappsportals.com/NewTicket.jpg" width="300px">
  </div>
</div>


<div class="m-6 p-6 rounded shadow">
  <div class="text-2xl font-semibold">
  Previous Tickets</div>
  <div class="font-medium m-2 p-2 hover:bg-gray-50  hover:font-medium" *ngFor="let ticket of supportTicket" >
    <div class="m-2 p-2">Name : {{ticket.name}}
    </div>
    <div class="m-2 p-2">Title : {{ticket.title}}</div>
    <div class="m-2 p-2">Ticket Raised : {{ticket.textTicket}}</div>
    <div class="flex mx-auto">
      <h6 class="m-2 p-2">
        <i class="text-blue-500 p-2 list-disc"
        [ngClass]="ticket.status==0? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
        Pending
      </h6>
      <h6 class="m-2  p-2">
        <i class="text-blue-500 p-2 list-disc"
          [ngClass]="ticket.status==1? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
        Opened
      </h6>
      <h6 class="m-2 p-2">
        <i class="text-blue-500 p-2 list-disc"
        [ngClass]="ticket.status==2? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
        Closed
      </h6>
    </div>
    <small>{{ticket.date.toDate() | date:'dd/MM/yyy'}}</small>
  </div>
</div>


