import { BusinessDetailComponent } from './business-detail/business-detail.component';
import { BusinessesComponent } from './businesses/businesses.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customers/customers.component';
import { GstRequestsComponent } from './gst-requests/gst-requests.component';
import { HomeComponent } from './home/home.component';
import { ItRequestsComponent } from './it-requests/it-requests.component';
import { ItRequestDetailComponent } from './it-request-detail/it-request-detail.component';
import { GstRequestDetailComponent } from './gst-request-detail/gst-request-detail.component';
import { ContactRequestsComponent } from './contact-requests/contact-requests.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
export {
  BusinessDetailComponent,
  BusinessesComponent,
  CustomerDetailComponent,
  CustomersComponent,
  GstRequestsComponent,
  HomeComponent,
  ItRequestsComponent,
  ItRequestDetailComponent,
  GstRequestDetailComponent,
  ContactRequestsComponent,
  AppointmentsComponent,
  UserRegistrationComponent,
};
