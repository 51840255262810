<div class="section md:m-10 md:p-10 mt-2" *ngIf="business">
  <div class="text-2xl p-4 shadow bg-white rounded mt-5">
    <div class="col mx-auto tp-Center mt-2 mb-2">
      <h2>BUSINESS DETAIL : {{ business.name }}</h2>
    </div>
  </div>
  <div class="row mt-2">
    <button class="btn btn-taxPrism-outline" (click)="back()">
      BACK
    </button>
  </div>
  <div class="mt-2 bg-white">
    <div class="p-4 text-center font-bold">
      <span class="text-green-400" *ngIf="business.isActive">ACTIVE BUSINESS</span>
      <span class="" *ngIf="!business.isActive">INACTIVE BUSINESS</span>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 p-4 text-center">
      <h6 class="">Business Type: {{ business.businessType | businessType }}</h6>
      <h6 class="">Business Name: {{ business.name }}</h6>
      <h6 class="">Email Address: {{ business.emailAddress }} </h6>
      <h6 class="">PAN Card Number: {{ business.panCardNumber }}</h6>
      <h6 class="">IT Portal Login: {{ business.loginId }}</h6>
      <h6 class="">IT Portal Password: {{ business.loginPwd }}</h6>
    </div>
  </div>
  <div class="bg-white mt-2 shadow p-4">
    <div class="text-2xl font-bold">Filed IT Requests</div>


    <div class="bg-gray-300 shadow mt-2 grid grid-cols-1 md:grid-cols-5 gap-2 p-4 uppercase text-lg">
      <div class="mx-auto">Year of Assesment</div>
      <div class="mx-auto">Filing Request Type</div>
      <div class="mx-auto">Name of Entity</div>
      <div class="mx-auto">STATUS</div>
      <div class="mx-auto"></div>
    </div>
    <div class="bg-white shadow mt-2 p-4" *ngFor="let request of itRequests">
      <div class="bg-white mt-2 grid grid-cols-1 md:grid-cols-5 gap-2 ">
        <div class="mx-auto">
          <p> {{ request.assesmentYear }}</p>
        </div>

        <div class="mx-auto">
          Filing Request Type: {{ request.itRequestType | iTRequestType }}
        </div>
        <div class="mx-auto">
          <p>{{ request.name }}</p>
        </div>
        <div class="mx-auto uppercase">
          <span *ngIf="request.status === 'New Request'">NEW REQUEST</span>
          {{request.status}}
        </div>
        <div class="mx-auto">
          <button class="btn btn-taxPrism-outline" (click)="viewIncomeTaxFilingRequest(request)">
            DETAILS
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white mt-2 shadow p-4">
    <div class="text-2xl font-bold">Filed GST Requests</div>

    <div class="bg-white shadow mt-2 p-4 rounded grid grid-cols-1 md:grid-cols-4 font-semibold text-lg ">
      <div class="mx-auto">Month</div>
      <div class="mx-auto">NAME</div>
      <div class="mx-auto">GST Type</div>
      <div class="mx-auto"></div>
    </div>
    <div class="bg-white shadow mt-2 p-4 rounded grid grid-cols-1 md:grid-cols-4" *ngFor="let request of gstRequests">
      <p class="card-text mx-auto">
        {{ request.month }}
        {{ request.timestamp.toDate() | date: "yyyy" }}
      </p>
      <div class="mx-auto">
        {{ request.name }}
      </div>
      <div class="mx-auto">
        GST Type: {{ request.gstType | gstRequestType }}
      </div>
      <div class="mx-auto">
        <button class="btn btn-taxPrism-outline" (click)="viewGstRequest(request)">
          DETAILS
        </button>
      </div>
    </div>
  </div>
</div>
<!-- TODO: Filed IT Requests and GST Requests -->
