import { Pipe, PipeTransform } from '@angular/core';


enum OtherServicesType {
  None,
  PAN_allotment,
  Tan_allotment,
  Agreements, //Rental, Partnership deed, Trust Deed, Sales Deed , Settlement Deed, Will, Power of Attorney, other contracts
  Company_formation, //partnership, private limited company, one person company, limited liability company
  Trust_formation,
  GST_registration,
  TDS_returns_filing,
  Appeal_filing,
  Trademark,
  Patent,
  Copyright,
  Accounting_Booking,
  Legal_advice,
  Bank_loan_funding,
  ROC_compliance,
  Shop_Establishment_registration,
  MSME,
  Import_Export_code,
  Professional_Tax_registration,
  Property_registration,
}


@Pipe({
  name: 'otherServices'
})
export class OtherServicesPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    return OtherServicesType[value].toUpperCase();
  }

}
