import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import packageInfo from "../../../../../package.json";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public appVersion: string = '';
  date=new Date();

  disclaimerPopup:boolean=false;
  isAgree:boolean

  constructor(public afAuth: AngularFireAuth, private router: Router, public authService: AuthService) { }

  ngOnInit() {
    this.appVersion = packageInfo.version;
  }

}
