import { Pipe, PipeTransform } from '@angular/core';

enum BusinessType {
  proprietor,
  partnership,
  company,
  society,
  trust,
}

@Pipe({
  name: 'businessType',
})
export class BusinessTypePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): String {
    return BusinessType[value].toUpperCase();
  }
}
