<div class="bg-gray-100 pt-2">

  <div class="text-3xl text-center m-5 p-3">
Subscription Plans & Pricing
  </div>

  <div class="m-2 p-2 text-center">
    CA Users who sign up before <b>December 31<sup>st</sup> 2023</b> can subscribe at
     <b>Silver/ Gold/Diamond promotional costs per month for next 1 year (Early Access rate)</b>
     <br>
    New users who sign up after promo date will be charged Silver/Gold/Diamond regular cost per month.
    <p class="p-2">*Regular Price Includes GST of 18%</p>
  </div>
  <div class="flex">
    <div class="inline-flex mx-auto bg-gray-200 rounded-t " >

    <div  (click)="mons()">
      <div class=" px-8 py-4 mb-0 font-semibold hover:bg-white rounded-t" *ngIf="!year">
        12 Months
      </div>
      <div class=" px-8 py-4 mb-0 font-semibold bg-white rounded-t" *ngIf="year">
        12 Months
      </div>
    </div>
    <div (click)="monss()">
      <div  class="mb-0 px-8 py-4 hover:bg-white rounded-t  font-semibold " *ngIf="!twoYears">
        24 Months
      </div>
      <div  class="mb-0 px-8 py-4 bg-white rounded-t  font-semibold " *ngIf="twoYears">
        24 Months
      </div>

    </div>
    <div  (click)="mon()">
     <div class=" px-8 py-4 mb-0 font-semibold hover:bg-white rounded-t " *ngIf="!month">
      1 Month
     </div>
     <div class=" px-8 py-4 mb-0 font-semibold bg-white rounded-t " *ngIf="month">
      1 Month
     </div>
    </div>
    </div>
  </div>
</div>

<div class="ml-10 mr-10 bg-gray-100 text-center mt-4 rounded-lg p-2" >
  The 24-month plan has the best value.
</div>

<div class="sectionMobile md:m-10 md:mt-3 md:p-10">
<div class="lg:flex grid" *ngIf="month">
  <div class=" mx-auto text-center" *ngFor="let item of subscriptionItem">
    <!-- Silver - 1 month -->
    <div class="" *ngIf="item.type==1">
    <div class="border  hover:shadow-md hover:shadow-gray-300 border-black m-2 p-2 h-full w-96  rounded-md" >
    <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
      Silver subscription
    </div>
    <div class="p-2 text-center">
      <!-- totalCost -->
      <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
        {{((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
          (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) | number}}

        <sup class="font-light"> per month</sup></p>
        <p class="p-2"> *GST Included</p>
        <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>

      <div class="p-2"><i class="text-lg">
        <!-- totalCost -->
        <sup class="font-light">INR </sup>
        <del>
    {{((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
      (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) | number}}
      </del>
      <sup class="font-light"> per month</sup>
      <!-- Promotional -->
      <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
        {{subscriptionSilverPromotional | number}}
        <sup class="font-light"> per month</sup></p>
      </i>
      <p class="">(Promotional Price)</p>
</div>
    </div>
      <div class="mt-4 mb-4 bottom-0 text-center">
        <button class="subscriptionBtn" >
          <!-- (click)="getSilver()" -->
          Subscribe
        </button>
      </div>
    </div>
    </div>
    <div class="" *ngIf="item.type==3">
      <div class="border hover:shadow-md hover:shadow-gray-300 border-black m-2 p-2 h-full w-96 rounded-md">
        <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
          Diamond Subscription</div>

        <div class="p-2 text-center">
      <p class="text-center mt-4 text-3xl font-bold">
        <sup class="font-light">INR </sup>
        <!-- totalCost -->
    {{((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
      (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) | number}}
        <sup class="font-light"> per month</sup>
      </p>

     <p class="p-2"> *GST Included</p>

      <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>
    </div>
    <div class="p-2"><i class="text-lg">
      <!-- totalCost -->
      <sup class="font-light">INR </sup>
      <del>
  {{((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
    (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) | number}}
    </del>
    <sup class="font-light"> per month</sup>
    <!-- Promotional -->
    <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
      {{subscriptionDiamondPromotional | number}}
      <sup class="font-light"> per month</sup></p>
    </i>
    <p class="">(Promotional Price)</p>
</div>

      <div class="mt-4 mb-4 bottom-0 text-center">
        <button class="subscriptionBtn">
          <!--  (click)="getDiamond()" -->
          Subscribe
        </button>
      </div>
    </div>
    </div>
    <div class="" *ngIf="item.type==2">
    <div class="border border-black m-2 p-2 hover:shadow-md hover:shadow-gray-300 rounded-md h-full w-96">
      <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
        Gold Subscription</div>

        <div class="p-2 text-center">
          <p class="text-center mt-4 text-3xl font-bold">
            <sup class="font-light">INR </sup>
            <!-- totalCost -->
        {{((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
          (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) | number}}
            <sup class="font-light"> per month</sup>
          </p>

         <p class="p-2"> *GST Included</p>

          <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>
        </div>
        <div class="p-2"><i class="text-lg">
          <!-- totalCost -->
          <sup class="font-light">INR </sup>
          <del>
      {{((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
        (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) | number}}
        </del>
        <sup class="font-light"> per month</sup>
        <!-- Promotional -->
        <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
          {{subscriptionGoldPromotional | number}}
          <sup class="font-light"> per month</sup></p>
        </i>
        <p class="">(Promotional Price)</p>
    </div>

        <div class="mt-4 mb-4 bottom-0 text-center">
          <button class="subscriptionBtn" >
            <!-- (click)="getGold()" -->
           Subscribe
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="lg:flex grid" *ngIf="year">
  <div class=" mx-auto text-center" *ngFor="let item of subscriptionItem">
    <div class="" *ngIf="item.type==1">
        <div class="border  hover:shadow-md hover:shadow-gray-300 border-black m-2 p-2 h-full w-96 rounded-md">
        <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
          Silver subscription
        </div>
        <div class="p-2 text-center">
          <!-- totalCost before discount-->
          <p class="p-2"><i>
            <sup class="font-light">INR </sup>
            <del>{{silverOneYear | number}}</del>
          <!-- <sup class="font-light"> per 12 month</sup> -->
      </i></p>
          <!-- totalCost after discount-->
          <p class="text-center mt-4 text-3xl font-bold">
            <sup class="font-light">INR </sup>
          {{subscriptionSilverOneYear  | number}}
            <!-- <sup class="font-light"> per 12 month</sup> -->
          </p>

         <p class="p-2"> *GST Included</p>

          <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>

          <p class="">Discount of {{item.discountyear}}%</p>
          <!-- You save -->
          <p class="p-2">You Save - <i class="text-blue-400">INR
            {{silverSavingOneYear  | number}}
            </i></p>
        </div>
        <div class="p-2"><i class="text-lg">
          <!-- totalCost after discount-->
          <sup class="font-light">INR </sup>
          <del>
            {{subscriptionSilverOneYear | number}}
        </del>
        <!-- <sup class="font-light"> per 12 month</sup> -->
        <!-- Promotional -->
        <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
          {{subscriptionSilveroneYearPromotional | number}}
          <!-- <sup class="font-light"> per 12 month</sup></p> -->
        </i>
        <p class="">(Promotional Price)</p>
    </div>
          <div class="mt-4 mb-4 bottom-0 text-center">
            <button class="subscriptionBtn" >
              <!-- (click)="getSilveroneYear()" -->
              Subscribe
            </button>
          </div>
        </div>
      </div>
    <div class="" *ngIf="item.type==3">
          <div class="border hover:shadow-md hover:shadow-gray-300 border-black m-2 p-2 h-full w-96 rounded-md">
            <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
              Diamond Subscription</div>

              <div class="p-2 text-center">

                <!-- totalCost before discount-->
                <p class="p-2"><i>
                  <sup class="font-light">INR </sup>
                  <del>
              {{ diamondOneYear  | number}}</del>
                <!-- <sup class="font-light"> per 12 month</sup> -->
            </i></p>
                <!-- totalCost after discount-->
                <p class="text-center mt-4 text-3xl font-bold">
                  <sup class="font-light">INR </sup>
                  {{subscriptionDiamondOneYear  | number}}
                  <!-- <sup class="font-light"> per 12 month</sup> -->
                </p>

               <p class="p-2"> *GST Included</p>

                <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>

                <p class="">Discount of {{item.discountyear | number}}%</p>
                <!-- You save -->
                <p class="p-2">You Save - <i class="text-blue-400">INR
                  {{diamondSavingOneYear | number}}
                  </i></p>
              </div>
              <div class="p-2"><i class="text-lg">
                <!-- totalCost after discount-->
                <sup class="font-light">INR </sup>
                <del>
                  {{subscriptionDiamondOneYear  | number}}
              </del>
              <!-- <sup class="font-light"> per 12 month</sup> -->
              <!-- Promotional -->
              <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
                {{subscriptionDiamondoneYearPromotional | number}}
                <!-- <sup class="font-light"> per 12 month</sup></p> -->
              </i>
              <p class="">(Promotional Price)</p>
          </div>

          <div class="mt-4 mb-4 bottom-0 text-center">
            <button class="subscriptionBtn" >
              <!-- (click)="getDiamondoneYear()" -->
             Subscribe
            </button>
          </div>
        </div>
      </div>
    <div class="" *ngIf="item.type==2">
        <div class="border border-black m-2 p-2 hover:shadow-md hover:shadow-gray-300 rounded-md h-full w-96">
          <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
            Gold Subscription</div>

            <div class="p-2 text-center">

              <!-- totalCost before discount-->
              <p class="p-2"><i>
                <sup class="font-light">INR </sup>
                <del>
            {{goldOneYear | number}}</del>
              <!-- <sup class="font-light"> per 12 month</sup> -->
          </i></p>
              <!-- totalCost after discount-->
              <p class="text-center mt-4 text-3xl font-bold">
                <sup class="font-light">INR </sup>
                {{subscriptionGoldOneYear  | number}}
                <!-- <sup class="font-light"> per 12 month</sup> -->
              </p>

             <p class="p-2"> *GST Included</p>

              <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>

              <p class="">Discount of {{item.discountyear | number}}%</p>
              <!-- You save -->
              <p class="p-2">You Save - <i class="text-blue-400">INR
                {{goldSavingOneYear | number}}
                </i></p>
            </div>
            <div class="p-2"><i class="text-lg">
              <!-- totalCost after discount-->
              <sup class="font-light">INR </sup>
              <del>
                {{subscriptionGoldOneYear | number}}
            </del>
            <!-- <sup class="font-light"> per 12 month</sup> -->
            <!-- Promotional -->
            <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
              {{subscriptionGoldoneYearPromotional | number}}
              <!-- <sup class="font-light"> per 12 month</sup></p> -->
            </i>
            <p class="">(Promotional Price)</p>
        </div>

            <div class="mt-4 mb-4 bottom-0 text-center">
              <button class="subscriptionBtn" >
                <!-- (click)="getGoldoneYear()" -->
                Subscribe
              </button>
            </div>
          </div>
      </div>
  </div>
</div>

<div class="lg:flex grid" *ngIf="twoYears">
  <div class=" mx-auto text-center" *ngFor="let item of subscriptionItem">
    <div class="" *ngIf="item.type==1">
            <div class="border  hover:shadow-md hover:shadow-gray-300 border-black m-2 p-2 rounded-md h-full w-96">
            <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
              Silver subscription
            </div>

            <div class="p-2 text-center">
              <!-- totalCost before discount-->
              <p class="p-2"><i>
                <sup class="font-light">INR </sup>
                <del>
            {{silverTwoYears| number}}</del>
              <!-- <sup class="font-light"> per 24 month</sup> -->
          </i></p>
              <!-- totalCost after discount-->
              <p class="text-center mt-4 text-3xl font-bold">
                <sup class="font-light">INR </sup>
              {{subscriptionSilverTwoYears | number}}
                <!-- <sup class="font-light"> per 24 month</sup> -->
              </p>

             <p class="p-2"> *GST Included</p>

              <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>

              <p class="">Discount of {{item.discountyears | number}}%</p>
              <!-- You save -->
              <p class="p-2">You Save - <i class="text-blue-400">INR
                {{silverSavingTwoYear  | number}}
                </i></p>
            </div>
            <div class="p-2"><i class="text-lg">
              <!-- totalCost after discount-->
              <sup class="font-light">INR </sup>
              <del>
                {{subscriptionSilverTwoYears | number}}
            </del>
            <!-- <sup class="font-light"> per 24 month</sup> -->
            <!-- Promotional -->
            <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
              {{subscriptionSilverfortwoYearsPromotional | number}}
              <!-- <sup class="font-light"> per 24 month</sup></p> -->
            </i>
            <p class="">(Promotional Price)</p>
        </div>

              <div class="mt-4 mb-4 bottom-0 text-center">
                <button class="subscriptionBtn">
                  <!-- (click)="getSilverfortwoYears()" -->
                  Subscribe
                </button>
              </div>
            </div>
      </div>
    <div class="" *ngIf="item.type==3">
              <div class="border hover:shadow-md hover:shadow-gray-300 border-black m-2 p-2 rounded-md h-full w-96">
                <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
                  Diamond Subscription</div>

                  <div class="p-2 text-center">
                    <!-- totalCost before discount-->
                    <p class="p-2"><i>
                      <sup class="font-light">INR </sup>
                      <del>
                  {{diamondTwoYears | number}}</del>
                    <!-- <sup class="font-light"> per 24 month</sup> -->
                </i></p>
                    <!-- totalCost after discount-->
                    <p class="text-center mt-4 text-3xl font-bold">
                      <sup class="font-light">INR </sup>
                      {{subscriptionDiamondTwoYears | number}}
                      <!-- <sup class="font-light"> per 24 month</sup> -->
                    </p>

                   <p class="p-2"> *GST Included</p>

                    <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>

                    <p class="">Discount of {{item.discountyears | number}}%</p>
                    <!-- You save -->
                    <p class="p-2">You Save - <i class="text-blue-400">INR
                      {{diamondSavingTwoYear | number}}
                      </i></p>
                  </div>
                  <div class="p-2"><i class="text-lg">
                    <!-- totalCost after discount-->
                    <sup class="font-light">INR </sup>
                    <del>
                      {{subscriptionDiamondTwoYears | number}}
                  </del>
                  <!-- <sup class="font-light"> per 24 month</sup> -->
                  <!-- Promotional -->
                  <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
                    {{subscriptionDiamondfortwoYearsPromotional | number}}
                    <!-- <sup class="font-light">  24 month</sup></p> -->
                  </i>
                  <p class="">(Promotional Price)</p>

              </div>

              <div class="mt-4 mb-4 bottom-0 text-center">
                <button class="subscriptionBtn" >
                  <!-- (click)="getDiamondfortwoYears()" -->
                  Subscribe
                </button>
              </div>
            </div>
      </div>
    <div class="" *ngIf="item.type==2">
            <div class="border border-black m-2 p-2   hover:shadow-md hover:shadow-gray-300 rounded-md h-full w-96">
              <div class="font-semibold text-xl p-2 m-2 text-center border-b-2 border-black uppercase text-blue-400">
                Gold Subscription</div>

                <div class="p-2 text-center">
                  <!-- totalCost before discount-->
                  <p class="p-2"><i>
                    <sup class="font-light">INR </sup>
                    <del>
                {{goldTwoYears | number}}</del>
                  <!-- <sup class="font-light"> per 24 month</sup> -->
              </i></p>
                  <!-- totalCost after discount-->
                  <p class="text-center mt-4 text-3xl font-bold">
                    <sup class="font-light">INR </sup>
                    {{subscriptionGoldTwoYears | number}}
                    <!-- <sup class="font-light"> per 24 month</sup> -->
                  </p>

                 <p class="p-2"> *GST Included</p>

                  <p class="p-2"> Add upto {{item.numberOfCustomers | number}} customers</p>

                  <p class="">Discount of {{item.discountyears | number}}%</p>
                  <!-- You save -->
                  <p class="p-2">You Save - <i class="text-blue-400">INR
                    {{goldSavingTwoYear | number}}
                    </i></p>
                </div>
                <div class="p-2"><i class="text-lg">
                  <!-- totalCost after discount-->
                  <sup class="font-light">INR </sup>
                  <del>
                    {{subscriptionGoldTwoYears | number}}
                          </del>
                <!-- <sup class="font-light"> per 24 month</sup> -->
                <!-- Promotional -->
                <p class=" mt-4 text-3xl font-bold" ><sup class="font-light">INR </sup>
                  {{subscriptionGoldfortwoYearsPromotional | number }}
                  <!-- <sup class="font-light"> per 24 month</sup></p> -->
                </i>
                <p class="">(Promotional Price)</p>
            </div>

                <div class="mt-4 mb-4 bottom-0 text-center">
                  <button class="subscriptionBtn">
                    <!--  (click)="getGoldfortwoYears()" -->
                    subscribe
                  </button>
                </div>
              </div>
      </div>
  </div>
</div>

</div>
