  <div class="bg-blue-500 inline-flex w-full" *ngIf="!currentUser">
          <img src="/assets/images/logo_horizontal.png" alt="" class="m-3 rounded w-40" srcset="" />
           <button class="bg-white m-4 p-2 w-fit h-fit text-black shadow rounded ml-auto" routerLink="login">Login</button>
  </div>

<nav class="bg-blue-500 lg:p-6 hidden lg:block p-3 md:w-full" *ngIf="afAuth.authState | async as user">
  <div class="flex justify-between  text-white">
    <div class="navbar-brand w-2/5" (click)="goto('home')" routerLinkActive="active">
      <img  src="../../../assets/images/logo_horizontal.png" width="200px">
    </div>

    <div class="flex justify-between align-middle md:text-sm">
      <div class="flex justify-between align-middle pt-4">


<div *ngIf="currentUser">
        <div *ngIf="currentUser.ifAgree==true">
          <div *ngIf="currentUser.isAdmin" class="md:flex md:w-fit md:h-fit text-xs md:text-base ">
          <button routerLink="users" class="navLink w-fit ml-2" >USERS</button>
          <button routerLink="tickets" class="navLink w-fit m-2" >Support Tickets</button>
          <button routerLink="subscriptionPlans" class="navLink w-fit m-2"  *ngIf="currentUser.isSuperAdmin">Subscription Plans</button>
          <button routerLink="activitylogs" class="navLink w-fit m-2"  *ngIf="currentUser.isSuperAdmin">Activity Logs</button>
          <button class="bg-red-500 hover:bg-red-600 shadow rounded text-white p-2 m-2"
           (click)="logout()">LOGOUT</button>
          </div>

          <div *ngIf="!currentUser.isAdmin" class="lg:flex md:h-fit justify-between text-sm">
        <button class="navLink m-2" (click)="goto('customers')" routerLinkActive="active">CUSTOMERS</button>
        <button class="navLink m-2" (click)="goto('businesses')" routerLinkActive="active">BUSINESSES</button>
        <button class="navLink m-2" (click)="goto('itRequests')" routerLinkActive="active">IT REQUESTS</button>
        <button class="navLink m-2" (click)="goto('gstRequests')" routerLinkActive="active">GST REQUESTS</button>
        <!-- <button class="navLink m-2" (click)="goto('contactRequests')" routerLinkActive="active">CONTACT REQUESTS</button> -->
        <button class="navLink m-2" (click)="goto('appointments')" routerLinkActive="active">APPOINTMENTS</button>
        <button  class="navLink m-2" (click)="goto('myaccount')" routerLinkActive="active">MY ACCOUNT</button>
        <button class="bg-red-500 hover:bg-red-600 shadow rounded text-white p-2 m-2"
        (click)="logout()">LOGOUT</button>
          </div>
        </div>
        <div *ngIf="currentUser.ifAgree==false">
          <button class="bg-red-500 hover:bg-red-600 shadow rounded text-white p-2 m-2 inline-block text-sm px-4 py-2 leading-none border border-white hover:border-transparent mt-2"
          (click)="logout()">LOGOUT</button>
        </div>
      </div>
      </div>
    </div>
  </div>
</nav>




<nav class="bg-blue-500 lg:p-6 p-3 lg:hidden md:w-full" *ngIf="afAuth.authState | async as user">
  <div class="flex justify-between text-white  mr-6">
    <a class="navbar-brand" (click)="goto('home')" routerLinkActive="active">
      <img src="../../../assets/images/logo_horizontal.png" width="200" alt="" />
    </a>

  <div class="m-2 ml-auto lg:hidden">
    <button type="button" (click)="toggleMobileMenu()"
      class="rounded p-2 inline-flex -mr-8 text-white ">
      <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </button>
  </div>
  <div class="fixed z-30 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden" *ngIf="mobileMenuOpen">
    <div class="rounded-lg shadow-lg ring-1 bg-white divide-y-2 divide-gray-50">

      <div class="flex ">
        <div class="flex bg-blue-500 p-2 m-2 w-full rounded">
          <a class="navbar-brand " routerLink="/home">
      <img src="../../../assets/images/logo_horizontal.png" width="200" alt="" />
    </a>
        <button (click)="toggleMobileMenu()" type="button"
          class=" ml-auto rounded-md p-2 inline-flex items-center justify-center text-white">
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      </div>
      <div class="py-6 px-5">
        <div class="text-black font-medium">

                <div *ngIf="currentUser">
                  <div *ngIf="currentUser.isAdmin" class="grid grid-cols-1 mx-auto w-fit md:h-fit md:text-base p-2 font-medium">
                  <button routerLink="users" class="navLink m-2" >USERS</button>
                  <button routerLink="tickets" class="navLink m-2" >Support Tickets</button>
                  <button routerLink="subscriptionPlans" class="navLink m-2" *ngIf="currentUser.isSuperAdmin">Subscription Plans</button>
                  <button routerLink="activitylogs" class="navLink m-2" *ngIf="currentUser.isSuperAdmin">Activity Logs</button>
                  <button class="bg-red-500 hover:bg-red-600 shadow rounded text-white p-2 m-2" (click)="logout()">LOGOUT</button>
                  </div>

                  <div *ngIf="!currentUser.isAdmin" class="grid grid-cols-1 mx-auto w-fit md:h-fit font-medium md:text-base">
                <button class="navLink m-2" (click)="goto('customers')" routerLinkActive="active">CUSTOMERS</button>
                <button class="navLink m-2" (click)="goto('businesses')" routerLinkActive="active">BUSINESSES</button>
                <button class="navLink m-2" (click)="goto('itRequests')" routerLinkActive="active">IT REQUESTS</button>
                <button class="navLink m-2" (click)="goto('gstRequests')" routerLinkActive="active">GST REQUESTS</button>
                <!-- <button class="navLink m-2" (click)="goto('contactRequests')" routerLinkActive="active">CONTACT REQUESTS</button> -->
                <button class="navLink m-2" (click)="goto('appointments')" routerLinkActive="active">APPOINTMENTS</button>
                <button  class="navLink m-2" (click)="goto('myaccount')" routerLinkActive="active">MY ACCOUNT</button>
                <button class="bg-red-500 hover:bg-red-600 shadow rounded text-white p-2 m-2" (click)="logout()">LOGOUT</button>
                  </div>
                </div>
                </div>
                </div>
              </div>
          </div>
    </div>
</nav>
