import { Component } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.css']
})
export class ActivityLogsComponent {

  q=1;
  logs:any;
  loading:boolean;
  startDate:any;
  endDate:any;
  users:any;

  showSearch:boolean;
  currentDate=new Date();
  searchKeyword = "name";
  selectedUser:any;
  maxDate=moment(new Date()).format('YYYY-MM-DD');

  disableButton:boolean=true;
  customer: any;

  constructor(private fs : FirebaseService,private logService:LogService){}

  ngOnInit(){
    this.fs.getUsers().subscribe(res=>{
      this.users=res;

      this.logDataCollection()
  })
  }
  logDataCollection(){
    this.logService.getAllLogs(this.currentDate).then(res=>{
      this.logs=res;
      // console.log(res)
    })
  }
  selectEvent(item: any) {
    this.selectedUser = item;
  }

  logSearch(){
    this.loading=true;
    this.disableButton=false;
    setTimeout(()=>{
      return this.logService.getLogsbyDateRange(this.startDate,this.endDate).then(res=>{
        if(this.startDate<=this.endDate){
          this.logs=res;
          this.loading=false;
          this.showSearch=true;
          this.disableButton=true;
      }else {
       alert("Invalid Date")
      }})
    },1000)
  }

  logSearchByUser(){
    this.loading=true;
    this.disableButton=false;
    return this.logService.getLogsbyUserDateRange(this.selectedUser.uid,this.startDate,this.endDate).then(res=>{
      this.logs=res;
      this.loading=false;
      this.disableButton=true;
    })
  }



  reset() {
    this.loading=true;
    this.showSearch=false;

      this.customer = null;
      this.startDate = null;
      this.endDate = null;
      this.logDataCollection();
      this.loading=false;

  }
}
