import { Component, OnInit } from '@angular/core';
import { FirebaseService, DataService } from 'src/app/services/services';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ETFUser } from 'src/app/models/user';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-it-requests',
  templateUrl: './it-requests.component.html',
  styleUrls: ['./it-requests.component.css']
})
export class ItRequestsComponent implements OnInit {
  itRequests = [];
  boundRequest = [];
  filteredRequests = [];
  customers = [];
  businesses = [];


  isViewAll = true;
  filterdefaultClass = 'btn btn-secondary';
  filterAllClass: string;
  filterBusinessClass: string;
  filterIndividualClass: string;
  filterText: string;
  active = ' active';

  customersfilteredReq = [];
  element: any;
  item: any;
  itemss: any;
  user: ETFUser;
  userId: any;
  customerDetails: any[];
  filtered: any;
  userCustomerItReq: any[];

  constructor(private fs: FirebaseService, private ds: DataService, private router: Router,private authService:AuthService) { }

  async ngOnInit() {

    this.customerItReq();

    // this.filterAllClass = this.filterdefaultClass;
    // this.filterBusinessClass = this.filterdefaultClass;
    // this.filterIndividualClass = this.filterdefaultClass;

    // await this.fs.getCustomers().subscribe(allCustomers =>{
    //   this.customers = allCustomers
    // });

    // this.fs.getAllItRequests(false).subscribe(async request => {
    //   request.forEach(
    //     req => {
    //       if (req.itRequestType === 0) {

    //         req.name = (this.customers.find(c => c.uid === req.userId).name);
    //       }
    //       else {
    //         req.name = (this.businesses.find(c => c.id === req.businessId).name);
    //       }
    //     }
    //   );
      // this.itRequests = request;
      // this.boundRequest = this.itRequests;
      // this.filteredRequests = this.boundRequest;
    // });

    this.filterAll();
    this.filterText = '';

  }

  filterAll() {
    this.filterAllClass = this.filterdefaultClass + this.active;
    this.filterBusinessClass = this.filterdefaultClass;
    this.filterIndividualClass = this.filterdefaultClass;
    // All items
    this.boundRequest = this.itRequests.filter(item => item);
    this.filteredRequests = this.boundRequest;
    this.isViewAll = true;
  }

  filterBusinesses() {
    this.filterAllClass = this.filterdefaultClass;
    this.filterBusinessClass = this.filterdefaultClass + this.active;
    this.filterIndividualClass = this.filterdefaultClass;
    // Enum type for ITRequest Type
    this.boundRequest = this.itRequests.filter(item => item.itRequestType === 1);
    this.filteredRequests = this.boundRequest;
    this.isViewAll = false;
  }

  filterIndividuals() {
    this.filterAllClass = this.filterdefaultClass;
    this.filterBusinessClass = this.filterdefaultClass;
    this.filterIndividualClass = this.filterdefaultClass + this.active;
    // Enum type for ITRequest Type
    this.boundRequest = this.itRequests.filter(item => item.itRequestType === 0);
    this.filteredRequests = this.boundRequest;
    this.isViewAll = false;
  }

  search() {
    this.filteredRequests = this.boundRequest.filter(item => item.name.toUpperCase().includes(this.filterText.toUpperCase()));
  }

  viewIncomeTaxFilingRequest(incometaxfiling: any) {
    this.router.navigate(['itRequestDetail', { id: incometaxfiling.requestID }]);
  }


  async customerItReq(){
    await this.fs.getBusinesses().subscribe(allBus => {
      this.businesses = allBus
    });
    this.filterAllClass = this.filterdefaultClass;
    this.filterBusinessClass = this.filterdefaultClass;
    this.filterIndividualClass = this.filterdefaultClass;

    this.authService.getUser().then(res=>{
      this.user = res as ETFUser;
      this.userId=this.user.uid;
      this.customerDetails=[]
      var userCustomerItReq=[]
  // gets customer details with current user
   firstValueFrom(this.fs.getAllCustomersDetails(this.userId)).then(data=>{
    this.customers=data;
  // looping and pushing the data to array
    for(let i=0;i<this.customers.length;i++){
      const UCD = this.customers[i]
      this.customerDetails.push(UCD.uid)
  // getting all It requests with customers uid
      firstValueFrom(this.fs.getAllItRequests(false)).then(res=>{
        // console.log(res)
  // looping thriugh the data
        for(let j=0;j<res.length;j++){
          const item=res[j]
  // comparing user customer's uid with Itrequest UserId
          if(UCD.uid==item.userId){
          // console.log(item)
          userCustomerItReq.push(item)
        }
      }
        // console.log(userCustomerItReq)
          userCustomerItReq.forEach(
                  req => {
                    // console.log(req)
                    if (req.itRequestType === 0) {
                      req.name = (this.customers.find(c => c.uid === req.userId).name);
                    }
                    else {
                      req.name = (this.businesses.find(c => c.id === req.businessId).name);
                    }
                  }
                );
      });
    };
    this.itRequests = userCustomerItReq;
                this.boundRequest = this.itRequests;
                this.filteredRequests = this.boundRequest;
            // console.log(this.itRequests)
    });
  });
}
  }

