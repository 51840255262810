<div class="sectionMobile md:m-10 md:p-10 mt-2">
  <div class=" mt-5">
    <div class="md:text-2xl shadow p-2 text-center w-full">
      <h2>USERS</h2>
    </div>
  </div>

        <div class="mt-2 w-100 lg:flex gap-2 btn-group btn-group-toggle" data-toggle="buttons">
          <div class=" w-100 justify-content-center shadow">
            <div class="flex gap-2 mb-auto">
              <div class="p-4">
                <span>Filter</span>
              </div>

              <div class="m-2">
                <input type="text" class="form-control p-2 rounded shadow" placeholder="Search by Name" aria-label="Search"
                aria-describedby="button-addon2" [(ngModel)]="searchText" />
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="search()">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </div>
                </div>
                <div class="grid lg:flex gap-2 m-2 justify-content-center">
          <label [ngClass]="filterAllUsers" class="lg:w-fit h-fit  w-full">
            <input type="radio" name="options" id="option1" checked (click)="filterAll()" />
            ALL
          </label>
          <label [ngClass]="filterSubscribers" class="lg:w-fit w-full h-fit">
            <input type="radio" name="options" id="option2" (click)="filterSubscribedUser()" />
            SUBSCRIBED USERS
          </label>
          <label [ngClass]="filterUnSubscribers" class="lg:w-fit w-full h-fit">
            <input type="radio" name="options" id="option3" (click)="filterUnSubscribedUser()" />
            UNSUBSCRIBED USERS
          </label>
          <label [ngClass]="filterVerified" class="lg:w-fit w-full h-fit">
            <input type="radio" name="options" id="option3" (click)="filterVerifiedUser()" />
            VERIFIED USERS
          </label>
          <label [ngClass]="filterunVerified" class="lg:w-fit w-full h-fit">
            <input type="radio" name="options" id="option3" (click)="filterunVerifiedUser()" />
            UNVERIFIED USERS
          </label>
        </div>
</div>


  <div>
  <div class="mt-2 bg-gray-300 p-4 shadow rounded w-full">
    <div class="grid lg:grid-cols-12 grid-cols-1 md:gap-4 font-medium  md:mx-auto text-center uppercase" >
      <div class="col-span-1">User Photo</div>
      <div class="col-span-2">NAME</div>
      <div class="col-span-2">EMAIL-ID</div>
      <div class="col-span-2">PHONE NUMBER</div>
      <div class="col-span-2">SUBSCRIPTION</div>
      <div class="col-span-2">VERIFICATION </div>
      <div class="col-span-1"></div>
    </div>
      </div>

      <div *ngIf="users" class="">
      <div  *ngFor="let user of filteredRequests">

        <div *ngIf="!user.isAdmin" class="grid lg:grid-cols-12 grid-cols-1 lg:gap-2 p-2 m-2 text-center shadow lg:mx-auto">
          <div class="mx-auto col-span-1"><img [src]="user.photoUrl" class="rounded-full"></div>
          <div class="font-semibold p-2 lg:mt-6 lg:mb-6 col-span-2">{{user.name}}</div>
          <div class="text-sm p-2 lg:mt-6 lg:mb-6 col-span-2">{{user.email}}</div>
          <div class="font-medium lg:mt-6 lg:mb-6 col-span-2">{{user.phoneNumber}}</div>

          <div class="lg:mt-6 lg:mb-6 col-span-2">
            <i class="text-blue-500 p-2 list-disc"
                [ngClass]="user.validSubscription? 'fa-solid fa-circle-check':'fa-regular fa-circle'">
            </i>
                Subscribed

          </div>
          <div class="lg:mt-6 lg:mb-6 col-span-2">
            <i class="text-blue-500 p-2 list-disc"
                [ngClass]="user.verified? 'fa-solid fa-circle-check':'fa-regular fa-circle'">
            </i>
                verified
          </div>
          <div class="lg:mt-6 lg:mb-6 inline-flex col-span-1 gap-2">
            <button class="bg-white shadow uppercase rounded px-3 py-2 text-blue-500 "
            (click)="router.navigate(['subscriptionDetails', {id: user.id}])">
            <i class="fa-solid fa-circle-info"></i></button>
            <button class="bg-white shadow uppercase rounded px-3 py-2 text-black "
            (click)="router.navigate(['userCustomer', {id: user.id}])">
            <i class="fa-solid fa-user"></i></button></div>
        </div>
        <div >

            </div>

        </div>
      </div>
    </div>
