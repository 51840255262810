<div class="sectionMobile md:m-10 md:p-10 mt-2">
  <div class=" mt-5">
    <div class="text-2xl shadow p-2 text-center w-full">
      <h2>CUSTOMERS</h2>
    </div>
  </div>
  <div class="mt-2 w-100 justify-content-center shadow">
    <div class="md:flex gap-2 mb-auto">
      <div class="flex ">
        <span class="p-4">Filter</span>
        <div class="mt-2">
          <input type="text" class="form-control p-2 rounded shadow" placeholder="Search" aria-label="Search"
          aria-describedby="button-addon2" [(ngModel)]="searchText" />
          <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="search()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>

      <div class="m-2 ml-auto">
        <button class="btn " type="button" (click)="showNewUserDialog=true" >
          <i class="fa-solid fa-user-plus"></i>
        </button>
        <button  class="btn ml-3" type="button" (click)="showNewEmailDialog()" >
        <i class="fa-solid fa-envelope-circle-check"></i>
      </button>
      <!-- <div *ngFor="let item of subscription" class="flex">

        <div *ngFor="let customer of filtered" class="flex">

      <div class="" *ngIf="item.plan==0 && filtered.length <= 10">
        <button class="btn " type="button" (click)="showNewUserDialog=true" >
          <i class="fa-solid fa-user-plus"></i>
        </button>
        <button  class="btn ml-3" type="button" (click)="showNewEmailDialog()" >
        <i class="fa-solid fa-envelope-circle-check"></i>
      </button>
      </div>

      <div class="" *ngIf="item.plan==1 && filtered.length <= 20">
          <button class="btn " type="button" (click)="showNewUserDialog=true" >
            <i class="fa-solid fa-user-plus"></i>
          </button>
          <button  class="btn ml-3" type="button" (click)="showNewEmailDialog()" >
          <i class="fa-solid fa-envelope-circle-check"></i>
        </button>
        </div>

        <div class="p-2 m-2" *ngIf="item.plan==2 && filtered.length <= 50">
            <button class="btn " type="button" (click)="showNewUserDialog=true" >
              <i class="fa-solid fa-user-plus"></i>
            </button>
            <button  class="btn ml-3" type="button" (click)="showNewEmailDialog()" >
            <i class="fa-solid fa-envelope-circle-check"></i>
          </button>
          </div>

          <div class="p-2 m-2" *ngIf="item.plan==3 && filtered.length <= 100">
              <button class="btn " type="button" (click)="showNewUserDialog=true" >
                <i class="fa-solid fa-user-plus"></i>
              </button>
              <button  class="btn ml-3" type="button" (click)="showNewEmailDialog()" >
              <i class="fa-solid fa-envelope-circle-check"></i>
            </button>
            </div>

      </div>
    </div> -->

      </div>

    </div>
  </div>


  <div *ngIf="filtered.length > 0" class="p-2 m-2 text-blue-500"> Number of Customers : {{filtered.length}}</div>

  <div class=" mt-2 bg-gray-300 p-4 shadow rounded ">
    <div class="grid grid-cols-1 md:grid-cols-12 md:gap-2 w-100 ">
      <div class="mx-auto col-span-1">STATUS</div>
      <div class="mx-auto col-span-2">NAME</div>
      <div class="mx-auto col-span-2">EMAIL-ID</div>

      <div class="mx-auto col-span-1">PHONE </div>

      <div class="mx-auto col-span-2">AADHAR </div>
      <div class="mx-auto col-span-1">PANCARD </div>
      <div class="mx-auto col-span-1">DOB</div>
      <div class="mx-auto col-span-2"></div>
    </div>
  </div>
  <div class="">
  <div class=" mt-2 bg-white p-4 shadow rounded hover:bg-slate-100" *ngFor="let customer of filtered">
    <div class="grid  md:grid-cols-12 md:gap-4 w-full ">
      <div class=" mx-auto col-span-1">
        <span class="text-green-400 " *ngIf="!customer.newUser">ACTIVE</span>
        <span class="text-orange-400 " *ngIf="customer.newUser">NEW, UNVERIFIED</span>
      </div>
      <div class="col-span-2 col d-flex justify-content-center mx-auto">{{ customer.name }}</div>
      <div  class=" col-span-2 col d-flex justify-content-center md:text-sm mx-auto">
        {{customer.email}}
      </div>

      <div class="col-span-1 col d-flex justify-content-center mx-auto">
        {{ customer.phoneNumber }}
      </div>

      <div class="col-span-2 col d-flex justify-content-center mx-auto">
        {{ customer.aadharCardNumber }}
      </div>
      <div  class="col-span-1 col-span-1col d-flex justify-content-center mx-auto">
        {{customer.panCardNumber}}
      </div>
      <div class="col-span-1 col d-flex justify-content-center mx-auto">
        <span *ngIf="!customer.dateofBirth"></span>
        <span *ngIf="customer.dateofBirth">{{customer.dateofBirth.toDate() | date: "dd/MM/yyyy" }}</span>
      </div>

      <div class="col-span-2 col d-flex  justify-content-center md:-mt-4 mx-auto">
        <button class="bg-white p-2 m-2 text-black rounded-md" title="ViewCustomerDetails" (click)="customer.detail = !customer.detail">
          <i class="fa-solid fa-eye"></i>
        </button>
        <button class="bg-white p-2 m-2 text-black rounded-md" title="EditCustomerDetails" (click)="editCustomerDetails(customer)" >
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
        <button class="bg-white p-2 m-2 text-xl text-blue-600 rounded-md" title="viewHistory" (click)="viewHistory(customer)">
          <i class="fa-solid fa-circle-info" ></i>
        </button>
      </div>
    </div>
    <!-- DETAILS -->
    <div class=" p-4 grid grid-cols-1 md:grid-cols-4 w-full bg-white shadow mx-auto" *ngIf="customer.detail">
      <div class="p-2 m-2 mx-auto" *ngIf="customer.socialPhotoUrl">
        <img width="150px" [src]=" customer.socialPhotoUrl " alt="" />
      </div>
      <div class="mx-auto">
        <h6 class="text-xl font-bold">Name: {{ customer.name }}</h6>
        <h6 class="">Address:{{ customer.address }} </h6>
        <h6 class="">Date of Birth: <span *ngIf="customer.dateofBirth"> {{ customer.dateofBirth.toDate() | date: "dd/MM/yyyy" }}</span></h6>

        <h6 class="card-text">Phone Number: {{ customer.phoneNumber }}</h6>
      </div>
      <div class="p-2 m-2 mx-auto">
        <h6 class="">Email-Id: {{ customer.email }} </h6>
        <h6 class="">AADHAR: {{ customer.aadharCardNumber }}</h6>
        <h6 class="">PAN Card: {{ customer.panCardNumber | uppercase}}</h6>
        <h6 class=""><b>IT Portal</b> <br>
          Login: {{ customer.loginId }}<br>
          Password: {{ customer.loginPwd }}
        </h6>
      </div>
    </div>
    <div class="p-2 shadow mt-2" *ngIf="customer.detail && customer.newUser">
      <div class="">
        <h6 class="text-green-500">This customer is a new user.</h6>
      </div>
      <div class="col d-flex justify-content-center">
        <!-- TODO: Implementation -->
        <button class="bg-green-400 hover:bg-green-600 text-white rounded p-2 m-2" (click)="setAsVerified(customer)">
          SET AS VERIFIED
        </button>
      </div>
    </div>
  </div>
</div>
</div>

<!-- add New Customer Dialog -->
<div *ngIf="showNewUserDialog" class="grid grid-cols-1 md:flex md:gap-2 fixed top-0 h-screen w-screen bg-gray  items-center justify-center bg-gray-50" >
  <div class="bg-white shadow p-6 rounded">
<form [formGroup]="formvalue" class="grid grid-cols-1 md:gap-2 space-y-6 p-2 m-2 w-fit" >
  <div class=" font-medium text-lg" >CUSTOMER PROFILE

  <button (click)="cancel()"
    class="flex ml-auto -mt-6">
    <i class="fa-solid fa-xmark"></i>
    </button>
</div>
  <input type="hidden" name="remember" value="true">
  <div class="space-y-px rounded-md shadow-sm">

    <div class="flex p-3 space-x-3">
      <label for="Name" class="sr-only">Name</label>
      <input id="Name" name="Name" type="text" autocomplete="Name" formControlName="name"
       required class="p-2 mb-2 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Name" >

      <label for="email" class="sr-only">Email-Id</label>
      <input id="email" name="email" type="email" autocomplete="email" formControlName="email"
       required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Email address" >
    </div>

    <div class="flex p-3 space-x-3">
      <label for="loginId" class="sr-only">Login Id</label>
      <input id="loginId" name="loginId" type="text"  formControlName="loginId"
      required class="p-2 mb-2 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Login Id" >

      <label for="password" class="sr-only">Password</label>
          <input id="password" name="password" type="password" formControlName="loginPwd"
          required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Password" >
    </div>

    <div class="flex p-3 space-x-3">
      <label for="pancard" class="sr-only">Pancard Number</label>
      <input id="pancard" name="pancard" type="text"  formControlName="panCardNumber"
      required class="p-2 mb-2 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="PANCARD Number" >

      <label for="aadharCardNumber" class="sr-only">Aadhar Number</label>
      <input id="aadharCardNumber" name="aadharCardNumber" type="number" formControlName="aadharCardNumber"
      required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="AADHAR Number" >
    </div>

    <div class="flex p-3 space-x-3">
      <label for="phoneNumber" class="sr-only">Phone Number</label>
      <input id="phoneNumber" name="phoneNumber" type="number" formControlName="phoneNumber"
      required class="p-2 mb-2 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Phone Number" >

      <label for="address" class="sr-only">Address</label>
      <input id="address" name="address" type="text" formControlName="address"
      required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Address">

    </div>

    <div class="md:flex p-3 m-3 space-x-3 ">
      <label for="photo" >Upload Photo</label>
      <input id="photo" name="photo" type="file" formControlName="socialPhotoUrl"
      required class="p-2 mb-2 relative block w-fit rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Upload Photo">

      <label for="dob">Date Of Birth</label>
      <input id="dateofBirth" name="dateofBirth" type="date" formControlName="dateofBirth"
      required class="p-2 mb-2 space-x-3 relative block w-fit rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  >
    </div>
  </div>

  <div class="flex ml-auto">
    <button type="submit" *ngIf="showRegisterButton" (click)="newUser()"
    class="group relative justify-center rounded-md py-2 px-3 text-sm font-semibold text-white bg-green-500 hover:bg-green-600  ml-auto">
     REGISTER
    </button>

    <button type="submit" (click)="updateCustomerDetails()" *ngIf="showUpdateButton"
    class="group relative  justify-center rounded-md py-2 px-3 text-sm font-semibold text-white  bg-green-500  ml-auto">
     UPDATE
    </button>

    <button class="group relative  justify-center rounded-md py-2 px-3 text-sm font-semibold text-white  bg-red-500 uppercase ml-2"
      (click)="cancel()">Cancel</button>
  </div>
</form>
</div>
</div>

<!-- Add new Customer by Email dialog -->
<div *ngIf="showEmail" class="flex fixed top-0 h-screen w-screen bg-gray  items-center justify-center bg-gray-50" >
  <div class="bg-white shadow p-6 rounded md:w-1/3">
    <div  class=" space-y-6 p-2 m-2 " >
      <div class="flex font-medium text-lg" >CUSTOMER PROFILE

      <button (click)="showEmail=false"   class="ml-auto">
        <i class="fa-solid fa-xmark"></i>
        </button>
    </div>

      <div class="space-y-px rounded-md shadow-sm">
          <label for="email" class="sr-only">Email address</label>
          <input type="email" id="email" name="email" autocomplete="email" [(ngModel)]="email"
           required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Email address" >
        </div>

        <div class="flex ml-auto">
          <button (click)="addNewCustomerByEmail(email)"
          class="group relative justify-center rounded-md py-2 px-3 text-sm font-semibold text-white bg-green-500 hover:bg-green-600  ml-auto">
           Add User
          </button>

          <button class="group relative  justify-center rounded-md py-2 px-3 text-sm font-semibold text-white  bg-red-500 uppercase ml-2"
      (click)="cancel()">Cancel</button>
          </div>
        </div>
        </div>
</div>
