import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/services';

@Component({
  selector: 'app-contact-requests',
  templateUrl: './contact-requests.component.html',
  styleUrls: ['./contact-requests.component.css']
})
export class ContactRequestsComponent implements OnInit {
  contactRequests = [];
  filteredRequests = [];
  customers = [];
  searchText = '';

  constructor(private fs: FirebaseService) {

  }

  search() { }
  viewContactRequest(contact) {

  }

  async ngOnInit() {
    this.fs.getCustomers().subscribe(allCustomers => this.customers = allCustomers);

    this.fs.getAllContactUsRequest().subscribe(async request => {
      request.forEach(
        req => {
          req.name = (this.customers.find(customer => customer.uid === req.userId).name);
        }
      );
      this.contactRequests = request;
    });
  }

}
