 <div class=" grid md:grid-cols-2 grid-cols-1 md:gap-4" *ngIf="disclaimerPopup==false">

      <div class="md:flex mx-auto">
        <div *ngIf="!(afAuth.authState | async)" class="ml-auto">
          <div class=" mx-auto">
            <img src="/assets/images/newImg.png" alt="" class="rounded w-fit mx-auto" srcset="" />
          </div>




        <div *ngIf="afAuth.authState | async as user" class="logout mx-auto mt-10">
          <div >
            <p class="md:flex mx-auto">
              Logged in as <strong class="ml-2">{{ user.email }}</strong>
            </p>
            <div class="flex m-4">
            <button class="btn ml-auto m-4" routerLink="home"> Home</button>

            <button class="btn m-4" (click)="afAuth.signOut()">
              Logout
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>


      <div class="mx-auto">
      <div class="font-semibold text-xl md:text-4xl ml-10 mt-5 p-2 md:mt-28">
        Easy Tax Filing: Your Auditor's Partner
      </div>
      <div class="rounded shadow  p-5 md:m-20 md:ml-36 md:p-5 md:w-96 mx-auto">
        <button class="group relative flex w-full  m-10  mx-auto justify-center hover:bg-gray-200 bg-white shadow p-2 rounded-md text-base text-black hover:scale-105 "
        (click)="authService.GoogleAuth()">
          <img src="/assets/images/google-logo.svg" width="20px" class="mr-2">
            Login/Sign up with Google
        </button>

        <div class="md:mt-16 md:w-96 mx-auto">By signing in, I agree to the
          <p class="text-sm text-blue-600 inline-flex cursor-pointer" routerLink="/privacyPolicy" >Privacy</p>
           Statement and
           <p class="text-sm text-blue-600 inline-flex cursor-pointer" routerLink="/termsandConditions" >Terms of Service.</p>
        </div>


</div>
<div class="bg-blue-500 bottom-0 fixed md:-ml-14 w-full md:w-1/2 p-2 inline-flex mx-auto" >
  <div class="text-lg text-white mx-auto">
    <img  src="../../../assets/images/logo_horizontal.png" class="inline-flex" width="120px">
    <small class="text-xs m-2">(v {{appVersion}})
      </small>
      <small class="text-xs">{{date}}</small>

  </div>
  </div>

</div>

</div>



