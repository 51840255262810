<div class="sectionMobile md:m-10 md:p-10">

  <div class="text-xl">
    <div class=" p-2" *ngIf="!endDate">Log Activity for {{currentDate | date:'dd/MM/yyyy' }}</div>
    <div class="p-2" *ngIf="endDate">Log Activity for :
      ({{startDate | date:'dd/MM/yyyy' }} - {{endDate | date:'dd/MM/yyyy'}})</div>
      <div *ngIf="logs" class="p-2 text-sm text-gray-400">Number of Records : {{logs.length}}</div>
  </div>

  <div class="bg-blue-500 text-white p-2 shadow rounded m-2 md:flex">

    <p class="p-2 uppercase font-semibold">Select Date</p>
    <div class="inline-flex">
    <input class="bg-white text-gray-700 rounded p-2 " [max]="maxDate" type="date" [(ngModel)]="startDate">
    <p class="m-2">To</p>
    <input class="bg-white text-gray-700 rounded p-2 " [max]="maxDate" type="date" [(ngModel)]="endDate">
    </div>

      <button *ngIf="disableButton==false || !startDate || !endDate " class="buttonNone bg-gray-400 p-2 ml-2"> Search</button>
      <button *ngIf="disableButton==true && startDate && endDate" class="buttonNone bg-green-400 p-2 ml-2" (click)="logSearch()"> Search</button>

      <button *ngIf="disableButton==false || !startDate || !endDate" class="buttonNone bg-gray-400 p-2 ml-2"> Reset</button>
      <button *ngIf="disableButton==true && startDate && endDate" class="buttonNone bg-red-400 p-2 ml-2" (click)="reset()"> Reset</button>

  </div>

  <div *ngIf="showSearch" class="bg-blue-200 p-2 m-2 text-white rounded shadow">
  <div  class=" inline-flex">
    <div class="p-2 uppercase text-black">Choose User</div>
    <div >
      <ng-autocomplete [data]="users" [searchKeyword]="searchKeyword" placeholder="Select team member"
        (selected)='selectEvent($event)' [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"></ng-autocomplete>
      <ng-template #itemTemplate let-item >
        <a [innerHTML]=" item.name "></a>
      </ng-template>
      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
    </div>
    <button *ngIf=" !endDate " class="buttonNone bg-gray-400 p-2 ml-2"> Search</button>
    <button *ngIf="startDate && endDate" class="buttonNone bg-green-400 p-2 ml-2" (click)="logSearchByUser()"> Search</button>

    <button *ngIf="!endDate" class="buttonNone bg-gray-400 p-2 ml-2"> Reset</button>
    <button *ngIf="startDate && endDate" class="buttonNone bg-red-400 p-2 ml-2" (click)="logSearch()"> Reset</button>
  </div>




  <ng-container *ngIf="loading; else content">
    <div  class="border-2 border-white shadow rounded p-4 m-2">
      <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
          <div class="space-y-3">
            <div class="grid grid-cols-5 gap-4 m-2 p-2">
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
            </div>
            <div class="grid grid-cols-5 gap-4 m-2 p-2">
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

<ng-template #content>

  <div *ngIf="logs  && logs.length==0" class="text-red-500 flex m-2 p-2 text-lg justify-center"> No Logs</div>

  <div *ngIf="logs  && logs.length>0" class="overflow-auto h-full">
    <div *ngFor="let log of logs | paginate: { itemsPerPage: 10, currentPage: q}"
    class="m-2 p-2 text-sm font-medium bg-slate-200 shadow rounded-md ">
    <div class="grid md:grid-cols-5 text-center">

      <div class="p-2">{{log.logDateTime.toDate() | date:'dd/MM/yyyy | h:mm a'}}</div>

      <div class="p-2">{{log.logId}}</div>

      <div class="p-2"> {{log.userName}}</div>

      <div class="p-2">
        <div class="inline-flex">
        <div class="ml-2">
        <div *ngIf="log.logType==0">Create Customer</div>
        <div *ngIf="log.logType==1">Edit Customer</div>
        <div *ngIf="log.logType==2">Delete Customer</div>
        <div *ngIf="log.logType==3">Added Customer using Email</div>
        <div *ngIf="log.logType==4">Customer Verified</div>
        <div *ngIf="log.logType==5">Changed IT Status to New</div>
        <div *ngIf="log.logType==6">Changed IT Status to Submitted</div>
        <div *ngIf="log.logType==7">Changed IT Status to Review</div>
        <div *ngIf="log.logType==8">Changed IT Status to Incompleted</div>
        <div *ngIf="log.logType==9">Changed IT Status to Invalid</div>
        <div *ngIf="log.logType==10">Changed IT Status to Deleted</div>

        <div *ngIf="log.logType==11">Changed GST Status to New</div>
        <div *ngIf="log.logType==12">Changed GST Status to Submitted</div>
        <div *ngIf="log.logType==13">Changed GST Status to Review</div>
        <div *ngIf="log.logType==14">Changed GST Status to Incompleted</div>
        <div *ngIf="log.logType==15">Changed GST Status to Invalid</div>
        <div *ngIf="log.logType==16">Changed GST Status to Deleted</div>

        <div *ngIf="log.logType==17">Create Subscription</div>
        <div *ngIf="log.logType==18">Raise Ticket</div>
        <div *ngIf="log.logType==19">Changed Ticket Status to New</div>
        <div *ngIf="log.logType==20">Changed Ticket Status to Open</div>
        <div *ngIf="log.logType==21">Changed Ticket Status to Closed</div>
        <div *ngIf="log.logType==22">Changed Ticket Status to Deferred</div>

        <div *ngIf="log.logType==23">Add User</div>
        <div *ngIf="log.logType==24">Subscription Update</div>

        <div *ngIf="log.logType==25">Cancel Trial Subscription</div>

        <div *ngIf="log.logType==26">Create Subscription Item</div>
        <div *ngIf="log.logType==27">Edit Subscription Item </div>
        <div *ngIf="log.logType==28">Delete Subscription Item</div>
        <div *ngIf="log.logType==29">Start User Subscription </div>
        <div *ngIf="log.logType==30">User Verification </div>
        <div *ngIf="log.logType==31">User UnVerified </div>
        <div *ngIf="log.logType==32">Pause User Subscription </div>

        </div>
        </div>
      </div>

      <div class="px-4 py-2 border-2 border-blue-222 w-fit h-full rounded mx-auto cursor-pointer" (click)="log.viewLogData = !log.viewLogData">
        <button class="text-blue-500 " >
        <i *ngIf="!log.viewLogData" class="fas fa-eye"></i>
        <i *ngIf="log.viewLogData" class="fas fa-eye-slash"></i>
      </button>
      </div>
    </div>

    <div *ngIf="log.viewLogData" class="bg-white rounded shadow m-2 p-2">
      <div>
        <div *ngIf="log.logDataType==1">Customer</div>
        <div *ngIf="log.logDataType==2">IT Request</div>
        <div *ngIf="log.logDataType==3">GST Request</div>
        <div *ngIf="log.logDataType==4">Subscription</div>
        <div *ngIf="log.logDataType==5">Raise Ticket</div>
        <div *ngIf="log.logDataType==6">User</div>
        <div *ngIf="log.logDataType==7">Subscription Item</div>
        <div *ngIf="log.logDataType==8">User Verification</div>
        <div *ngIf="log.logDataType==9">Pause User Subscription</div>
        <div *ngIf="log.logDataType==10">Start User Subscription</div>
      </div>

      <!-- customers -->
        <div *ngIf="log.logDataType==1" class="text-left">
          <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-12" *ngIf="log.logData">
            <div class="col-span-3 p-2">
              <div class=""> {{log.logData.id}}</div>
              <div class="p-2 m-2 mx-auto" *ngIf="log.logData.socialPhotoUrl">
                <img width="80px" [src]=" log.logData.socialPhotoUrl "  />
              </div>
            </div>
            <div class="col-span-5 p-2">
              <div class="p-2">Customer Name : {{log.logData.name}} </div>
              <div class="p-2">Customer Email : {{log.logData.email}}</div>
              <div class="p-2">Aadhar Number :  {{log.logData.aadharCardNumber}} </div>
              <div class="p-2">Phone Number : {{log.logData.phoneNumber}}  </div>
            </div>
            <div class="col-span-4 p-2">
              <div class="p-2">login Id : {{log.logData.loginId}}  </div>
              <div class="p-2">login Password : {{log.logData.loginPwd}}  </div>
              <div class="p-2">Pancard Number : {{log.logData.panCardNumber}}  </div>
              <div class="p-2">Date of Birth : {{log.logData.dateofBirth.toDate() | date:('dd/MM/yyyy')}}  </div>
            </div>
          </div>
        </div>

        <!-- It Requests -->
          <div *ngIf="log.logDataType==2" class="text-left">
            <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-3" *ngIf="log.logData">
              <div>Request Id : {{log.logData.id}}</div>
              <div>Assesment Year : {{log.logData.assesmentYear}}</div>
              <div>user Id : {{log.logData.userId}}</div>
          </div>
          </div>

          <!-- GST Requests -->
          <div *ngIf="log.logDataType==3" class="text-left">
            <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-3" *ngIf="log.logData">
              <div>Request Id : {{log.logData.id}}</div>
              <div>Month : {{log.logData.month}}</div>
              <div>User Id : {{log.logData.userId}}</div>
          </div>
          </div>

          <!-- Subscription -->
          <div *ngIf="log.logDataType==4" class="text-left">
            <div class="grid grid-cols-6 bg-gray-500 text-white m-2 p-2 rounded Shadow">
              <div class="mx-auto">Id</div>
              <div class="mx-auto">Name</div>
              <div class="mx-auto">Plan</div>
              <div class="mx-auto">Amount</div>
              <div class="mx-auto">Start Date</div>
              <div class="mx-auto">End date</div>
            </div>
            <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-6" *ngIf="log.logData">
              <div class="mx-auto">{{log.logData.id}}</div>
              <div class="mx-auto">{{log.logData.name}}</div>
              <div class="mx-auto">
                <span *ngIf="log.logData.plan==0">Trial</span>
                <span *ngIf="log.logData.plan==1">Silver</span>
                <span *ngIf="log.logData.plan==2">Gold</span>
                <span *ngIf="log.logData.plan==3">Diamond</span>
              </div>
              <div class="mx-auto">{{log.logData.amount}}</div>
              <div class="mx-auto">{{log.logData.startDate.toDate() | date:'dd/MM/yyyy'}}</div>
              <div class="mx-auto">{{log.logData.endDate.toDate() | date:'dd/MM/yyyy'}}</div>
          </div>
          </div>

          <!-- Raise Ticket -->
          <div *ngIf="log.logDataType==5" class="text-left">
            <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-4" *ngIf="log.logData">
              <div>Id : {{log.logData.id}}</div>
              <div>Name : {{log.logData.name}}</div>
              <div>Title : {{log.logData.title}}</div>
              <div>Description : {{log.logData.description}}</div>
          </div>
          </div>

          <!-- Subscription Item-->
          <div *ngIf="log.logDataType==7" class="text-left">
            <div class="grid grid-cols-5 bg-gray-500 text-white m-2 p-2 rounded Shadow text-center">
              <div class="mx-auto">Id</div>
              <div class="mx-auto">Plan</div>
              <div  class="mx-auto">Customers</div>
              <div  class="mx-auto">customer Cost </div>
              <div  class="mx-auto">Base </div>
              </div>
              <div class="grid grid-cols-5 bg-gray-500 text-white m-2 p-2 rounded Shadow text-center">
                <div >GST %</div>
                <div>description</div>
                <div>discount 12M</div>
                <div>discount 24M</div>
                <div>promotion </div>
            </div>
            <div *ngIf="log.logData">
              <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-5 text-center">
              <div class="mx-auto">{{log.logData.id}}</div>
               <!-- Plan -->
      <div class="" *ngIf="log.logData.type==0">Trial</div>
      <div class="" *ngIf="log.logData.type==1">Silver</div>
      <div class="" *ngIf="log.logData.type==2">Gold</div>
      <div class="" *ngIf="log.logData.type==3">Diamond</div>
      <!-- Number of Customers -->
      <div>{{log.logData.numberOfCustomers}}</div>
      <!-- subscription Cost / customer -->
      <div>{{log.logData.customerSubscriptionRate}}</div>
      <!-- base Value - Dashboard Cost -->
      <div>{{log.logData.baseValue}}</div>
      </div>
      <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-5 text-center">
      <!-- Gst % -->
      <div>{{log.logData.gstPercent}}%</div>
      <!-- description -->
      <div>{{log.logData.description}}</div>
      <!-- Discount - 12Months -->
      <div>{{log.logData.discountyear}}%</div>
       <!-- Discount - 24Months -->
       <div>{{log.logData.discountyears}}%</div>
       <!-- Promotional Value -->
      <div>{{log.logData.promotionalValue}}</div>
          </div>
          </div>
</div>

 <!-- User Verification -->
 <div *ngIf="log.logDataType==8" class="text-left">
  <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-2 text-center" *ngIf="log.logData">
    <div>Id : {{log.logData.uid}}</div>
    <div>Name : {{log.logData.name}}</div>
</div>
</div>
<!-- Start User Subscription -->
<div *ngIf="log.logDataType==9" class="text-left">
  <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-2 text-center" *ngIf="log.logData">
    <div>Id : {{log.logData.uid}}</div>
    <div>Name : {{log.logData.name}}</div>
</div>
</div>
<!-- Pause User Subscription -->
<div *ngIf="log.logDataType==10" class="text-left">
  <div class="grid bg-gray-300 m-2 p-2 rounded Shadow md:grid-cols-2 text-center" *ngIf="log.logData">
    <div>Id : {{log.logData.uid}}</div>
    <div>Name : {{log.logData.name}}</div>
</div>
</div>
    </div>
    </div>
    <pagination-controls (pageChange)="q = $event"></pagination-controls>
    </div>

</ng-template>

</div>
