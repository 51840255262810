import { BusinessTypePipe } from './business-type.pipe';
import { GstRequestTypePipe } from './gst-request-type.pipe';
import { ITRequestTypePipe } from './itrequest-type.pipe';
import { OtherServicesPipe } from './other-services.pipe';
import { SlotNumberPipe } from "./slot-number.pipe";



export {
  BusinessTypePipe, GstRequestTypePipe, ITRequestTypePipe, OtherServicesPipe, SlotNumberPipe
}
