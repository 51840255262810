import { Component } from '@angular/core';
import packageInfo from "../../../../../package.json";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ETFUser } from 'src/app/models/user';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  public appVersion: string = '';
  currentUser: ETFUser;

  constructor (public afAuth: AngularFireAuth,private fs:FirebaseService){ }

  public ngOnInit() {
    this.appVersion = packageInfo.version;

    this.afAuth.authState.subscribe(result=>{
      if(result!==null){
      this.fs.getUserData().then((res) => {
        res.subscribe((currentUser) => {
            this.currentUser = currentUser as ETFUser;
        })})

  }

})}}
