import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, firstValueFrom, of, switchMap } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ETFUser } from '../models/user';
import { LogEventDataType, LogEventType } from '../enum/log';
import { LogEntry } from '../models/logData';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  user$: Observable<ETFUser | null | undefined>;
  currentUser:ETFUser;

  currentDate=new Date()

  logsCollection=this.afireStore.collection('logs',ref => ref.orderBy('logDateTime'));



  constructor(private afireStore: AngularFirestore ,private afAuth: AngularFireAuth
    //  private authService: AuthService
     ) {
      this.user$ = this.afAuth.authState.pipe(
        switchMap(user => {
          // Logged in
          if (user) {
            return this.afireStore.doc<ETFUser>(`users/${user.uid}`).valueChanges();
          } else {
            // Logged out
            return of(null);
          }
        })
      )
      this.user$.subscribe(res=>{
        this.currentUser=res
      })
   }

  public createLog( dataItem : any , logEventType : LogEventType , dataType : LogEventDataType):LogEntry {
    var logEntry : LogEntry = {
      logId : this.afireStore.createId(),
      logData : dataItem,
      logDataType : dataType,
      logDateTime : new Date(),
      logType : logEventType,
      userId : this.currentUser.uid,
      userName : this.currentUser.name
    }
   return logEntry

  }


  public getLogs(){
    return this.logsCollection.valueChanges({ idField: 'id' });
  }

  //get logs for date range
  getLogsbyDateRange(startDate: Date, endDate: Date) {
      var refStartDate = new Date(startDate);
      var refEndDate = new Date(endDate);
      var startDate = new Date(refStartDate.getUTCFullYear(), refStartDate.getMonth(), refStartDate.getDate(), 0, 0, 0);
      var endDate = new Date(refEndDate.getFullYear(), refEndDate.getMonth(), refEndDate.getDate(), 23, 59, 59);
      return firstValueFrom(this.afireStore.collection('logs', ref => ref
        .where('logDateTime', '>=', startDate)
        .where('logDateTime', '<=', endDate)
        .orderBy('logDateTime', 'desc')
      )
        .valueChanges());
  }

  getLogsbyUserDateRange(userId:string,startDate: Date, endDate: Date){
    var refStartDate = new Date(startDate);
    var refEndDate = new Date(endDate);
    var startDate = new Date(refStartDate.getUTCFullYear(), refStartDate.getMonth(), refStartDate.getDate(), 0, 0, 0);
    var endDate = new Date(refEndDate.getFullYear(), refEndDate.getMonth(), refEndDate.getDate(), 23, 59, 59);
    return firstValueFrom(this.afireStore.collection('logs', ref => ref
      .where('logDateTime', '>=', startDate)
      .where('logDateTime', '<=', endDate)
      .where('userId', '==' , userId)
      .orderBy('logDateTime', 'desc')
    )
      .valueChanges());
  }

  // get logs for current date
  getAllLogs(date: Date) {
    var refDate = new Date(date);
    var startDate = new Date(refDate.getUTCFullYear(), refDate.getMonth(), refDate.getDate(), 0, 0, 0);

    return firstValueFrom(this.afireStore.collection('logs', ref => ref
      .where('logDateTime', '>=', startDate)
      .orderBy('logDateTime', 'desc')
    )
      .valueChanges());
  }
}
