import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { Router } from '@angular/router';
import { ETFUser } from '../models/user';
import { FirebaseService } from './firebase.service';
import { Observable, first, firstValueFrom } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userCollection:AngularFirestoreCollection;

  user:Observable<ETFUser>;
  authState: any=null;
  currentUser: any;

  signUpDate:Date = new Date();

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private fs: FirebaseService,
    private firestore:AngularFirestore)
     {

      this.userCollection=this.firestore.collection('users')

      this.currentUser=this.afAuth.authState.pipe( first() )
      this.afAuth.authState.subscribe(data => this.authState = data)
  }

  getCurrentUser(uId){
    return this.userCollection.doc(uId).get();
   };

   getUser() {
    return firstValueFrom(this.afAuth.authState);
  }

  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  public isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  AuthLogin(provider: GoogleAuthProvider) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {

        firstValueFrom( this.getCurrentUser(result.user.uid)).then((result)=>{
          var userInfo=result.data();
        })

        //TODO: Add user details to database if it doesnt exist
        var user: ETFUser =
        {
          name: result.user?.displayName,
          email: result.user?.email,
          photoUrl: result.user?.photoURL,
          uid: result.user?.uid,
          newUser: true,
          detail: false,
          verified: false,
          validSubscription: false,
          companyName: "",
          address: "",
          phoneNumber: result.user?.phoneNumber,
          isAdmin:false,
          ifAgree:false,
          isSuperAdmin : false,
          signUpDate : this.signUpDate,
          trialSubscription:false
        };

        this.fs.addNewUser(user).then(res => {
          this.router.navigate(['home'])
        });

      })
      .catch((error) => {
        console.log(error);
      })
  }


}
