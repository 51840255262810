import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { first, firstValueFrom, Observable } from 'rxjs';
import { FirebaseService } from '../services/services';
import { ETFUser } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {

  constructor(private fs: FirebaseService, private router:Router) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    var userInfo = await firstValueFrom((await this.fs.getUserData()).pipe(first()));

    if ((userInfo as ETFUser).validSubscription == false) {
      this.router.navigate(['/myaccount']);
      return false;

    }
    return true;
  }

}
