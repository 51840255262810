<div class="sectionMobile md:m-10 md:p-10 mt-2">
  <div class=" mt-5">
    <div class="text-2xl shadow p-2 text-center w-full">
      <h2>CUSTOMERS</h2>
    </div>
  </div>
  <div class="mt-2 w-100 justify-content-center shadow">
    <div class="md:flex gap-2 mb-auto">
      <div class="flex ">
        <span class="p-4">Filter</span>
        <div class="mt-2">
          <input type="text" class="form-control p-2 rounded shadow" placeholder="Search" aria-label="Search"
          aria-describedby="button-addon2" [(ngModel)]="searchText" />
          <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="search()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </div>
  </div>




  <div class=" mt-2 bg-gray-300 p-4 shadow rounded ">
    <div class="grid grid-cols-1 md:grid-cols-8 md:gap-2 w-100 ">
      <div class="mx-auto">STATUS</div>
      <div class="mx-auto">NAME</div>
      <div class="mx-auto">PHONE NUMBER</div>
      <div class="mx-auto">AADHAR NUMBER</div>
      <div class="mx-auto">PANCARD NUMBER</div>
      <div class="mx-auto">DATE OF BIRTH</div>
      <div class="mx-auto">EMAIL-ID</div>
      <div class="mx-auto"></div>
    </div>
  </div>
  <div class="md:mb-20">
  <div class=" mt-2 bg-white p-4 text-sm shadow rounded hover:bg-slate-100" *ngFor="let customer of filtered">
    <div class="grid grid-cols-1 md:grid-cols-8 md:gap-4 w-full ">
      <div class=" mx-auto">
        <span class="text-green-400 " *ngIf="!customer.newUser">ACTIVE</span>
        <span class="text-orange-400 " *ngIf="customer.newUser">NEW, UNVERIFIED</span>
      </div>
      <div class="col d-flex justify-content-center mx-auto">{{ customer.name }}</div>

      <div class="col d-flex justify-content-center mx-auto">
        {{ customer.phoneNumber }}
      </div>

      <div class="col d-flex justify-content-center mx-auto">
        {{ customer.aadharCardNumber }}
      </div>
      <div  class="col d-flex justify-content-center mx-auto">
        {{customer.panCardNumber}}
      </div>
      <div class="col d-flex justify-content-center mx-auto">
        <span *ngIf="customer.dateofBirth">{{customer.dateofBirth.toDate() | date: "dd/MM/yyyy" }}</span>
      </div>
      <div  class="col d-flex justify-content-center md:text-sm mx-auto">
        {{customer.email}}
      </div>
      <div class="col d-flex  justify-content-center md:-mt-4 mx-auto">
        <button class="bg-white p-2 m-2 text-black rounded-md" (click)="customer.detail = !customer.detail">
          <i class="fa-solid fa-eye"></i>
        </button>
        <!-- <button class="bg-white p-2 m-2 text-black rounded-md" (click)="editCustomerDetails(customer)" >
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
        <button class="bg-white p-2 m-2 text-xl text-blue-600 rounded-md" (click)="viewHistory(customer)">
          <i class="fa-solid fa-circle-info"></i>
        </button> -->
      </div>
    </div>
    <!-- DETAILS -->
    <div class=" p-4 grid grid-cols-1 md:grid-cols-4 w-full bg-white shadow mx-auto" *ngIf="customer.detail">
      <div class="p-2 m-2 mx-auto" *ngIf="customer.socialPhotoUrl">
        <img width="150px" [src]=" customer.socialPhotoUrl " alt="" />
      </div>
      <div class="mx-auto">
        <h6 class="text-xl font-bold">Name: {{ customer.name }}</h6>
        <h6 class="">Address:{{ customer.address }} </h6>
        <h6 class="">Date of Birth: <span *ngIf="customer.dateofBirth"> {{ customer.dateofBirth | date:
            "dd/MM/yyyy" }}</span></h6>

        <h6 class="card-text">Phone Number: {{ customer.phoneNumber }}</h6>
      </div>
      <div class="p-2 m-2 mx-auto">
        <h6 class="">Email-Id: {{ customer.email }} </h6>
        <h6 class="">AADHAR: {{ customer.aadharCardNumber }}</h6>
        <h6 class="">PAN Card: {{ customer.panCardNumber | uppercase}}</h6>
        <h6 class=""><b>IT Portal</b> <br>
          Login: {{ customer.loginId }}<br>
          Password: {{ customer.loginPwd }}
        </h6>
      </div>
    </div>
    <div class="p-2 shadow mt-2" *ngIf="customer.detail && customer.newUser">
      <div class="">
        <h6 class="text-green-500">This customer is a new user.</h6>
      </div>
      <div class="col d-flex justify-content-center">
        <!-- TODO: Implementation -->
        <button class="bg-green-400 hover:bg-green-600 text-white rounded p-2 m-2" (click)="setAsVerified(customer)">
          SET AS VERIFIED
        </button>
      </div>
    </div>
  </div>
</div>
</div>


