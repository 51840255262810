import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/services';
import { Router } from '@angular/router';
import { ETFUser } from 'src/app/models/user';
import { element } from 'protractor';
import { AuthService } from 'src/app/services/auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.css'],
})
export class BusinessesComponent implements OnInit {
  public businesses;
  filtered = [];
  searchText;
  user: ETFUser;
  userId: any;
  userEmail: any;
  customers: any=[];
  UCD: any;

  customerDetails: any;

  constructor(
    private fs: FirebaseService,
    private router: Router,
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    // this.fs.getBusinesses().subscribe((result) => {
    //   this.businesses = result;
    //   this.filtered = result;
    // });
this.userCustomerBusiness()

  }

  viewBusinessDetail(businessInfo: any) {
    this.router.navigate(['business-detail', { id: businessInfo.id, isParentBusinessPage: true }]);
  }
  search() {
    this.filtered = this.businesses
      .filter(business => business.name
        .toUpperCase()
        .includes(this.searchText
          .toUpperCase()));
  }


  //--------------- user => customer => business------------------
  userCustomerBusiness(){

    this.authService.getUser().then(res=>{

        this.user = res as ETFUser;
        this.userId=this.user.uid;

        this.customerDetails=[]

    // gets customer details with current user
     firstValueFrom(this.fs.getAllCustomersDetails(this.userId)).then(data=>{
      this.customers=data;
        // looping and pushing the data to array
      for(let i=0;i<this.customers.length;i++){
        const UCD = this.customers[i]
        this.customerDetails.push(UCD.uid)
      }
      // spliting the array - max 5 values if it exceeds 5 values it will create another array
      const size = 5;
      const customersArray:any = [];
      this.businesses=[]
      for (let i = 0; i < this.customerDetails.length; i += size) {
          customersArray.push(this.customerDetails.slice(i, i + size));
      }
      // getting businesses with customer uid
      for(let index=0;index<customersArray.length;index++){
        firstValueFrom(this.fs.getCustomerBusinesssDetails(customersArray[index])).then(res=>{
          for(let j=0;j<res.length;j++){
            this.businesses.push(res[j]);
            this.filtered.push(res[j]);
          }
        });
      }
      });
    })
  }
}
