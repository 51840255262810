import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

import { LoginComponent } from './components/common/common';
import { PrivacyPolicyComponent } from './components/common/privacy-policy/privacy-policy.component';
import { BillingComponent } from './components/Payment/billing/billing.component';
import { SubscriptionGuard } from './guards/subscription.guard';
import {
  CustomersComponent,
  ItRequestsComponent,
  ItRequestDetailComponent,
  GstRequestsComponent,
  GstRequestDetailComponent,
  BusinessesComponent,
  CustomerDetailComponent,
  BusinessDetailComponent,
  HomeComponent, ContactRequestsComponent, AppointmentsComponent
} from './components/components';

import { AdminGuard } from './guards/admin.guard';

import { UsersComponent } from './admin/users/users.component';
import { TermsandconditionsComponent } from './components/common/termsandconditions/termsandconditions.component';
import { SubscriptionComponent } from './components/Payment/subscription/subscription.component';
import { RaiseTicketComponent } from './components/common/raise-ticket/raise-ticket.component';
import { TicketsComponent } from './components/common/tickets/tickets.component';
import { DeleteComponent } from './data/delete/delete.component';
import { LandingComponent } from './components/common/landing/landing.component';
import { UserCustomerComponent } from './admin/user-customer/user-customer.component';
import { SubscriptionPlansComponent } from './admin/subscription-plans/subscription-plans.component';
import { SubscribtionDetailsComponent } from './admin/subscribtion-details/subscribtion-details.component';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { ActivityLogsComponent } from './admin/activity-logs/activity-logs.component';


const routes: Routes = [

  { path: 'home', component: HomeComponent, canActivate: [AuthGuard, SubscriptionGuard] },
  { path: 'login/home', component: HomeComponent, canActivate: [AuthGuard,SubscriptionGuard] },
  {
    path: 'customer-detail', component: CustomerDetailComponent, canActivate: [AuthGuard,SubscriptionGuard]
  },
  {
    path: 'myaccount', component: BillingComponent, canActivate: [AuthGuard],
  },
  {
    path: 'addcustomer', component: AddCustomerComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'customers', component: CustomersComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'business-detail', component: BusinessDetailComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'businesses', component: BusinessesComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'itRequests', component: ItRequestsComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'itRequestDetail', component: ItRequestDetailComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'gstRequests', component: GstRequestsComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'gstRequestDetail', component: GstRequestDetailComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'contactRequests', component: ContactRequestsComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  {
    path: 'appointments', component: AppointmentsComponent, canActivate: [AuthGuard,SubscriptionGuard],
  },
  { path:'users', component:UsersComponent, canActivate:[AuthGuard,AdminGuard]},
  { path:'termsandConditions' , component:TermsandconditionsComponent},
  { path:'subscription' , component:SubscriptionComponent},
  { path:'raiseTicket' , component:RaiseTicketComponent, canActivate: [AuthGuard,SubscriptionGuard]},
  { path:'tickets' , component:TicketsComponent,canActivate: [AuthGuard,AdminGuard]},
  { path:'deletemydata', component:DeleteComponent},
  { path: 'privacyPolicy', component: PrivacyPolicyComponent },
  { path: 'login', component: LoginComponent },
  { path : 'userCustomer', component:UserCustomerComponent, canActivate:[AuthGuard,AdminGuard]},
  { path : 'subscriptionPlans', component:SubscriptionPlansComponent, canActivate:[AuthGuard,AdminGuard]},
  { path : 'subscriptionDetails', component:SubscribtionDetailsComponent, canActivate:[AuthGuard,AdminGuard]},
  { path : 'activitylogs', component:ActivityLogsComponent, canActivate:[AuthGuard,AdminGuard]},

  { path: '', component: LandingComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
