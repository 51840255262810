import { Component, OnInit } from '@angular/core';
import { FirebaseService, DataService } from 'src/app/services/services';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ETFUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-gst-requests',
  templateUrl: './gst-requests.component.html',
  styleUrls: ['./gst-requests.component.css']
})
export class GstRequestsComponent implements OnInit {
  gstRequests = [];
  filteredRequests = [];
  businesses = [];
  searchText = '';


  user: ETFUser;
  userId: any;
  customerDetails: any[];
  customers: any[];
  boundRequest: any;

  constructor(private fs: FirebaseService, private ds: DataService, private router: Router,private authService:AuthService) { }

  ngOnInit() {

    this.customerGstReq();


    // this.fs.getAllGstRequests().subscribe(
    //   async gstRequest => {
    //     gstRequest
    //       .forEach(
    //         gst => {
    //           gst.name = (this.businesses
    //             .find(b => b.id === gst.businessId).name);
    //         }
    //       );
        // this.gstRequests = gstRequest;
        // this.filteredRequests = this.gstRequests;   }
    // );
  }

  viewGstRequest(request: any) {
    //TODO: Send request ID
    // console.log(request);
    this.router.navigate(['gstRequestDetail', { id: request.requestId }]);
  }
  search() {
    this.filteredRequests = this.gstRequests
      .filter(request => request.name
        .toUpperCase()
        .includes(this.searchText
          .toUpperCase()));

    console.log(this.filteredRequests);
  }

  async customerGstReq(){
    this.customerDetails=[]
    var userCustomerGstReq=[]
    firstValueFrom(this.fs.getBusinesses())
      .then(businesses => this.businesses = businesses);
    this.authService.getUser().then(res=>{
      this.user = res as ETFUser;
      this.userId=this.user.uid;
      // console.log(this.userId)
  // gets customer details with current user
   firstValueFrom(this.fs.getAllCustomersDetails(this.userId)).then(data=>{
    this.customers=data;
    // console.log(this.customers);
  // looping and pushing the data to array
    for(let i=0;i<this.customers.length;i++){
      const UCD = this.customers[i]
      this.customerDetails.push(UCD.uid)
  // getting all It requests with customers uid
      firstValueFrom(this.fs.getAllGstRequests(this.userId)).then(res=>{
  // looping thriugh the data
        for(let j=0;j<res.length;j++){
          const item=res[j]
  // comparing user customer's uid with Itrequest UserId
          if(UCD.uid==item.userId){
          // console.log(item)
          userCustomerGstReq.push(item)
        }
      }
        // console.log(userCustomerItReq)
          userCustomerGstReq.forEach(
            gst => {
              gst.name = (this.businesses
                .find(b => b.id === gst.businessId).name);
            }
          );
      });
    }
    });
    this.gstRequests = userCustomerGstReq;
    this.filteredRequests = this.gstRequests;
    // console.log(this.filteredRequests)
  })
}
}
