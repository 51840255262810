<div class="section md:m-10 md:p-10 mt-2">
  <!-- TODO: Add Search functionality -->
  <div class="mt-5">
    <div class="text-2xl shadow p-2 text-center w-full">
      <h2>IT FILING REQUESTS</h2>
    </div>
  </div>
  <div class="mt-2 w-100 flex gap-2 btn-group btn-group-toggle" data-toggle="buttons">
    <label [ngClass]="filterAllClass">
      <input type="radio" name="options" id="option1" checked (click)="filterAll()" />
      ALL
    </label>
    <label [ngClass]="filterBusinessClass">
      <input type="radio" name="options" id="option2" (click)="filterBusinesses()" />
      BUSINESS ONLY
    </label>
    <label [ngClass]="filterIndividualClass">
      <input type="radio" name="options" id="option3" (click)="filterIndividuals()" />
      INDIVIDUALS ONLY
    </label>
  </div>
  <div class=" mt-2 w-100 justify-content-center" *ngIf="!isViewAll">
    <div class="flex gap-2  mt-2 w-100  shadow">
      <div class=" mb-auto p-4">
        <span>Filter</span>
      </div>

      <div class="mt-2">
        <input type="text" class="form-control p-2 rounded shadow" placeholder="Search" aria-label="Search"
        aria-describedby="button-addon2" />
        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="search()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="bg-white shadow mt-2 grid grid-cols-1 md:grid-cols-6 gap-2 p-4 uppercase text-lg">
    <div class="mx-auto">Date</div>
    <div class="mx-auto">Year of Assesment</div>
    <div class="mx-auto"> Type</div>
    <div class="mx-auto">Name</div>
    <div class="mx-auto">STATUS</div>
    <div class="mx-auto"></div>
  </div>
  <div class="">
  <div class="bg-white shadow mt-2 p-4 " *ngFor="let request of filteredRequests">
    <div class="bg-white  mt-2 grid grid-cols-1 md:grid-cols-6 gap-2 ">
      <div class="mx-auto">{{request.timestamp.toDate() | date:"dd/MM/yyyy | h:mm a"}}</div>
      <div class="mx-auto">
        <p> {{ request.assesmentYear }}</p>
      </div>

      <div class="mx-auto">
         {{ request.itRequestType | iTRequestType }}
      </div>
      <div class="mx-auto">
        <p>{{ request.name }}</p>
      </div>
      <div class="mx-auto uppercase">
        <span class="text-blue-400" *ngIf="request.status === 0">NEW </span>
        <span class="text-yellow-400" *ngIf="request.status === 1">SUBMITTED </span>
        <span class="text-green-400" *ngIf="request.status === 2">REVIEW </span>
        <span class="text-red-400" *ngIf="request.status === 3">INCOMPLETE </span>
        <span class="text-red-400" *ngIf="request.status === 4">INVALID </span>
        <span class="text-red-400" *ngIf="request.status === 5">DELETED </span>
      </div>
      <div class="mx-auto">
        <button class="btn btn-taxPrism-outline" (click)="viewIncomeTaxFilingRequest(request)">
          DETAILS
        </button>
      </div>
    </div>
  </div>
  </div>
</div>
