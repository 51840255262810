<div class="sectionMobile md:p-6 md:m-6 mt-2">
  <div class="">
    <div class=" md:text-lg p-2">
      <div *ngIf="currentUser" class="inline-flex gap-3">Welcome
        <div class="text-blue-500">{{currentUser.name}}</div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 text-xs md:text-base lg:grid-cols-5 gap-3">

    <div class=" text-center bg-white rounded m-2 p-2 shadow cursor-pointer hover:shadow-2xl" [routerLink]="['/addcustomer']">
      <p class=" m-4 text-blue-500"><i class="fa-solid fa-user-plus fa-5x"></i></p>
      <p class="tp-DashboardTitle m-2 pb-2">Add a Customer</p>
    </div>
    <div class=" text-center bg-white rounded m-2 p-2 shadow cursor-pointer hover:shadow-2xl" routerLink="/customers">
      <p class=" m-4 text-blue-500"><i class="fa-solid fa-user fa-5x"></i></p>
      <p class="tp-DashboardTitle m-2 pb-2">View Customers</p>
    </div>
    <div class=" text-center bg-white rounded m-2 p-2 shadow cursor-pointer hover:shadow-2xl" routerLink="/itRequests">
      <p class=" m-4 text-yellow-400"><i class="fa-regular fa-folder-open fa-5x"></i></p>
      <p class="tp-DashboardTitle m-2 pb-2">View IT Requests</p>
    </div>
    <div class=" text-center bg-white rounded m-2 p-2 shadow cursor-pointer hover:shadow-2xl" routerLink="/gstRequests">
      <p class=" m-4 text-yellow-400"><i class="fa-regular fa-folder-open fa-5x"></i></p>
      <p class="tp-DashboardTitle m-2 pb-2">View GST Requests</p>
    </div>
    <div class=" text-center bg-white rounded m-2 p-2 shadow cursor-pointer hover:shadow-2xl" routerLink="/appointments">
      <p class=" m-4 text-gray-400"><i class="fa-regular fa-calendar-days fa-5x"></i></p>
      <p class="tp-DashboardTitle m-2 pb-2">View Appoinments</p>
    </div>
  </div>

  <!-- <div *ngIf="viewCustomer">
    <div class="bg-white p-2 m-2">
      <div class="text-lg font-medium p-2 m-2">New Customers</div>
      <div class="bg-gray-200 shadow rounded grid md:grid-cols-4 p-2 m-2 uppercase font-medium text-center">

        <div> Name </div>
        <div> Email </div>
        <div> Phone </div>
        <div> Status </div>
      </div>

      <div class="p-2 m-2 shadow rounded text-center " *ngFor="let customer of allCustomers">
        <div class="grid md:grid-cols-4" *ngIf="customer.newUser">
        <div> {{customer.name}}</div>
        <div> {{customer.email}}</div>
        <div> {{customer.phoneNumber}}</div>
        <div class=" mx-auto">
          <span class="text-orange-400 " *ngIf="customer.newUser">NEW, UNVERIFIED</span>
        </div>
      </div>
      </div>
    </div>

  </div> -->

  <div class="grid lg:grid-cols-3">

    <div class="bg-white p-2 m-2">
      <div class=" font-medium p-2 m-2">New IT Requests</div>
      <div class="bg-gray-200 shadow rounded grid md:grid-cols-3 p-2 m-2 uppercase font-medium text-center text-base" >
        <div> Date </div>
        <div>  Type </div>
        <div> Name </div>
      </div>
      <div  *ngFor="let request of filteredItRequests">
        <div  (click)="viewIncomeTaxFilingRequest(request)" class="p-2 m-2 shadow rounded text-center grid md:grid-cols-3 text-sm cursor-pointer hover:shadow-xl">
        <div *ngIf="request.timestamp"> {{request.timestamp.toDate() | date:'dd/MM/yyyy'}}</div>
        <div *ngIf="!request.timestamp"></div>
        <div> {{ request.itRequestType | iTRequestType }}</div>
        <div> {{request.name}}</div>
        </div>
      </div>
    </div>


    <div class="bg-white p-2 m-2">
      <div class="font-medium p-2 m-2">New GST Requests</div>
      <div class="bg-gray-200 shadow rounded grid md:grid-cols-4 p-2 m-2 uppercase font-medium text-center text-base ">
        <div> Date  </div>
        <div>  Type </div>
        <div> Month  </div>
        <div>  Name </div>
      </div>
      <div class="" *ngFor="let item of filteredGSTRequests">
        <div (click)="viewGstRequest(item)" class="grid md:grid-cols-4 cursor-pointer hover:shadow-xl p-2 m-2 shadow rounded text-center  text-sm">
        <div *ngIf="item.timestamp"> {{item.timestamp.toDate() | date:'dd/MM/yyyy'}}</div>
        <div *ngIf="!item.timestamp"></div>
        <div> {{ item.gstType | gstRequestType }}</div>
        <div> {{item.month}}</div>
        <div> {{item.name}}</div>
      </div>
      </div>
    </div>


    <div class="bg-white p-2 m-2">
      <div class="font-medium p-2 m-2">New Appointments</div>
      <div class="bg-gray-200 shadow rounded grid md:grid-cols-4 p-2 m-2 uppercase font-medium text-center text-base ">
        <div> Date </div>
        <div> slot </div>
        <div> Name </div>
        <div> Phone </div>
      </div>
    </div>

</div>

</div>
<!-- <div class="grid grid-cols-1 text-xs md:text-base md:grid-cols-2 ">
  <div class="bg-white rounded m-2 text-center shadow">
    <p class="tp-DashboardTitle mt-4">New Customers</p>
    <h1 class="display-1 p-2 text-blue-700">
      {{ newCustomers }}
    </h1>
  </div>
  <div class=" text-center bg-white rounded m-2 shadow">
    <p class="tp-DashboardTitle mt-4">Number of Customers</p>
    <h1 class="display-1 p-2 text-blue-700">
      {{ numberOfCustomers }}
    </h1>
  </div>
  <div class=" text-center bg-white rounded m-2 shadow">
    <p class="tp-DashboardTitle mt-4">New IT filing requests</p>
    <h1 class="display-1 p-2 text-blue-700">
      {{ currentActiveITRequests }}
    </h1>
  </div>
  <div class=" text-center bg-white rounded m-2 shadow">
    <p class="tp-DashboardTitle mt-4">New GST filing requests</p>
    <h1 class="display-1 p-2 text-blue-700">
      {{ currentActiveGSTRequests }}
    </h1>
  </div>
  <div class=" text-center bg-white rounded m-2 shadow">
    <p class="tp-DashboardTitle mt-4">New Contact requests</p>
    <h1 class="display-1 p-2 text-blue-700">
      {{ currentActiveContactRequests }}
    </h1>
  </div>
  <div class=" text-center bg-white rounded m-2 shadow">
    <p class="tp-DashboardTitle mt-4 ">New Other Services requests</p>
    <h1 class="display-1 p-2 text-blue-700">
      {{ otherServiceRequest }}
    </h1>
  </div>
</div> -->
