<!-- popup for agreeing the terms and condition -->
<div *ngIf="currentUser">
<div class="bg-white w-screen h-screen md:mt-28 text-center align-center" *ngIf="!currentUser.ifAgree">
  <div>
    <input type="checkbox" (click)="agreeTS()">By clicking here, I state that I have read and understood the <p class="text-blue-600" routerLink=""> terms and conditions.</p></div>

<div class="inline-flex" >
  <button class="bg-gray-400 rounded px-3 py-2 m-2 shadow text-white" *ngIf="!isAgree">Agree</button>
  <button class="bg-blue-400 rounded px-3 py-2 m-2 shadow text-white" *ngIf="isAgree" (click)="agreeLogin(currentUser.uid)">Login</button>

</div>
<button class="bg-red-500 rounded px-3 py-2 m-2 shadow text-white" (click)="cancel()">cancel</button>
</div>
</div>

<div class="section md:m-10 md:p-10">
  <p class="text-3xl uppercase">My Account</p>

  <!-- Display User Information  -->
  <div class=" bg-white p-2 rounded shadow " *ngIf="user">
    <div class="text-2xl mt-4 font-medium">User Details</div>
    <div class="flex flex-row font-sans font-medium p-4 m-3 shadow-lg rounded w-fit">
      <div class="">
        <div class="flex flex-col">
          <div class="text-sm m-2 p-2 font-medium">First Name</div>
          <div class="text-sm m-2 p-2 font-medium">Email</div>
          <div class="text-sm m-2 p-2 font-medium">Phone</div>
        </div>
      </div>
      <div class="w-1/2">
        <div class="flex flex-col">
          <div class="text-sm m-2 p-2 font-medium">{{user.name}}</div>
          <div class="text-sm m-2 p-2 font-medium">{{user.email}}</div>
          <div class="text-sm m-2 p-2 font-medium">{{user.phoneNumber}}</div>
        </div>
      </div>
    </div>
    <!-- Billing Information -->
    <div *ngIf="!user.isAdmin">
    <div class="mt-4" *ngIf="user.validSubscription">
      <div class="text-2xl font-medium flex">
        Subscription Details
        <button routerLink="/raiseTicket" class="bg-orange-300 text-white font-medium rounded shadow m-2 p-2 ml-auto text-sm hover:scale-105">
          Raise Support Ticket
        </button>

        <div *ngFor="let element of subscription" >
          <div *ngIf="element.trialSubscription==true">
          <button class="bg-gray-900 text-white p-2 m-2 rounded shadow hover:bg-gray-700 font-medium  text-sm hover:scale-105"
           (click)="cancelTrialSubscription(element)" *ngIf="nosubscription==false">
           Cancel Trial Subscription</button>
        </div>
        </div>
          <button class="bg-gray-900 text-white p-2 m-2 rounded shadow hover:bg-gray-700 font-medium  text-sm hover:scale-105"
           routerLink="/subscription" *ngIf="nosubscription==true">
           Subscribe
          </button>
      </div>

      <div *ngFor="let item of subscription" class="">

        <div class="p-2 m-2" *ngIf="item.plan==0">
      <div *ngIf="item.trialSubscription==false">
      </div>

      </div>
      </div>

      <div *ngFor="let item of subscription" class="">
        <div *ngIf="item.trialSubscription==true" >
        <div class="m-2 p-2 bg-gray-100 hover:bg-gray-200 rounded shadow" *ngIf="item.endDate.toDate() > currentDate">
            <div class="p-2 m-2" *ngIf="item.plan==0" >
              <div class="text-center">Trial Pack has been Activated.</div>
              <div class="p-2 m-2">Plan : Trial Subscription</div>
              <div class="p-2 m-2">Valid Upto : {{item.endDate.toDate() | date:'dd/MM/yyy' }}</div>
              <div class="p-2 m-2">Subscribe to Unlock more features.
                You can Subscribe after Trial Plan.
              </div>
              </div>
          </div>
          </div>

          <div class="p-2 m-2" *ngIf="item.plan==1">
          <div class="m-2 p-2 bg-gray-100 hover:bg-gray-200 rounded shadow" *ngIf="item.endDate.toDate() > currentDate">

          <div class="p-2 m-2">Plan : Silver Subscription</div>
          <div class="p-2 m-2">Valid Upto : {{item.endDate.toDate() | date:'dd/MM/yyy' }}</div>
        </div>
        </div>
        <div class="p-2 m-2" *ngIf="item.plan==2">
          <div class="m-2 p-2 bg-gray-100 hover:bg-gray-200 rounded shadow" *ngIf="item.endDate.toDate() > currentDate">
          <div class="p-2 m-2">Plan : Gold Subscription</div>
          <div class="p-2 m-2">Valid Upto : {{item.endDate.toDate() | date:'dd/MM/yyy' }}</div>
        </div>
        </div>
        <div class="p-2 m-2" *ngIf="item.plan==3">
          <div class="m-2 p-2 bg-gray-100 hover:bg-gray-200 rounded shadow" *ngIf="item.endDate.toDate() > currentDate">
          <div class="p-2 m-2">Plan : Diamond Subscription</div>
          <div class="p-2 m-2">Valid Upto : {{item.endDate.toDate() | date:'dd/MM/yyy' }}</div>
        </div>
        </div>

      <!-- <div class="text-2xl">    Subscription Details    </div> -->
<div  *ngIf="currentDate>item.endDate.toDate() && item.plan==0 && item.plan==1 && item.plan==2 && item.plan==3">
      <div>User doesn't have a valid subscription.</div>
      <div class="flex">
        <button class="bg-gray-900 text-white p-2 m-2 rounded shadow hover:bg-gray-700 " routerLink="/subscription">Subscribe</button>
      </div>
    </div>


</div>
</div>
  </div>
</div>
