<div class="section md:m-10 md:p-10 mt-2">
  <div class=" mt-5">
    <div class="bg-white  shadow p-4 mt-5 text-center" *ngIf="gstRequest && businessDetail">
      <h2>
        {{gstRequest.gstType | gstRequestType}}
        - {{ gstRequest.month }}
        {{ gstRequest.timestamp.toDate() | date: "yyyy" }}
      </h2>
    </div>
  </div>
  <div class="">
    <div class="bg-white mt-2 shadow p-4" *ngIf="businessDetail">
      <div class="text-2xl font-bold">
        Business Details
      </div>
      <h5 class="text-blue-400 text-lg">{{ businessDetail.name }}</h5>
      <div class="card-body">
        <p class=" mb-2 text-muted">Address: {{ businessDetail.address }}</p>
        <p class="mb-2 text-muted">Email Address: {{ businessDetail.emailAddress }}</p>
        <p class=" mb-2 text-muted">PAN Card: {{ businessDetail.panCardNumber }}</p>
        <p class=" mb-2 text-muted">Phone Number: {{ businessDetail.phoneNumber }}</p>
      </div>

      <div> Status</div>
      <div class="flex mx-auto">
        <h6 class="statusbtn" (click)="updateNew(gstRequest)">
          <i class="text-blue-500  list-disc"
          [ngClass]="gstRequest.status==0? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          New
        </h6>
        <h6 class="statusbtn" (click)="updateSubmitted(gstRequest)">
          <i class="text-green-500  list-disc"
            [ngClass]="gstRequest.status==1? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
            SUBMITTED
        </h6>
        <h6 class="statusbtn" (click)="updateReview(gstRequest)">
          <i class="text-orange-500  list-disc"
          [ngClass]="gstRequest.status==2? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          REVIEW
        </h6>
        <h6 class="statusbtn" (click)="updateIncomplete(gstRequest)">
          <i class="text-red-500 list-disc"
          [ngClass]="gstRequest.status==3? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          INCOMPLETE
        </h6>
        <h6 class="statusbtn" (click)="updateInvalid(gstRequest)">
          <i class="text-red-500 list-disc"
          [ngClass]="gstRequest.status==4? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          INVALID
        </h6>
        <h6 class="statusbtn"  (click)="updateDelete(gstRequest)">
          <i class="text-red-500  list-disc"
          [ngClass]="gstRequest.status==5? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
          DELETED
        </h6>
      </div>
    </div>


  </div>

  <div class="bg-white mt-2 shadow p-4">
    <div>
      <h5 class="text-2xl font-bold">Sales Statements</h5>
    </div>
    <app-no-data *ngIf="saleStatements.length == 0"></app-no-data>
    <div class="grid grid-cols-3 gap-2 p-4 bg-blue-50 rounded overflow-y-visible"
      *ngFor="let statement of saleStatements">
      <div class="col">
        <div class="row">{{ statement.name }}</div>
        <div class="row">
          <img class="rounded w-1/2" src="{{ statement.fileUrl }}" alt="" />
        </div>
        <div class="row">
          <a target="_blank" rel="noopener noreferrer" download="{{ statement.fileUrl }}"
            href="{{ statement.fileUrl }}">
            <button class="btn btn-block btn-taxPrism-outline">
              Download
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
