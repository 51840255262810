import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import Toast from 'awesome-toast-component';
import { SubscriptionItem } from 'src/app/models/subscriptionItem';
import { SubscriptionTypeItem } from 'src/app/models/subscriptionTypeItem';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.css']
})
export class SubscriptionPlansComponent {

  formvalue: FormGroup;
  subscriptionItem: any;

  editSelectedItem : SubscriptionTypeItem = {};
  editbtn: boolean;

  constructor(
    private fs : FirebaseService
    ) { }

  ngOnInit(): void {

    this.formvalue=new FormGroup({
      planName          : new FormControl(),
      planType          : new FormControl(),
      description       : new FormControl(),
      baseValue         : new FormControl(),
      customerSubscriptionRate      : new FormControl(),
      numberOfCustomers : new FormControl(),
      subscriptionCost  : new FormControl(),
      gstPercent        : new FormControl(),
      gstValue          : new FormControl(),
      promotionalValue  : new FormControl(),
      discountyear  : new FormControl(),
      discountyears  : new FormControl(),
    });

    this.getSubscriptionItem()
  }

  createPost(){

    const subscriptionItem : SubscriptionTypeItem = {

  // name                      : this.formvalue.value.planName,
  type                      : this.formvalue.value.planType,
  description               : this.formvalue.value.description,
  numberOfCustomers         : this.formvalue.value.numberOfCustomers,
  baseValue                 : this.formvalue.value.baseValue,
  customerSubscriptionRate  : this.formvalue.value.customerSubscriptionRate,
  gstPercent                : this.formvalue.value.gstPercent,
  promotionalValue          : this.formvalue.value.promotionalValue,
  discountyear              : this.formvalue.value.discountyear,
  discountyears             : this.formvalue.value.discountyears
    }
    console.log(subscriptionItem)
    this.fs.createSubscriptionItem(subscriptionItem);
    new Toast(`Subscription Data Added Successfully..!!!`, {
      position: 'top'});
    this.formvalue.reset();
  }

  getSubscriptionItem(){
    this.fs.getSUbscriptionItem().subscribe(res=>{
      this.subscriptionItem = res;
      // console.log(this.subscriptionItem)
    })
  }

  edit(item:any){
    // console.log(item)
    this.editSelectedItem = item;
    this.editbtn=true;

    // this.formvalue.controls['name'].setValue(subscriptionItem.name);
    this.formvalue.controls['planType'].setValue(item.type);
    this.formvalue.controls['numberOfCustomers'].setValue(item.numberOfCustomers);
    this.formvalue.controls['baseValue'].setValue(item.baseValue);
    this.formvalue.controls['customerSubscriptionRate'].setValue(item.customerSubscriptionRate);
    this.formvalue.controls['gstPercent'].setValue(item.gstPercent);
    this.formvalue.controls['promotionalValue'].setValue(item.promotionalValue);
    this.formvalue.controls['discountyear'].setValue(item.discountyear);
    this.formvalue.controls['discountyears'].setValue(item.discountyears);
    this.formvalue.controls['description'].setValue(item.description);

  }

  updateSubscriptionDetails(){

    this.editSelectedItem.type = this.formvalue.value.planType;
    this.editSelectedItem.baseValue = this.formvalue.value.baseValue;
    this.editSelectedItem.numberOfCustomers = this.formvalue.value.numberOfCustomers;
    this.editSelectedItem.customerSubscriptionRate = this.formvalue.value.customerSubscriptionRate;
    this.editSelectedItem.gstPercent = this.formvalue.value.gstPercent;
    this.editSelectedItem.promotionalValue = this.formvalue.value.promotionalValue;
    this.editSelectedItem.discountyear = this.formvalue.value.discountyear;
    this.editSelectedItem.discountyears = this.formvalue.value.discountyears;
    this.editSelectedItem.description = this.formvalue.value.description;


    this.fs.updateSubscriptionItem(this.editSelectedItem).then(res => {
      // console.log(res)
      new Toast(`Project Details Updated Successfully.`, {
        position: 'top'});
        this.editbtn=false;
      this.formvalue.reset();

      // this.showNewProjectDialog = false;
    })
  }

    delete(selectedItem: SubscriptionTypeItem) {
      if (confirm("Are you sure you want to delete this Subscription Plan?")) {
        this.fs.deleteSubscriptionItem(selectedItem);
          new Toast("Subscription Item deleted successfully", { position: "top-right" });
       }
    }
}
