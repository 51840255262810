import { Component, OnInit } from '@angular/core';
import { DataService, FirebaseService } from 'src/app/services/services';
import { HttpClientModule } from '@angular/common/http';
import { map, switchMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Customer } from 'src/app/models/customer';
import { firstValueFrom } from 'rxjs';
import Toast from 'awesome-toast-component';

@Component({
  selector: 'app-it-request-detail',
  templateUrl: './it-request-detail.component.html',
  styleUrls: ['./it-request-detail.component.css']
})
export class ItRequestDetailComponent implements OnInit {
  itFilingRequest;
  customerDetail;
  businessDetail;
  bankStatements = [];
  loanStatements = [];
  insuranceStatements = [];
  rentalStatements = [];
  tdsStatements = [];

  iTreqid: string;

  constructor(private fs: FirebaseService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.iTreqid = String(params.get('id'));

        // console.log(this.iTreqid)
        return this.fs.getITRequest(this.iTreqid)
      })
    ).subscribe((result) => {

      this.itFilingRequest = result;
      // console.log(this.itFilingRequest)

      this.fs.getCustomer(this.itFilingRequest.userId).subscribe(cust => {
        this.customerDetail = cust;
        // console.log(this.customerDetail)
      });

      this.fs.getBusiness(this.itFilingRequest.businessId).subscribe(business => {
        this.businessDetail = business;
        // console.log(this.businessDetail)
      });

    })
      this.fs.getAllBankStatements(this.iTreqid).subscribe(bk => {
        this.bankStatements = bk;
        // console.log(bk)
      });

      this.fs.getAllLoanStatements(this.iTreqid).subscribe(loans => {
        this.loanStatements = loans;
        // console.log(this.loanStatements)
      });

      this.fs.getAllInsuranceStatements(this.iTreqid).subscribe(insurances => {
        this.insuranceStatements = insurances;
        // console.log(this.insuranceStatements)
      });

      this.fs.getAllPropertyStatements(this.iTreqid).subscribe(properties => {
        this.rentalStatements = properties;
        // console.log(this.rentalStatements)
      });

      this.fs.getAllTDSStatements(this.iTreqid).subscribe(tds => {
        this.tdsStatements = tds;
        // console.log(this.tdsStatements)
      });



    // // this.ds.currentIncomeTaxFilingRequest.subscribe(f => this.itFilingRequest = f);
    // this.fs.getCustomer(this.itFilingRequest.userId).subscribe(cust => this.customerDetail = cust);
    // this.fs.getBusiness(this.itFilingRequest.businessId).subscribe(business => this.businessDetail = business);
    // this.fs.getAllBankStatements(this.itFilingRequest.requestID).subscribe(bk => this.bankStatements = bk);
    // this.fs.getAllLoanStatements(this.itFilingRequest.requestID).subscribe(loans => this.loanStatements = loans);
    // this.fs.getAllInsuranceStatements(this.itFilingRequest.requestID).subscribe(insurances => this.insuranceStatements = insurances);
    // this.fs.getAllPropertyStatements(this.itFilingRequest.requestID).subscribe(properties => this.rentalStatements = properties);
    // this.fs.getAllTDSStatements(this.itFilingRequest.requestID).subscribe(tds => this.tdsStatements = tds);
  }

  updateNew(itFilingRequest) {
    if(window.confirm('Are you sure want to Change Status to New?')){
      this.fs.updateITRequestStatusNew(itFilingRequest);
      new Toast(`Status Changed to NEW.` , {
        position: 'top'});
    }
  }

  updateSubmitted(itRequestId){
    if(window.confirm('Are you sure want to submit IT Request?')){
      this.fs.updateITRequestStatusSubmitted(itRequestId);
      new Toast(`It Request Submitted` , {
        position: 'top'});
    }
  }

  updateReview(itRequestId){
    this.fs.updateITRequestStatusReview(itRequestId);
    new Toast(`It Request Under Review...` , {
      position: 'top'});
  }

  updateIncomplete(itRequestId){
    if(window.confirm('Set IT Request as Incomplete?')){
      this.fs.updateITRequestStatusIncomplete(itRequestId);
    }
  }

  updateInvalid(itRequestId){
    if(window.confirm('Set IT Request as Invalid?')){
    this.fs.updateITRequestStatusInvalid(itRequestId);
  }
  }

  updateDelete(itRequestId){
    if(window.confirm('Delete IT Request?')){
    this.fs.updateITRequestStatusDeleted(itRequestId);
    }
  }

}
