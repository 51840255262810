import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ETFUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  numberOfCustomers: number;
  currentActiveITRequests: number;
  currentActiveGSTRequests: number;
  currentActiveContactRequests: number;
  newCustomers: number;
  otherServiceRequest: number;
  user: ETFUser;
  userId: any;
  userEmail: any;
  customer: any;

  viewCustomer:boolean ;
  viewItReq : boolean= true;
  viewGstReq : boolean;
  viewAppoinments : boolean;

  currentUser:any;
  allCustomers:any;
// GST Req
  businesses: any;
  customerDetails: any[];
  customers: any;
  filteredGSTRequests:any;

// IT Req
  filteredItRequests: any;
  filterAllClass: any;
  filterdefaultClass: any;
  filterBusinessClass: any;
  filterIndividualClass: any;

  showNewUserDialog:boolean=true;

  constructor(public afAuth: AngularFireAuth, private router: Router, private fs: FirebaseService,public authService: AuthService) {
    if (afAuth.authState == null) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.afAuth.authState.subscribe(result=>{
      if(result!==null){
      this.fs.getUserData().then((res) => {
        firstValueFrom(res).then((currentUser) => {
            this.currentUser = currentUser as ETFUser;
            this.userId=this.currentUser.uid;
        })
      })
      }})
      this.getItRequests();
      this.getGstRequests();
    // this.fs.getUserData().then((res) => {
    //   res.subscribe((user) => {

    //     this.user = user as ETFUser;
    //     this.userId=this.user.uid;
    //     this.userEmail=this.user.uid;


//     this.numberOfCustomers = 0;
//     this.currentActiveITRequests = 0;
//     this.currentActiveGSTRequests = 0;
//     this.currentActiveContactRequests = 0;
//     this.newCustomers = 0;
//     this.otherServiceRequest = 0;

//     let allCustomers = [];

//     this.fs.getAllCustomersDetails(this.userId).subscribe(customers => {
//       this.numberOfCustomers = customers.length;
//       allCustomers = customers.filter(this.customer , this.customer.newUser === true);
//       this.newCustomers = allCustomers.length;
//     });

//     this.fs.getAllItRequests(false).subscribe(itreqs => this.currentActiveITRequests = itreqs.length);
//     this.fs.getAllGstRequests().subscribe(gstReq => this.currentActiveGSTRequests = gstReq.length);
//     this.fs.getAllContactUsRequest().subscribe(cRequests => {
//       this.currentActiveContactRequests = cRequests.length;

//     });

  // });
// });

}

// getCustomers(){
//   var customer=[]
//   this.fs.getAllCustomersDetails(this.userId).subscribe(res => {
//     customer = res
//     this.allCustomers = customer.filter(customer => customer.newUser === true);
//     // console.log(this.allCustomers)
//   });
// }

async getItRequests(){
  await this.fs.getBusinesses().subscribe(allBus => {
    this.businesses = allBus
  });
  this.filterAllClass = this.filterdefaultClass;
  this.authService.getUser().then(res=>{
    this.user = res as ETFUser;
    this.userId=this.user.uid;
    this.customerDetails=[]
    var userCustomerItReq=[]
// gets customer details with current user
 firstValueFrom(this.fs.getAllCustomersDetails(this.userId)).then(data=>{
  this.customers=data;
// looping and pushing the data to array
  for(let i=0;i<this.customers.length;i++){
    const UCD = this.customers[i]
    this.customerDetails.push(UCD.uid)
// getting all It requests with customers uid
    firstValueFrom(this.fs.getlimitedItRequests(false)).then(res=>{
      // console.log(res)
// looping thriugh the data
      for(let j=0;j<res.length;j++){
        const item=res[j]
// comparing user customer's uid with Itrequest UserId
        if(UCD.uid==item.userId){
        // console.log(item)
        userCustomerItReq.push(item)
      }
    }
      // console.log(userCustomerItReq)
        userCustomerItReq.forEach(
                req => {
                  // console.log(req)
                  if (req.itRequestType === 0) {

                    req.name = (this.customers.find(c => c.uid === req.userId).name);

                  }
                  else {
                    req.name = (this.businesses.find(c => c.id === req.businessId).name);
                  }
                }
              );
    });
  }
  this.filteredItRequests = userCustomerItReq;
// console.log(userCustomerItReq)
  }
  );
});
}

getGstRequests(){
    firstValueFrom(this.fs.getBusinesses())
      .then(businesses => this.businesses = businesses);
    this.authService.getUser().then(res=>{
      this.user = res as ETFUser;
      this.userId=this.user.uid;

      // console.log(this.userId)

      this.customerDetails=[]
      var userCustomerGstReq=[]

  // gets customer details with current user
   firstValueFrom(this.fs.getAllCustomersDetails(this.userId)).then(data=>{
    this.customers=data;

    // console.log(this.customers);

  // looping and pushing the data to array
    for(let i=0;i<this.customers.length;i++){
      const UCD = this.customers[i]
      // console.log(UCD)
      this.customerDetails.push(UCD.uid)


  // getting all It requests with customers uid
      firstValueFrom(this.fs.getLimitedGstRequests(UCD.uid)).then(res=>{
        // console.log(res)

  // looping thriugh the data
        for(let j=0;j<res.length;j++){
          const item=res[j]
          // console.log(UCD.uid)
          // console.log(item)
  // comparing user customer's uid with Itrequest UserId
          if(UCD.uid==item.userId){
          // console.log(item)
          userCustomerGstReq.push(item)
        }
      }
        // console.log(userCustomerItReq)
          userCustomerGstReq.forEach(
            gst => {
              gst.name = (
                this.businesses
                .find(b => b.id === gst.businessId).name);

            }

          );

      });
    };
    });
     this.filteredGSTRequests = userCustomerGstReq;
    //  console.log(this.filteredGSTRequests)
  })
}


// TODO
getAppointments(){

}

goto(url:string){
  window.location.href=url;
}

viewGstRequest(item: any) {
  this.router.navigate(['gstRequestDetail', { id: item.requestId }]);
}

viewIncomeTaxFilingRequest(incometaxfiling: any) {
  this.router.navigate(['itRequestDetail', { id: incometaxfiling.requestID }]);
}
}
