<div class="sectionMobile md:m-6 md:p-6 mt-2">

    <div class="text-2xl shadow p-2 text-center">
      <h2>CUSTOMERS TICKETS</h2>
    </div>

  <div class="m-2 p-6">
    <div class="grid grid-cols-1 md:grid-cols-4 md:gap-4 font-medium p-2 bg-slate-500 text-white rounded shadow" >
      <div class="flex mx-auto">CUSTOMER NAME</div>
      <div class="flex mx-auto">ISSUE</div>
      <div class="flex mx-auto">CUSTOMER TICKET</div>
      <div class="flex mx-auto">STATUS</div>
    </div>

    <div class="m-3 p-3 shadow rounded" *ngFor="let ticket of tickets">
    <div class="grid grid-cols-1 md:grid-cols-4 md:gap-4 font-medium m-2 p-2" >

        <div class="mx-auto text-lg font-medium">   {{ticket.name || ticket.email}}  </div>
        <div class="flex mx-auto text-red-500">    {{ticket.title}}    </div>

        <div>
              <button class=" btn flex mx-auto" (click)="ticket.textTicket = !ticket.textTicket">
                <i *ngIf="!ticket.textTicket " class="fa-solid fa-eye"></i>
                <i *ngIf="ticket.textTicket " class="fas fa-eye-slash"></i>
              </button>
        </div>

        <div class="mx-auto" *ngIf="ticket.name">
            <div class="flex ">
                <h6 class="mr-2">
                  <i class="text-blue-500 p-2 list-disc" (click)="updatePending(ticket)"
                    [ngClass]="ticket.status==0? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
                  New
                </h6>
                <h6 class="mr-2">
                  <i class="text-green-500 p-2 list-disc" (click)="updateOpen(ticket)"
                    [ngClass]="ticket.status==1? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
                  Opened
                </h6>
                <h6 class="mr-2">
                  <i class="text-orange-500 p-2 list-disc" (click)="updateClosed(ticket)"
                    [ngClass]="ticket.status==2? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
                  Closed
                </h6>
                <h6>
                  <i class="text-red-500 p-2 list-disc" (click)="updateDeferred(ticket)"
                    [ngClass]="ticket.status==3? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
                  Deferred
                </h6>
            </div>
              <small class="text-sm">({{ticket.date.toDate() | date:'dd/MM/yyyy' }})</small>
        </div>

        <div class="mx-auto" *ngIf="!ticket.name">
            <div class="bg-red-600 p-2 m-2 mx-auto px-3 text-white rounded shadow hover:bg-red-700 font:medium w-fit">
            <i class="fa-solid fa-trash"></i>
              Delete Status
            </div>
            <small class="text-sm">({{ticket.date.toDate() | date:'dd/MM/yyyy' }})</small>
        </div>
    </div>



      <div class=" p-4 grid w-full bg-white shadow mx-auto" *ngIf="ticket.textTicket">
        <div class="bg-blue-800 md:m-3 md:p-3 p-2 m-1 text-white text-lg rounded shadow-md" >{{ticket.textTicket}}
        <div class="text-right w-fit text-xs bg-gray-100 text-black p-1 rounded-sm">{{ticket.date.toDate() | date:'dd/MM/yyyy, h:mm a'}}</div>
      </div>
    </div>
  </div>
  </div>
</div>

   <!-- <div *ngIf="showTicket" class="flex fixed top-0 h-screen w-screen bg-gray  justify-center-center bg-gray-100">
    <div  class="shadow p-6 rounded w-full top-60">

        <div class="flex font-medium text-lg" >CUSTOMER TICKET
          <button type="submit" (click)="showTicket=false" class="ml-auto">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

      <div *ngFor="let ticket of tickets" >
        <div *ngIf="ticket.status==1">
        <div *ngIf="ticket.userId!=null">
          <div class="m-10 p-10 rounded shadow">
        <div class="flex mx-auto text-lg  hover:bg-white">{{ticket.textTicket}}
          </div>
          <div class="flex ml-auto bg-slate-500 rounded shadow text-white p-2 m-2 mt-2 w-fit">
            <i class="text-blue-500 p-2 list-disc " (click)="updateClosed(ticket.id)"
              [ngClass]="ticket.status==2? 'fa-solid fa-circle-check':'fa-regular fa-circle'"></i>
              <button>Close Ticket</button>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  </div> -->
