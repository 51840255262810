import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from 'firebase/app';


function _window(): any {
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  get nativeWindow(): any {
    return _window();
  }


  httpOptions = {
    headers: new HttpHeaders({ 'Access-Control-Allow-Origin': 'true', 'Content-Type': 'application/json; charset=utf-8', })
  };

  constructor() { }

  createOrder(reciept: string, amount: number): any {
    // console.log(amount)
    const functions = getFunctions(getApp(), 'asia-south1');
    // console.log(functions);
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    const createOrder = httpsCallable(functions, 'createOrder');

    return createOrder({ reciept, amount }).then(result => {
      const data: any = result.data;
      return data.order.id;
    }).catch(error => {
      console.log(error);
    });
  }

}
