<div class="section md:m-10 md:p-10 mt-2">
  <div class="mt-5">
    <div class="text-2xl shadow p-2 text-center w-full">
      <h2>ALL APPOINTMENTS</h2>
    </div>
  </div>
  <div class=" mt-2 w-100 justify-content-center shadow">
    <div class="flex gap-2 mb-auto">
      <div class="p-4">
        <span>Filter</span>
      </div>

      <div class=" mt-2">
        <input type="text" class="form-control p-2 rounded shadow" placeholder="Search" aria-label="Search"
        aria-describedby="button-addon2" [(ngModel)]="searchText" />
        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="search()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="mt-2 bg-white p-4 shadow rounded  text-lg uppercase">
    <div class="grid grid-cols-1 md:grid-cols-6 gap-4 w-100">
      <div class="mx-auto">Date</div>
      <div class="mx-auto">Slot Number</div>
      <div class="mx-auto">Name</div>
      <div class="mx-auto">Phone Number</div>
      <div class="mx-auto">Email Address</div>
    </div>
  </div>
  <div class="mt-2 bg-white p-4 shadow rounded hover:bg-slate-100" *ngFor="let appointment of appointments">
    <div class="grid grid-cols-1 md:grid-cols-6 gap-4 w-100">
      <div class="mx-auto">
        {{ appointment.appointment.toDate() | date: "dd-MM-yyyy" }}
      </div>
      <div class="mx-auto">
        {{ appointment.slotNumber | slotNumber }}
      </div>
      <div class="mx-auto">
        {{ appointment.name }}
      </div>
      <div class="mx-auto">
        {{ appointment.phoneNumber }}
      </div>
      <div class="mx-auto">
        {{ appointment.email }}
      </div>
      <div class="col d-flex justify-content-end mx-auto" *ngIf="!appointment.completed">
        <button class="btn btn-taxPrism-outline" (click)="setComplete(appointment)">
          COMPLETED
        </button>
      </div>
      <div class="col d-flex justify-content-end mx-auto" *ngIf="appointment.completed">
        <p>APPOINTMENT COMPLETE</p>
      </div>
    </div>
  </div>
</div>
