import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../services/services';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

import Toast from 'awesome-toast-component';
import { ETFUser } from 'src/app/models/user';
import { Customer } from 'src/app/models/customer';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
})
export class CustomersComponent implements OnInit {

  public customer;
  filtered = [];
  searchText;

  showRegisterButton:boolean;
  showUpdateButton:boolean;
  formvalue: FormGroup;

  showNewUserDialog:boolean=false;
  user: ETFUser;
  userId: string;

  showEmail:boolean=false;
  userEmail: string;
  allCustomers: any;
  email:string;
  selectedCustomer: any;

  subscription:any;

  constructor(
    private fs: FirebaseService,
    private router: Router, private formbuilder : FormBuilder,private authService:AuthService
  ) { }


  ngOnInit(): void {
    this.showRegisterButton=true;
      this.showUpdateButton=false;

    this.formvalue=new FormGroup({

      aadharCardNumber  : new FormControl(),
      address           : new FormControl(),
      dateofBirth       : new FormControl(),
      email             : new FormControl(),
      loginId           : new FormControl(),
      loginPwd          : new FormControl(),
      name              : new FormControl(),
      newUser           : new FormControl(),
      panCardNumber     : new FormControl(),
      phoneNumber       : new FormControl(),
      socialPhotoUrl    : new FormControl(),
       detail            : new FormControl(),

       userID            : new FormControl(),
       verified          : new FormControl()
    })
    this.fs.getUserData().then((res) => {
      res.subscribe((user) => {

        this.user = user as ETFUser;
        this.userId=this.user.uid;
        this.userEmail=this.user.uid;

     this.fs.getAllCustomersDetails(this.userId).subscribe(data=>{
      this.customer=data;
      this.filtered=data;
      // console.log(this.filtered)
      });
      this.getSubscriptionDetails(this.userId);

    })
  })
  }

  viewHistory(customer: any) {
    this.router.navigate(['customer-detail', { id: customer.uid }]);
  }

  search() {
    this.filtered = this.customer
      .filter(customer => customer.name
        .toUpperCase()
        .includes(this.searchText
          .toUpperCase()));
  }

  setAsVerified(customer) {
    if (confirm("Approve customer?")) {
      customer.newUser = false;
      const d = 'detail';
      delete customer[d];
      this.fs.updateCustomer(customer);
      new Toast(`Customer Verified` , {
        position: 'top'});
    }
  }

  newUser(){
    const customerData={
      name             : this.formvalue.value.name,
      email            : this.formvalue.value.email,
      loginId          : this.formvalue.value.loginId,
      loginPwd         : this.formvalue.value.loginPwd,
      aadharCardNumber : this.formvalue.value.aadharCardNumber,
      panCardNumber    : this.formvalue.value.panCardNumber,
      phoneNumber      : this.formvalue.value.phoneNumber,
      address          : this.formvalue.value.address,
      dateofBirth      : new Date(this.formvalue.value.dateofBirth),
      socialPhotoUrl   : this.formvalue.value.socialPhotoUrl,

      newUser : true,
      verified : false,
      detail:true,
      userID:this.fs.currentUserId
    }

    this.fs.createNewCustomerDetails(customerData);
    new Toast(`New Customer Added Successfully..!!!`, {
      position: 'top'});
    this.formvalue.reset();
    this.showNewUserDialog=false;
  }

editCustomerDetails(item:any){
    this.showNewUserDialog=true;
    this.showRegisterButton=false;
    this.showUpdateButton=true;
    this.customer=item;
    this.formvalue.controls['name'].setValue(item.name);
    this.formvalue.controls['email'].setValue(item.email);
    this.formvalue.controls['loginId'].setValue(item.loginId);
    this.formvalue.controls['loginPwd'].setValue(item.loginPwd);
    this.formvalue.controls['aadharCardNumber'].setValue(item.aadharCardNumber);
    this.formvalue.controls['panCardNumber'].setValue(item.panCardNumber);
    this.formvalue.controls['phoneNumber'].setValue(item.phoneNumber);
    this.formvalue.controls['address'].setValue(item.address);
    this.formvalue.controls['dateofBirth'].setValue(new Date(item.dateofBirth));
}

updateCustomerDetails(){
  this.customer.name=this.formvalue.value.name;
  this.customer.email=this.formvalue.value.email;
  this.customer.loginId=this.formvalue.value.loginId;
  this.customer.loginPwd=this.formvalue.value.loginPwd;
  this.customer.aadharCardNumber=this.formvalue.value.aadharCardNumber;
  this.customer.panCardNumber=this.formvalue.value.panCardNumber;
  this.customer.phoneNumber=this.formvalue.value.phoneNumber;
  this.customer.address=this.formvalue.value.address;
  this.customer.dateofBirth=new Date(this.formvalue.value.dateofBirth);

  this.fs.updateCustomerDetails(this.customer).then(()=>{
    new Toast(`Customer Profile Updated Successfully..!!!`, {
      position: 'top'});
    this.formvalue.reset();
    this.showNewUserDialog=false;
  })
}

cancel(){
  this.showNewUserDialog=false
  this.showRegisterButton=true;
  this.showUpdateButton=false;
  this.showEmail=false;
  this.formvalue.reset();


}

showNewEmailDialog(){
  this.showEmail=true;
  this.email=''
  this.fs.getAllCustomers().subscribe(customers=>{
    this.allCustomers=customers;
    // console.log(this.allCustomers)

  })
}

addNewCustomerByEmail(email){
  var selectedCustomer=null;
  this.showEmail=false;
for(let index=0;index<this.allCustomers.length;index++){
const customer=this.allCustomers[index] as Customer;

// console.log(this.allCustomers)

if(email === customer.email){
  selectedCustomer=customer;
  break;
}
}
if(selectedCustomer!==null){

  selectedCustomer.userID=this.fs.currentUserId;
    this.fs.addCustomerwithEmail(selectedCustomer).then(()=>{
    new Toast(`Added New Customer` , {
      position: 'top'});
      })

}else{
  new Toast(`Customer Not Found` , {
    position: 'top'});
}
}

getSubscriptionDetails(userId){
  this.fs.getSubscriptionDetails(userId).subscribe(res=>{
  this.subscription=res;
  // console.log(res)
  })
}

}
