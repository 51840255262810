
  <div class=" bg-gray-100 m-6 p-6 rounded shadow">

    <div class=" text-2xl underline font-sans font-bold  p-2 m-2">
      Terms and Conditions
    </div>
    <p>
      Please read these terms and conditions carefully. By using Easy Tax Filing, you agree to be bound by these terms and conditions.
      If you do not agree to these terms and conditions, you may not use the service.
    </p>

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Subscription</div>
    <p>
    Easy Tax Filing offers a subscription plan. Payment must be made in advance for each month of service.
    Subscription can be cancelled at any time, but no refunds will be issued for unused portions of the subscription period.
</p>
<div class=" text-2xl font-semibold font-sans  p-2 m-2">User Responsibilities</div>
    Users are responsible for ensuring that the information they provide to their auditor is accurate and complete. Easy Tax Filing is not responsible for any errors or omissions in tax filings prepared using the service. Users are responsible for maintaining the security of their login credentials and for any actions taken using their account.

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Disclaimer</div>
    Easy Tax Filing does not provide tax advice or legal advice. Users are advised to consult with a tax or legal professional for advice specific to their situation. Easy Tax Filing is not responsible for any damages, including but not limited to direct, indirect, incidental, punitive, and consequential damages, arising from the use of the service or inability to use the service.

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Limitation of Liability</div>
Easy Tax Filing will not be liable for any damages, including but not limited to direct, indirect, incidental, punitive, and consequential damages, arising from the use of the service or inability to use the service.

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Changes to Terms and Conditions</div>
Easy Tax Filing reserves the right to modify these terms and conditions at any time. Users will be notified of any changes to the terms and conditions via email or other communication method.

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Service Availability</div>
Easy Tax Filing will use reasonable efforts to make the service available 24 hours a day, 7 days a week, except for planned maintenance or unscheduled downtime. Easy Tax Filing reserves the right to modify or discontinue the service at any time, with or without notice.

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Data Privacy</div>
Easy Tax Filing takes data privacy seriously and is committed to protecting your personal information. We collect and use your personal information in accordance with our privacy policy, which is available here.

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Intellectual Property</div>
Easy Tax Filing and its licensors own all intellectual property rights in the service, including but not limited to copyrights, trademarks, and trade secrets. Users may not reproduce, distribute, or create derivative works of the service without our prior written consent.

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Governing Law and Jurisdiction</div>
These terms and conditions shall be governed by and construed in accordance with the laws of India. Any dispute arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.

    <div class=" text-2xl font-semibold font-sans  p-2 m-2">Entire Agreement</div>
These terms and conditions constitute the entire agreement between you and Easy Tax Filing with respect to the use of the service. These terms and conditions supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and Easy Tax Filing with respect to the use of the service.


</div>


