import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Toast from 'awesome-toast-component';
import { TicketStatus } from 'src/app/models/enums';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent {

  email: string;
  formvalue:FormGroup;

  constructor(private fs:FirebaseService, private router:Router){}

  ngOnInit(){
    this.formvalue=new FormGroup({
      email:new FormControl(),
      textTicket:new FormControl()
    });
  }

  reqDelete(){

    const data={
      email:this.formvalue.value.email,
      date:new Date(),
      textTicket:this.formvalue.value.textTicket,
      title:"Delete My Account",
      status:TicketStatus.new
    }

  this.fs.addSupportTicket(data).then(res=>{

    if(confirm('Are you Sure?')){
    new Toast(`Your Account Will be Deleted within 30 days`, {
      position: 'top'});
    this.formvalue.reset()
      this.router.navigate['']
  }
  })

  }
}

