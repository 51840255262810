<div class="section md:m-10 md:p-10  font-medium">
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
    <div class=" text-justify">

      <div>
        <div class="font-semibold text-4xl p-2">
          Easy Tax Filing: Your Auditor's Partner
        </div>

        <div class="m-5">
          Easy Tax Filing understands the importance of data security and takes it very seriously.
          In line with this, if you decide to cancel your subscription, we will delete all of your data within 30 days of your request.
          This gives you peace of mind knowing that your information will no longer be stored with us. Furthermore, we store your data on secure servers and protect it with encryption to ensure that it remains safe at all times.
          We want our clients to feel confident in using our service and trust that their information is secure with us.
          At Easy Tax Filing, we are committed to providing a high level of security for our clients' data.  </div>
      </div>
      <div>

</div>
</div>

    <div class="flex mx-auto">


        <div class="m-6 p-6 bg-gray-100 rounded shadow hover:bg-gray-200">
          Request To Delete Your Account<div>
            <form [formGroup]="formvalue" class="md:mt-10 w-full">
            <input type="email" autocomplete="email" id="email" name="email" placeholder="Enter Your Email-ID" class="p-2 m-2 flex mx-auto" formControlName="email">

            <textarea class="p-2 m-2 flex mx-auto" placeholder="Reason for Deleting? (optional)" formControlName="textTicket"></textarea>

            <button (click)="reqDelete()"
            class="bg-red-600 p-2 m-2 rounded shadow text-white flex mx-auto md:mt-10 hover:bg-red-700 ">DELETE MY DATA</button>
          </form>
        </div>
    </div>

    </div>
  </div>
</div>


