import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ETFUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { firstValueFrom } from 'rxjs'
import Toast from 'awesome-toast-component';
import { PaymentService } from 'src/app/services/payment.service';
import { Invoice } from 'src/app/models/subscriptionItem';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent {

  startDate = new Date();
  endDate = new Date();
  user: ETFUser;
  currentUser: any;
  rzp1;
  invoiceId: '';
  invoiceTotal=999;
  inoviceDate: any;
  userInvoice: Invoice;

  paymentId = "";
  error: string;
  message: string;
  customerInvoice: any;

  month : boolean;
  year  : boolean;
  twoYears : boolean=true;

  subscriptionItem : any;

  subscriptionSilverPromotional:   any;
  subscriptionGoldPromotional:     any;
  subscriptionDiamondPromotional:  any;

  subscriptionSilveroneYearPromotional  : any;
  subscriptionGoldoneYearPromotional    : any;
  subscriptionDiamondoneYearPromotional : any;

  subscriptionSilverfortwoYearsPromotional :  any;
  subscriptionGoldfortwoYearsPromotional   :  any;
  subscriptionDiamondfortwoYearsPromotional:  any;
  planSilver: any;
  planGold: any;
  planDiamond: any;

  // befor Discount
  silverOneYear   : number;
  goldOneYear     : number;
  diamondOneYear  : number;

  silverTwoYears  : number;
  goldTwoYears    : number;
  diamondTwoYears : number;

// after Discount
  subscriptionSilverOneYear   :number;
  subscriptionGoldOneYear     :number;
  subscriptionDiamondOneYear  : number;

  subscriptionSilverTwoYears  : number;
  subscriptionGoldTwoYears    : number;
  subscriptionDiamondTwoYears : number;

// Savings
  diamondSavingOneYear : number;
  goldSavingOneYear    : number;
  silverSavingOneYear  : number;

  silverSavingTwoYear  : number;
  goldSavingTwoYear    : number;
  diamondSavingTwoYear : number;
  subscription: any;
  userId: any;

  item:any

  constructor(
    private fs: FirebaseService,
    private authService: AuthService,
    private router: Router,
    private payService: PaymentService,
    // public decimalPipe: DecimalPipe
    ) { }

  ngOnInit(): void {
    this.fs.getUserData().then((res) => {

      firstValueFrom(res).then((user) => {

        this.user = user as ETFUser;
        this.userId=this.user.uid;
        this.getSubscriptionDetails(this.userId);
        this.getSubscriptionDetailsOfCurrentUser();
        this.getSubscriptionItem();
      })
    })
  }

  getSubscriptionItem(){
    this.fs.getSUbscriptionItem().subscribe(res=>{
      // console.log(res)
      this.subscriptionItem=res;
      for(let i=0;i<this.subscriptionItem.length;i++){
        var item=this.subscriptionItem[i]
        if(item.type==1){
          this.subscriptionSilverPromotional            = Math.round(item.promotionalValue + (item.promotionalValue * item.gstPercent/100));
          this.subscriptionSilveroneYearPromotional     = Math.round(item.promotionalValue*12 + (item.promotionalValue * 12 * item.gstPercent/100));
          this.subscriptionSilverfortwoYearsPromotional = Math.round(item.promotionalValue*24 + (item.promotionalValue * 24 * item.gstPercent/100));

           // before Discount
           this.silverOneYear = Math.round(( ((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
           (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) )*12);

           this.silverTwoYears= Math.round((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
           (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24);

           // after Discount
           this.subscriptionSilverOneYear = Math.round(( (((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100) +
           (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12) - (((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
             (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12)*item.discountyear/100));

             this.subscriptionSilverTwoYears= Math.round(
               ((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100) +
                   (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24) -
                   (((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                     (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24)*item.discountyears/100));

           // saving
             this.silverSavingOneYear = Math.round((((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                                         (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12)*item.discountyear/100) );

             this.silverSavingTwoYear = Math.round((((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                                         (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24)*item.discountyears/100)  );

          this.planSilver = item.type
        }

        if(item.type==2){
          this.subscriptionGoldPromotional            = Math.round(item.promotionalValue + (item.promotionalValue * item.gstPercent/100));
          this.subscriptionGoldoneYearPromotional     = Math.round(item.promotionalValue*12 + (item.promotionalValue * 12 * item.gstPercent/100));
          this.subscriptionGoldfortwoYearsPromotional = Math.round(item.promotionalValue*24 + (item.promotionalValue * 24 * item.gstPercent/100));

          // before Discount
          this.goldOneYear = Math.round(( ((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
          (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) )*12);

          this.goldTwoYears= Math.round((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
          (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24);

          // after Discount
          this.subscriptionGoldOneYear = Math.round(( (((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100) +
          (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12) - (((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
            (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12)*item.discountyear/100));

            this.subscriptionGoldTwoYears= Math.round(
              ((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100) +
                  (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24) -
                  (((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                    (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24)*item.discountyears/100));

          // saving
            this.goldSavingOneYear = Math.round((((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                                        (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12)*item.discountyear/100) );

            this.goldSavingTwoYear = Math.round((((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                                        (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24)*item.discountyears/100)  );

          this.planGold = item.type
        }

        if(item.type==3){
          this.subscriptionDiamondPromotional            = Math.round(item.promotionalValue + (item.promotionalValue * item.gstPercent/100));
          this.subscriptionDiamondoneYearPromotional     = Math.round(item.promotionalValue* 12 + (item.promotionalValue * 12 * item.gstPercent/100));
          this.subscriptionDiamondfortwoYearsPromotional = Math.round(item.promotionalValue* 24 + (item.promotionalValue * 24 * item.gstPercent/100));

          // before Discount
          this.diamondOneYear = Math.round(( ((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
          (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) )*12);

          this.diamondTwoYears= Math.round((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
          (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24);

          // after Discount
          this.subscriptionDiamondOneYear = Math.round(( (((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100) +
          (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12) - (((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
            (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12)*item.discountyear/100));

            this.subscriptionDiamondTwoYears= Math.round(
              ((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100) +
                  (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24) -
                  (((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                    (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24)*item.discountyears/100));

          // saving
            this.diamondSavingOneYear = Math.round((((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                                        (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*12)*item.discountyear/100) );

            this.diamondSavingTwoYear = Math.round((((((item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )) * item.gstPercent/100)+
                                        (item.baseValue + (item.numberOfCustomers * item.customerSubscriptionRate )))*24)*item.discountyears/100)  );

        this.planDiamond = item.type;
        }

      }
    })
  }
private updateSubscription(uid:string,status:boolean){
    this.fs.updateSubscripction(uid,status).then(()=>{
      new Toast(`Subscription done..!!` , {
        position: 'top'});
        this.router.navigate(['/myaccount']);
})
  }
  private getSubscriptionDetailsOfCurrentUser() {
    this.fs.getSubscriptionDetails(this.user.uid).subscribe(res=>{
      // console.log(res)
    })
  }

// -----------------Payment per month ----------------------
getSilver(){
  this.payService.createOrder(this.invoiceId, this.subscriptionSilverPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionSilverPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };

options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 30);

  var subscriptionPlan={
              name        : this.user.name,
              email       : this.user.email,
              startDate   : this.startDate,
              endDate     : this.endDate,
              amount      : options.amount/100,
              isActive    : true,
              userId      : this.fs.currentUserId,
              razorpayid  : response.razorpay_payment_id,
              orderId     : options.order_id,
              plan        : this.planSilver,
              trialSubscription: false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive);

              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}
getGold(){
  this.payService.createOrder(this.invoiceId, this.subscriptionGoldPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionGoldPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };

options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 30);

  var subscriptionPlan={
              email       : this.user.email,
              name        : this.user.name,
              startDate   : this.startDate,
              endDate     : this.endDate,
              amount      : options.amount/100,
              isActive    : true,
              userId      : this.fs.currentUserId,
              razorpayid  : response.razorpay_payment_id,
              orderId     : options.order_id,
              plan        : this.planGold,
              trialSubscription: false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)
              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}
getDiamond(){
  this.payService.createOrder(this.invoiceId, this.subscriptionDiamondPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionDiamondPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };
// console.log(options)
options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 30);

  var subscriptionPlan={
              email             :  this.user.email,
              name              : this.user.name,
              startDate         :  this.startDate,
              endDate           :  this.endDate,
              amount            :  options.amount/100,
              isActive          :  true,
              userId            :  this.fs.currentUserId,
              razorpayid        :  response.razorpay_payment_id,
              orderId           :  options.order_id,
              plan              :  this.planDiamond,
              trialSubscription : false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)
              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}
// -----------------Payment per Year ----------------------
getSilveroneYear(){
  this.payService.createOrder(this.invoiceId, this.subscriptionSilveroneYearPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionSilveroneYearPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };

options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 365);

  var subscriptionPlan={
              name        : this.user.name,
              email       : this.user.email,
              startDate   : this.startDate,
              endDate     : this.endDate,
              amount      : options.amount/100,
              isActive    : true,
              userId      : this.fs.currentUserId,
              razorpayid  : response.razorpay_payment_id,
              orderId     : options.order_id,
              plan        : this.planSilver,
              trialSubscription: false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)
              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}
getGoldoneYear(){
  this.payService.createOrder(this.invoiceId, this.subscriptionGoldoneYearPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionGoldoneYearPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };

options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 365);

  var subscriptionPlan={
              name        : this.user.name,
              email       : this.user.email,
              startDate   : this.startDate,
              endDate     : this.endDate,
              amount      : options.amount/100,
              isActive    : true,
              userId      : this.fs.currentUserId,
              razorpayid  : response.razorpay_payment_id,
              orderId     : options.order_id,
              plan        : this.planGold,
              trialSubscription: false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)
              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}
getDiamondoneYear(){
  this.payService.createOrder(this.invoiceId, this.subscriptionDiamondoneYearPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionDiamondoneYearPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };
// console.log(options)
options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 365);

  var subscriptionPlan={
              name        : this.user.name,
              email       : this.user.email,
              startDate   : this.startDate,
              endDate     : this.endDate,
              amount      : options.amount/100,
              isActive    : true,
              userId      : this.fs.currentUserId,
              razorpayid  : response.razorpay_payment_id,
              orderId     : options.order_id,
              plan        : this.planDiamond,
              trialSubscription: false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)
              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}

// -----------------Payment for 24months ----------------------
getSilverfortwoYears(){
  this.payService.createOrder(this.invoiceId, this.subscriptionSilverfortwoYearsPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionSilverfortwoYearsPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };

options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 730);

  var subscriptionPlan={
              name        : this.user.name,
              email       : this.user.email,
              startDate   : this.startDate,
              endDate     : this.endDate,
              amount      : options.amount/100,
              isActive    : true,
              userId      : this.fs.currentUserId,
              razorpayid  : response.razorpay_payment_id,
              orderId     :  options.order_id,
              plan        : this.planSilver,
              trialSubscription: false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)
              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}
getGoldfortwoYears(){
  this.payService.createOrder(this.invoiceId, this.subscriptionGoldfortwoYearsPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionGoldfortwoYearsPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };

options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 730);

  var subscriptionPlan={
              name        : this.user.name,
              email       : this.user.email,
              startDate   : this.startDate,
              endDate     : this.endDate,
              amount      : options.amount/100,
              isActive    : true,
              userId      : this.fs.currentUserId,
              razorpayid  : response.razorpay_payment_id,
              orderId     : options.order_id,
              plan        : this.planGold,
              trialSubscription: false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)
              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}
getDiamondfortwoYears(){
  this.payService.createOrder(this.invoiceId, this.subscriptionDiamondfortwoYearsPromotional).then( result => {
    const orderId = result;

    const options: any = {
      "key": "rzp_test_H65zWOjz5VUU4M", // Enter the Key ID generated from the Dashboard
      "amount": this.subscriptionDiamondfortwoYearsPromotional*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "DevelopSolutions", //your business name
      "description": "Subscription Plan for Current Month",
      "image": "assets/images/logo.png",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
      "prefill": {
        "name": this.user.name, //your customer's name
        "email": this.user.email,
        "contact": this.user.phoneNumber
      },
      "notes": {
        "address": "Develop Solutions"
      },
      "theme": {
        "color": "#3399cc"
      }, modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      }
  };
// console.log(options)
options.handler = ((response: any, error: any) => {
options.response = response;
// call your backend api to verify payment signature & capture transaction
if (response.razorpay_payment_id) {
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.startDate.getDate() + 730);

  var subscriptionPlan={
              name        : this.user.name,
              email       : this.user.email,
              startDate   : this.startDate,
              endDate     : this.endDate,
              amount      : options.amount/100,
              isActive    : true,
              userId      : this.fs.currentUserId,
              razorpayid  : response.razorpay_payment_id,
              orderId     : options.order_id,
              plan        : this.planDiamond,
              trialSubscription: false
            };
  this.fs.createSubscription(subscriptionPlan).then(()=>{
              this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)
              new Toast(`Subscription done..!!` , {
                position: 'top'});
            });
}
});
options.modal.ondismiss = (() => {
// handle the case when user closes the form while transaction is in progress
new Toast(`Subscription Process Cancelled` , {
  position: 'top'});
return false;
});
   this.rzp1 = new this.payService.nativeWindow.Razorpay(options);
   this.rzp1.open();
});
}

getSubscriptionDetails(userId){
  return firstValueFrom( this.fs.getSubscriptionDetails(userId)).then(res=>{
   this.subscription=res;
  //  console.log(res)
   // for(let i=0;i<res.length;i++){
   //   this.subscribtionDetails = res[i];
   //   // comparing the subscription end date to current Date and updating the status
   //   if(this.subscribtionDetails.endDate.toDate()>this.currentDate){
   //     this.auth.updateScripction(userId,status=true);
   //   }else{
   //     this.auth.updateScripction(userId,status=false);
   //   }
   // }
   })
 }



mon(){
  this.month=true;
  this.year=false;
  this.twoYears=false;
}
mons(){
  this.month=false;
  this.year=true;
  this.twoYears=false;
}
monss(){
  this.month=false;
  this.year=false;
  this.twoYears=true;
}
}

