import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor(private router: Router) { }

  authError() {
    console.log("Auth Error");
  }
}
