<div class="sectionMobile md:m-4 md:p-4">

  <div class="rounded bg-gray-100 p-2 shadow mt-2 mb-4">
    <div class="uppercase text-lg"> User Detail</div>
      <div class="grid grid-cols-12" *ngIf="user">

        <div class="mx-auto p-2 m-2 col-span-3">
          <img [src]="user.photoUrl" class="rounded-full">
        </div>

        <div class="col-span-6">
        <div class="grid grid-cols-12 p-2 m-2">
          <div class="col-span-3">
            <div class="pt-2 pb-2">Name</div>
            <div class="pt-2 pb-2">Email</div>
            <div class="pt-2 pb-2">Phone</div>
            <div class="pt-2 pb-2">Address</div>
            <div class="pt-2 pb-2">Company </div>
          </div>
          <div class="col-span-9">
          <div class="pt-2 pb-2">  {{user.name}}</div>
          <div class="pt-2 pb-2">  {{user.email}}</div>
          <div class="pt-2 pb-2">  {{user.phoneNumber}}</div>
          <div class="pt-2 pb-2">   {{user.address}}</div>
          <div class="pt-2 pb-2">   {{user.companyName}}</div>
          </div>
        </div>
        </div>

        <div class="col-span-3">
        <div class="grid grid-cols-12 p-2 m-2">
          <div class="col-span-4">
            <div class="pt-2 pb-2">Login Date</div>
            <div class="pt-2 pb-2 mt-3">Subscription</div>
            <!-- <div class="pt-2 pb-2"> </div> -->
            <div class="pt-2 pb-2 mt-3">Verification</div>
          </div>
          <div class="col-span-8">
            <div class="pt-2 pb-2">{{user.signUpDate.toDate() | date:'dd/mm/yyyy'}}</div>

          <div  class="pt-2 pb-2 cursor-pointer shadow rounded text-center flex hover:bg-gray-300 mt-3"  (click)="updateUserSubscription(user)">
            <i class="text-blue-500 pt-1 ml-2 list-disc"
                [ngClass]="user.validSubscription? 'fa-solid fa-circle-check':'fa-regular fa-circle'">
            </i>
                <p class="mx-auto">Subscribed</p>
          </div>

          <div  class="pt-2 pb-2  cursor-pointer shadow rounded text-center flex hover:bg-gray-300 mt-3" (click)="updateVerification(user)">
            <i class="text-blue-500 pt-1 ml-2 list-disc"
                [ngClass]="user.verified? 'fa-solid fa-circle-check':'fa-regular fa-circle'">
            </i>
            <p class="mx-auto"> Verified</p>
          </div>
          </div>
        </div>
        </div>

      </div>
  </div>



</div>
  <div class="rounded bg-gray-100 p-2 shadow md:m-4 md:p-4">
  <div class="uppercase text-lg"> Subscription History </div>
  <div class="p-5 m-2 grid grid-cols-12 gap-2 uppercase text-center rounded shadow bg-gray-300">
    <div class="col-span-2">Id</div>
    <div class="col-span-2">Email</div>
    <div class="col-span-2">Order_Id</div>
    <div class="col-span-2">razorpay_id</div>
    <div class="col-span-1">plan</div>
    <div class="col-span-1">start Date</div>
    <div class="col-span-1">End Date</div>
    <div class="col-span-1">Amount</div>
  </div>
  <div class="m-2 p-5 grid grid-cols-12 gap-2 text-center rounded shadow bg-white" *ngFor="let item of subscriptionDetails">
    <div class="col-span-2">{{item.id}}</div>
    <div class="col-span-2">{{item.email}}</div>
    <div class="col-span-2">{{item.orderId}}</div>
    <div class="col-span-2">{{item.razorpayid}}</div>

    <div class="col-span-1">
    <div class="col-span-1" *ngIf="item.plan==0">
      Trial</div>
    <div class="col-span-1" *ngIf="item.plan==1">Silver</div>
    <div class="col-span-1" *ngIf="item.plan==2">Gold</div>
    <div class="col-span-1" *ngIf="item.plan==3">Diamond</div>
</div>
    <div class="col-span-1">{{item.startDate.toDate() | date:'dd/MM/yyyy'}}</div>
    <div class="col-span-1">{{item.endDate.toDate() | date:'dd/MM/yyyy'}}</div>

    <div class="col-span-1">
      <p class="inline-flex">{{item.amount}}</p>

      <div class="inline-flex ml-3"  *ngIf="item.endDate.toDate() > currentDate">
        <button class="text-blue-400 ml-auto" (click)="edit(item)">
          <i class="fa-solid fa-pen"></i>
        </button>
      </div>
    </div>

<!-- bulk updating completed Date -->

      <div class="grid grid-cols-1 md:flex md:gap-2 fixed right-0 left-0 top-0 h-screen w-screen bg-gray  items-center justify-center bg-gray-50" *ngIf="editbtn==true" >
        <div class="bg-white shadow p-6 rounded">

      <form [formGroup]="formvalue" >
        <div class="grid uppercase  text-center ">
          <div class="text-center p-4 m-2 text-lg">
            Update Plan and End Date
          </div>

      <div class="flex  m-2">
          <p class="uppercase">Plan</p>

        <select class="bg-white formControl p-2 rounded w-48 ml-auto shadow-md m-2 shadow-gray-500" name="" id="" formControlName="planType" >
          <option [value]=-1 disabled>Select Activity</option>
          <option [value]=0>Trial</option>
          <option [value]=1>Silver</option>
          <option [value]=2>Gold</option>
          <option [value]=3>Diamond</option>
        </select>
      </div>
      <div class="flex m-2">
        End Date
        <p class="ml-2 text-blue-400">{{editSelectedItem.endDate.toDate() | date:'dd/MM/yyyy'}}</p>
        <input type="date" name="endDate" class="rounded shadow-md p-2 m-2 shadow-gray-500" formControlName="endDate">
      </div>
      <div class="grid grid-cols-2 gap-2">
        <button class="btnnobg bg-green-500 rounded text-white hover:bg-green-600 w-full h-fit m-2 " (click)="updateSubscriptionDetails(item.id)">Update</button>
        <button class="btnnobg bg-orange-300 rounded text-white hover:bg-red-500 w-full h-fit m-2" (click)="cancel()">CANCEL</button>
      </div>
      </div>

      </form>
  </div>
</div>
</div>

</div>




