import { Pipe, PipeTransform } from '@angular/core';


enum ITRequestType { Individual, Business }

@Pipe({
  name: 'iTRequestType'
})
export class ITRequestTypePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    return ITRequestType[value].toUpperCase();
  }

}
