import { Injectable } from '@angular/core';
import { CanActivate, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SnackService } from '../services/snack.service';
import { first, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private afAuth: AngularFireAuth, private snackBar: SnackService, private router: Router) {

  }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    var isLoggedin = await this.isLoggedIn();
    if (isLoggedin === null) {

      this.router.navigate(['login']);
      return false;
    }
    else {

      return true;
    }
  }

  public isLoggedIn() {
    return firstValueFrom(this.afAuth.authState.pipe(first()))
  }

}
