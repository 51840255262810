<div class="section md:m-10 md:p-10 mt-2">
  <!-- TODO: Add Search functionality -->
  <div class="mt-5">
    <div class="text-2xl shadow p-2 text-center w-full">
      <h2>GST FILING REQUESTS</h2>
    </div>
  </div>
  <div class="flex gap-2 mt-2 w-100  shadow">
    <div class="flex mb-auto p-4">
      <span class="">Filter</span>
    </div>

    <div class="mt-2">
      <input type="text" class="p-2 rounded shadow" placeholder="Search" aria-label="Search"
      aria-describedby="button-addon2" [(ngModel)]="searchText" />
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="search()">
        <i class="fa-solid fa-magnifying-glass"></i>
      </button>
    </div>
  </div>
  <div class="bg-white shadow mt-2 p-4 rounded grid grid-cols-1 md:grid-cols-6 uppercase  text-lg">
    <div class="mx-auto">Date</div>
    <div class="mx-auto">Month</div>
    <div class="mx-auto">Company NAME</div>
    <div class="mx-auto">GST Type</div>
    <div class="mx-auto">STATUS</div>
    <div class="mx-auto"></div>
  </div>
  <div class="">
  <div class="bg-white shadow mt-2 p-4 rounded grid grid-cols-1 md:grid-cols-6" *ngFor="let request of filteredRequests ">
    <div class="mx-auto">{{ request.timestamp.toDate() | date: "dd/MM/yyyy | h:mm a" }}</div>
    <p class="card-text mx-auto">
      {{ request.month }}
      {{ request.timestamp.toDate() | date: "yyyy" }}
    </p>
    <div class="mx-auto">
      {{ request.name }}
    </div>
    <div class="mx-auto">
      GST Type: {{ request.gstType | gstRequestType }}
    </div>
    <div class="mx-auto uppercase">
      <span class="text-blue-400" *ngIf="request.status === 0">NEW </span>
      <span class="text-yellow-400" *ngIf="request.status === 1">SUBMITTED </span>
      <span class="text-green-400" *ngIf="request.status === 2">REVIEW </span>
      <span class="text-red-400" *ngIf="request.status === 3">INCOMPLETE </span>
      <span class="text-red-400" *ngIf="request.status === 4">INVALID </span>
      <span class="text-red-400" *ngIf="request.status === 5">DELETED </span>
    </div>
    <div class="mx-auto">
      <button class="btn btn-taxPrism-outline" (click)="viewGstRequest(request)">
        DETAILS
      </button>
    </div>
  </div>
  </div>
</div>
