import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Toast from 'awesome-toast-component';
import { ETFUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-user-customer',
  templateUrl: './user-customer.component.html',
  styleUrls: ['./user-customer.component.css']
})
export class UserCustomerComponent {

  user: ETFUser;
  userId: any;
  userEmail: any;
  customer: any;
  filtered: any;
  searchText: any;

  constructor( private fs: FirebaseService, private authService:AuthService,private activateRoute:ActivatedRoute){}


  ngOnInit(){

    const userId = this.activateRoute.snapshot.paramMap.get('id');

     this.fs.getAllCustomersDetails(userId).subscribe(data=>{
      this.customer=data;
      this.filtered=data
      });

  }

  search() {
    this.filtered = this.customer
      .filter(customer => customer.name
        .toUpperCase()
        .includes(this.searchText
          .toUpperCase()));
  }

  setAsVerified(customer) {
    if (confirm("Approve customer?")) {
      customer.newUser = false;
      const d = 'detail';
      delete customer[d];
      this.fs.updateCustomer(customer);
      new Toast(`Customer Verified` , {
        position: 'top'});
    }
  }
}
