<div class="section md:m-10 md:p-10 mt-2">
  <div class=" mt-5">
    <div class="text-2xl shadow p-2 text-center w-full">
      <p>BUSINESSES</p>
    </div>
  </div>

  <!-- TODO: Add Search functionality -->
  <div class="mt-2 w-100 justify-content-center shadow">
    <div class="flex gap-2 mb-auto">
      <div class="p-4">
        <span class="input-group-text ">Filter</span>
      </div>

      <div class="input-group-append mt-2">
        <input type="text" class="form-control p-2 rounded shadow" placeholder="Search" aria-label="Search"
        aria-describedby="button-addon2" [(ngModel)]="searchText" />
        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="search()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
  </div>
  <div class=" mt-2 bg-gray-300 p-4 shadow rounded ">
    <div class="grid grid-cols-1 md:grid-cols-12 gap-2 w-100 ">
      <div class="mx-auto col-span-2">STATUS</div>
      <div class="mx-auto col-span-2">NAME</div>
      <div class="mx-auto col-span-2">TYPE</div>
      <div class="mx-auto col-span-3">EMAIL</div>
      <div class="mx-auto col-span-2">PAN</div>
      <div class="mx-auto col-span-1"></div>
    </div>
  </div>
  <div class="">
  <div class="mt-2 bg-white p-4 shadow rounded hover:bg-slate-100 mx-auto " *ngFor="let business of filtered">
    <div class="grid grid-cols-1 md:grid-cols-12 gap-2 w-100 p-2 ">
      <div class="col-span-2 d-flex justify-content-center mx-auto">
        <span class="text-green-400" *ngIf="business.isActive">ACTIVE BUSINESS</span>
        <span class="text-red-500" *ngIf="!business.isActive">INACTIVE BUSINESS</span>
      </div>
      <div class="col-span-2 d-flex justify-content-center mx-auto">{{ business.name }}</div>
      <div class="col-span-2 d-flex justify-content-center mx-auto">
        {{ business.businessType | businessType }}
      </div>
      <div class="col-span-3 d-flex justify-content-center mx-auto">
        {{ business.emailAddress }}
      </div>
      <div class="col-span-2 d-flex justify-content-center mx-auto">
        {{ business.panCardNumber }}
      </div>

      <div class="col-span-1 flex ">
        <button class="bg-white py-2 px-3 text-black rounded-md ml-auto" (click)="business.detail = !business.detail">
          <i class="fa-solid fa-eye"></i>
        </button>
        <button class="bg-white py-2 px-3 ml-3 text-xl text-blue-600  rounded-md" (click)="viewBusinessDetail(business)">
          <i class="fa-solid fa-circle-info"></i>
        </button>
      </div>
    </div>
    <!-- DETAILS -->
    <div class="mt-2 p-4 grid grid-cols-1 md:grid-cols-2 w-100 bg-white shadow text-center" *ngIf="business.detail">
      <p class="text-xl font-bold">Name: {{ business.name }}</p>
      <p></p>
      <p class="">Email Address: {{ business.emailAddress }}</p>
      <p class="">PAN: {{ business.panCardNumber }}</p>
      <p class="">IT Portal Login: {{ business.loginId }}</p>
      <p class="">IT Portal Password: {{ business.loginPwd }}</p>
    </div>
  </div>
</div>
<div *ngFor="let item of businesses">
  {{item.email}}
</div>
</div>

