<div class="grid grid-cols-1 md:flex md:gap-2 fixed top-0 h-screen w-screen bg-gray  items-center justify-center bg-gray-50" >
  <div class="bg-white shadow p-6 rounded">
<form [formGroup]="formvalue" class="grid grid-cols-1 md:gap-2 space-y-6 p-2 m-2 w-fit" >
  <div class=" font-medium text-lg" >CUSTOMER PROFILE

  <button (click)="cancel()"
    class="flex ml-auto -mt-6">
    <i class="fa-solid fa-xmark"></i>
    </button>
</div>
  <input type="hidden" name="remember" value="true">
  <div class="space-y-px rounded-md shadow-sm">

    <div class="flex p-3 space-x-3">
      <label for="Name" class="sr-only">Name</label>
      <input id="Name" name="Name" type="text" autocomplete="Name" formControlName="name"
       required class="p-2 mb-2 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Name" >

      <label for="email" class="sr-only">Email-Id</label>
      <input id="email" name="email" type="email" autocomplete="email" formControlName="email"
       required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Email address" >
    </div>

    <div class="flex p-3 space-x-3">
      <label for="loginId" class="sr-only">Login Id</label>
      <input id="loginId" name="loginId" type="text"  formControlName="loginId"
      required class="p-2 mb-2 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Login Id" >

      <label for="password" class="sr-only">Password</label>
          <input id="password" name="password" type="password" formControlName="loginPwd"
          required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Password" >
    </div>

    <div class="flex p-3 space-x-3">
      <label for="pancard" class="sr-only">Pancard Number</label>
      <input id="pancard" name="pancard" type="text"  formControlName="panCardNumber"
      required class="p-2 mb-2 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="PANCARD Number" >

      <label for="aadharCardNumber" class="sr-only">Aadhar Number</label>
      <input id="aadharCardNumber" name="aadharCardNumber" type="number" formControlName="aadharCardNumber"
      required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="AADHAR Number" >
    </div>

    <div class="flex p-3 space-x-3">
      <label for="phoneNumber" class="sr-only">Phone Number</label>
      <input id="phoneNumber" name="phoneNumber" type="number" formControlName="phoneNumber"
      required class="p-2 mb-2 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Phone Number" >

      <label for="address" class="sr-only">Address</label>
      <input id="address" name="address" type="text" formControlName="address"
      required class="p-2 mb-2 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Address">

    </div>

    <div class="md:flex p-3 m-3 space-x-3 ">
      <label for="photo" >Upload Photo</label>
      <input id="photo" name="photo" type="file" formControlName="socialPhotoUrl"
      required class="p-2 mb-2 relative block w-fit rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Upload Photo">

      <label for="dob">Date Of Birth</label>
      <input id="dateofBirth" name="dateofBirth" type="date" formControlName="dateofBirth"
      required class="p-2 mb-2 space-x-3 relative block w-fit rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  >
    </div>
  </div>

  <div class="flex ml-auto">
    <button type="submit" (click)="newUser()"
    class="group relative justify-center rounded-md py-2 px-3 text-sm font-semibold text-white bg-green-500 hover:bg-green-600  ml-auto">
     REGISTER
    </button>

    <button class="group relative  justify-center rounded-md py-2 px-3 text-sm font-semibold text-white  bg-red-500 uppercase ml-2"
      (click)="cancel()">Cancel</button>
  </div>
</form>
</div>
</div>
