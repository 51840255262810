<div class=" bg-gray-100  m-6 p-6 rounded shadow">
  <div class="text-2xl underline font-sans font-bold p-2 m-2">Privacy Policy</div>
Easy Tax Filing is committed to protecting the privacy of our users. We collect personal information such as bank statements, loan statements, insurance statements, property documents, and Form16/16A only for the purpose of providing tax filing services. We do not sell or share this information with any third parties without the user's express consent.
We take appropriate measures to ensure the security of our users' personal information. This includes using secure servers and encryption to protect data in transit and at rest.
Users have the right to access, update, or delete their personal information at any time. Please contact us at  <a href="mailto:contactus@developsolutions.in"> contactus@developsolutions.in </a> for any privacy-related inquiries or concerns.
By using Easy Tax Filing, users agree to this privacy policy and the collection and use of their personal information as described herein.
Thank you for choosing Easy Tax Filing as your partner in tax filing. We are committed to providing you with a secure and hassle-free experience. In line with our commitment to privacy, we want to provide you with additional information regarding the collection and use of your personal information.

<div class=" text-2xl font-semibold font-sans  p-2 m-2">Information We Collect</div>
We collect personal information such as bank statements, loan statements, insurance statements, property documents, and Form16/16A from our users. This information is collected solely for the purpose of providing tax filing services. We may also collect non-personal information such as technical information about your device and usage of our website or application.

<div class=" text-2xl font-semibold font-sans  p-2 m-2">Use of Information</div>
We use the information we collect to provide tax filing services to our users. We do not sell, rent, or share this information with any third parties without the user's express consent. We may use non-personal information to improve our website or application and to better understand user behavior.

<div class=" text-2xl font-semibold font-sans  p-2 m-2">Security of Information</div>
We take appropriate measures to ensure the security of our users' personal information. This includes using secure servers and encryption to protect data in transit and at rest. We also restrict access to personal information to authorized personnel only.

<div class=" text-2xl font-semibold font-sans  p-2 m-2">User Rights</div>
Users have the right to access, update, or delete their personal information at any time. Please contact us at <a href="mailto:contactus@developsolutions.in"> contactus@developsolutions.in </a> for any privacy-related inquiries or concerns.

<div class=" text-2xl font-semibold font-sans  p-2 m-2">Changes to Privacy Policy</div>
We reserve the right to update our privacy policy as necessary. Any changes will be reflected on our website or application and will be effective immediately upon posting.
By using Easy Tax Filing, users agree to this privacy policy and the collection and use of their personal information as described herein. If you have any questions or concerns, please do not hesitate to contact us.
</div>
