import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ETFUser } from '../../../models/user';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import Toast from 'awesome-toast-component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  user: ETFUser;
  userId:any;
  subscription: any;

  subscribtionDetails:any
  currentDate:Date = new Date()

  disclaimerPopup:boolean=true;
  isAgree: boolean;
  currentUser: ETFUser;
  endDate: Date;
  startDate: Date = new Date();
  trialPlan:any;
  subscriptionItem: any;
  subscriptiontrial: any;
  nosubscription: boolean=false;
  constructor(private fs: FirebaseService, private auth:AuthService,public afAuth: AngularFireAuth, private router: Router) {
  }

  ngOnInit(): void {
    this.fs.getUserData().then((res) => {

      res.subscribe((user) => {

        this.user = user as ETFUser;
        this.currentUser = user;

        this.userId=this.user.uid;
        this.getSubscriptionDetails(this.userId);

      })
    })
    this.getSubscriptionItem();
  }

  getSubscriptionDetails(userId){
    this.fs.getSubscriptionDetails(userId).subscribe(res=>{
    this.subscription=res;
    // console.log(res)
    // for(let i=0;i<res.length;i++){
    //   this.subscribtionDetails = res[i];
    //   // comparing the subscription end date to current Date and updating the status
    //   if(this.subscribtionDetails.endDate.toDate()>this.currentDate){
    //     this.auth.updateScripction(userId,status=true);
    //   }else{
    //     this.auth.updateScripction(userId,status=false);
    //   }
    // }
    })
  }

  getSubscriptionItem(){
    firstValueFrom(this.fs.getSUbscriptionItem()).then(res=>{
      // console.log(res)
      this.subscriptionItem=res;
      for(let i=0;i<this.subscriptionItem.length;i++){
        var element=this.subscriptionItem[i]
        if(element.type==0){
          this.subscriptiontrial = element.promotionalValue;

          this.trialPlan = element.type
        }
      }
    })
  }

  goto(url:string){
    window.location.href=url;
  }

  disclaimer(){
    this.disclaimerPopup=true;
  }
  agreeTS(){
this.isAgree = !this.isAgree
  }
  agreeLogin(userId){
    this.fs.updateIfAgree(userId).then(()=>{
    this.endDate = new Date(this.startDate);
    this.endDate.setDate(this.startDate.getDate() + 7);

var subscriptionPlan={
          email       : this.user.email,
          startDate   : this.startDate,
          endDate     : this.endDate,
          amount      : this.subscriptiontrial,
          isActive    : true,
          userId      : this.fs.currentUserId,
          plan        : this.trialPlan,
          trialSubscription : true,
          name        : this.user.name
        };
        console.log(subscriptionPlan)

this.fs.createSubscription(subscriptionPlan).then(()=>{
          this.updateSubscription(subscriptionPlan.userId,subscriptionPlan.isActive)

          new Toast(`Subscription done..!!` , {
            position: 'top'});
        });
      })
  }
private updateSubscription(uid:string,status:boolean){
  this.fs.updateSubscripction(uid,status).then(()=>{
    new Toast(`Trial Subscription Activated...!!` , {
      position: 'top'});
      this.router.navigate(['home']);
      window.location.reload();
})
}

cancelTrialSubscription(subscriptions:string){
  this.fs.updateTrialSubscription(subscriptions);
  this.nosubscription=true;
  new Toast(`Trial Subscription Cancelled..!!` , {
    position: 'top'});
}

  cancel(){
    this.afAuth.signOut().then(() =>
    this.router.navigate(['login']))
  }
}
