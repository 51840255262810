<div class="section md:m-10 md:p-10 mt-2">
  <div class=" mt-5">
    <div class="text-2xl shadow p-2 text-center w-full">
      <h2>CONTACT REQUESTS</h2>
    </div>
  </div>
  <div class="mt-2 w-100 justify-content-center shadow">
    <div class="flex gap-2 mb-auto">
      <div class="p-4">
        <span>Filter</span>
      </div>

      <!-- TODO: -->
      <div class="mt-2">
        <input type="text" class="p-2 rounded shadow" placeholder="Search" aria-label="Search"
        aria-describedby="button-addon2" [(ngModel)]="searchText" />
        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="search()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="mt-2 bg-gray-300 p-4 shadow rounded ">
    <div class="grid grid-cols-1 md:grid-cols-6 gap-4 w-100 uppercase">
      <div class="mx-auto">Date</div>
      <div class="mx-auto">Type of Request</div>
      <div class="mx-auto">Title</div>
      <div class="mx-auto">Details</div>
      <div class="mx-auto">Name</div>
      <div class="mx-auto"></div>
    </div>

  </div>
  <div class="mt-2 bg-white p-4 shadow rounded hover:bg-slate-100" *ngFor="let request of contactRequests">
    <div class="grid grid-cols-1 md:grid-cols-6 gap-4 w-100">
      <div class="mx-auto">
        <p class="">
          {{ request.timestamp.toDate() | date: "dd-MM-yyyy" }}
        </p>
      </div>
      <div class="mx-auto">
        {{ request.service | otherServices }}
      </div>
      <div class="mx-auto">
        {{ request.title }}
      </div>
      <div class="mx-auto">
        {{ request.body }}
      </div>
      <div class="mx-auto">
        {{ request.name }}
      </div>
      <div class="mx-auto">
        <!-- <button class="btn btn-taxPrism-outline" (click)="viewContactRequest(request)">
          DETAILS
        </button> -->
      </div>
    </div>
  </div>
</div>
