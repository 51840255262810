import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/services';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {
  appointments = [];
  searchText = '';
  constructor(private fs: FirebaseService) {

  }

  async ngOnInit() {
    //TODO: Implement appointments
    this.fs.getAllAppointments().subscribe(allAppointments => this.appointments = allAppointments);
  }

  search() { }

  setComplete(appointment) {
    console.log(appointment);
    appointment.completed = true;
    this.fs.updateAppointment(appointment);
  }

}
