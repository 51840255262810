import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Toast from 'awesome-toast-component';
import { firstValueFrom } from 'rxjs';
import { ETFUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {

  public users;
  filtered=[];
  searchText: any;

  isViewAll = true;
  filterdefaultClass = 'btn btn-secondary';

  filterAllUsers:string;
  filterSubscribers:string;
  filterUnSubscribers:string;

  filterText: string;
  active = ' active';
  userFilter=[];
  filteredRequests=[];
  filterVerified: string;
  filterunVerified: string;
  subscriptionDetails: any;
  subscriptiontrail: any;
  trialPlan: any;
  subscription: any;

  constructor(private fs: FirebaseService, private authService:AuthService, public router : Router){}

  ngOnInit(): void {
    this.getUsersList();

    this.filterAll();
    this.filterText = '';
  }
    // current user uid with post
    private getUsersList() {
      this.fs.getUsers().subscribe(user=>{
        this.users=user.sort((a: any, b: any) => { return a.validSubscription - b.validSubscription; });
        // console.log(user)
        this.filtered = user;
        this.userFilter = this.filtered;
        this.filteredRequests = this.userFilter;
// console.log(this.filteredRequests)

      })
      this.getSubscriptionDetails();
     }
     getSubscriptionDetails(){
      firstValueFrom(this.fs.getAllSubscriptionDetails()).then(res=>{
        // console.log(res)
        this.subscription=res;
        // var data=[]

        // for(let j=0;j<this.filtered.length;j++){
        // for(let i=0;i<this.subscriptionDetails.length;i++){
        //   var element=this.subscriptionDetails[i]

        //   if(this.filtered[j].id==element.userId){

        //     data.push(element)
        //     this.subscription=data
        //     console.log(this.subscription)
        //   }
        // }
        // }
      })
    }

     search() {
            this.filteredRequests = this.userFilter.filter(item => item.name.toUpperCase().includes(this.searchText.toUpperCase()));
            this.searchText=''
    }
  verify(uid:string,status:boolean){
    if(status==true){
      if(confirm("Verify Customer?")){
       this.fs.updateVerification(uid,status).then(()=>{
        new Toast(`Verification done..!!` , {
          position: 'top'});
          // window.location.reload();
  })
}
    }else{
      if(confirm("Unverify Customer?")){
      this.fs.updateVerification(uid,status).then(()=>{
        new Toast(`Customer Unverified..!!` , {
          position: 'top'});
          // window.location.reload();
        })}
    }

  }

  filterAll(){
    this.filterAllUsers=this.filterdefaultClass + this.active;
    this.filterSubscribers=this.filterdefaultClass;
    this.filterUnSubscribers=this.filterdefaultClass;
    this.filterVerified=this.filterdefaultClass ;
    this.filterunVerified=this.filterdefaultClass ;
// All users
    this.userFilter = this.filtered.filter(item => item);
    this.filteredRequests = this.userFilter;
    this.isViewAll = true;

    // console.log(this.filteredRequests)
  }

  filterSubscribedUser(){
    this.filterAllUsers=this.filterdefaultClass;
    this.filterSubscribers=this.filterdefaultClass + this.active;
    this.filterUnSubscribers=this.filterdefaultClass;
    this.filterVerified=this.filterdefaultClass ;
    this.filterunVerified=this.filterdefaultClass ;

    // subscribed User
    this.userFilter = this.filtered.filter(item => item.validSubscription==true);
    this.filteredRequests = this.userFilter;
    this.isViewAll = true;

    // console.log(this.filteredRequests)
  }

  filterUnSubscribedUser(){
    this.filterAllUsers=this.filterdefaultClass;
    this.filterSubscribers=this.filterdefaultClass;
    this.filterUnSubscribers=this.filterdefaultClass + this.active;
    this.filterVerified=this.filterdefaultClass ;
    this.filterunVerified=this.filterdefaultClass ;

    // Unsubscribed User
    this.userFilter = this.filtered.filter(item => item.validSubscription==false);
    this.filteredRequests = this.userFilter;
    this.isViewAll = true;

    // console.log(this.filteredRequests)
  }

  filterVerifiedUser(){
    this.filterAllUsers=this.filterdefaultClass;
    this.filterSubscribers=this.filterdefaultClass ;
    this.filterUnSubscribers=this.filterdefaultClass;
    this.filterVerified=this.filterdefaultClass + this.active;
    this.filterunVerified=this.filterdefaultClass;

    // Verified User
    this.userFilter = this.filtered.filter(item => item.verified==true);
    this.filteredRequests = this.userFilter;
    this.isViewAll = true;

    // console.log(this.filteredRequests)
  }

  filterunVerifiedUser(){
    this.filterAllUsers=this.filterdefaultClass;
    this.filterSubscribers=this.filterdefaultClass;
    this.filterUnSubscribers=this.filterdefaultClass;
    this.filterVerified=this.filterdefaultClass ;
    this.filterunVerified=this.filterdefaultClass + this.active;

    // Unverified User
    this.userFilter = this.filtered.filter(item => item.verified==false);
    this.filteredRequests = this.userFilter;
    this.isViewAll = true;

    // console.log(this.filteredRequests)
  }
}
